import React from "react";

import dayjs from "dayjs";
import DialogAlert from "../../components/alerts/DialogAlert";
import EditableTable from "../../components/tables/EditableTable";
import LoadingGif from "../../components/visual/LoadingGif";
import MainButton from "../../components/inputs/MainButton";
import MainDateTimeInput from "../../components/inputs/MainDateTimeInput";
import MainNumericKeyboard from "../../components/inputs/MainNumerickeyboard";
import MainSelectField from "../../components/inputs/MainSelectField";
import MainTextField from "../../components/inputs/MainTextField";
import PortionOrderHeader from "./header/PortionOrderHeader";
import SnackbarAlert from "../../components/alerts/SnackbarAlert";

import { Box, Divider, Typography } from "@mui/material";
import { defaultRequest } from "../../utils/request/request";
import { withTranslation } from "react-i18next";


class PortionOrder extends React.Component {
    constructor(props) {
        super(props)
        const { t } = this.props
        this.state = {
            isLoading: true,
            isLoadingTable: false,
            isSendingRequest: false,

            isDialogOpen: false,
            dialogTitle: '',
            dialogBody: '',
            isCancelDialogOpen: false,

            userInfos: this.props.user,
            company: this.props.user.data.empresas.find(item => item.cd_empresa === this.props.user.data.cd_empresa),
            focusedInput: 'portionedItemId',
            isFull: false,

            numericValue: '',
            data: [],

            itemsList: [],
            itemsColumns: [
                ['id_desc_item', t('Item Porcionado')],
                ['sg_unidademedida', t('UM')],
                ['quantidade_item', t('Quantidade')],
                ['peso_item', t('Peso (Kg)')],
                ['pesomedio_item', t('Peso Médio (Kg)')],
            ],
            itemsTotalSize: 0,

            // json
            initialDate: dayjs(), // dh_hora_inicio
            itemId: '', // id_item
            unit: '',
            qty: '',
            weight: '', // peso
            averageWeight: 0.0.toFixed(4),
            waste: '', // peso_refugo
            batch: '', // nr_lote
            expirationDays: '', // nr_lote
            expirationDate: dayjs(), // dt_validade 
        }

        this.fullScreenRef = React.createRef()
        this.itemRef = React.createRef()
        this.qtyRef = React.createRef()
        this.weightRef = React.createRef()
        this.wasteRef = React.createRef()
        this.batchRef = React.createRef()
        this.expirationDaysRef = React.createRef()
        this.numericKeyboardRef = React.createRef()
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.weight !== this.state.weight || prevState.qty !== this.state.qty) {   // Se o peso ou quantidade mudar, recalcula o peso médio
            this.calculateWeightAverage()
        }

        if (prevState.itemsList !== this.state.itemsList || prevState.weight !== this.state.weight) {   // Calcula valor de 'Refugo/Perdas'
            this.calculateWasteValue()
        }

        if (this.state.focusedInput && prevState.focusedInput !== this.state.focusedInput) {    // Se o foco mudar, limpa o input da calc.
            this.setState({ numericValue: '' })
        }

        if (prevState.expirationDays !== this.state.expirationDays) {   // Se dias de validade mudar, recalcula a data de validade
            if (this.state.expirationDays !== '') {
                const newDate = dayjs().add(parseInt(this.state.expirationDays, 10), 'day').format('YYYY-MM-DD')    // Somar os dias para validade com a data de validade
                this.setState({ expirationDate: newDate })
            } else {
                this.setState({ expirationDate: dayjs().format('YYYY-MM-DD') })
            }
        }
    }

    calculateWasteValue = () => {
        let sum = 0

        if (Array.isArray(this.state.itemsList) && this.state.itemsList.length > 0) {
            for (let item of this.state.itemsList) {
                sum += parseFloat(item.peso_item.replace(',', '.'))  // soma dos pesos de todas as linhas da tabela
            }
        }

        const currentWeight = parseFloat(this.state.weight.replace(',', '.'))   // valor do peso do input 'Peso (Kg)'
        const result = Math.max(0, currentWeight - sum)                         // resultado do peso menos a sema dos pesos da tabela

        this.setState({
            waste: isNaN(result) ? '' : result.toFixed(4),     // se o resultado for NaN, seta waste para ''
            sumOfTableWeights: sum,
        })
    }

    calculateWeightAverage = () => {
        if (this.state.weight !== '' && this.state.qty !== '') {
            const qty = parseFloat(this.state.qty)
            const weight = parseFloat(this.state.weight)

            const averageWeight = qty > 0 ? weight / qty : 0

            this.setState({ averageWeight: averageWeight.toFixed(4) })
        } else {
            this.setState({ averageWeight: '0,0000' })
        }
    }

    calculateWeightAverageTableItems = () => {
        if (!Array.isArray(this.state.itemsList) || this.state.itemsList.length === 0) {
            this.setState({
                itemsList: []
            })
            return
        }

        const updatedItems = this.state.itemsList.map(item => {
            const weight = parseFloat((item.peso_item + '').replace(',', '.'))
            const quantity = parseFloat((item.quantidade_item + '').replace(',', '.'))

            const averageWeight = quantity === 0 ? 0 : (isNaN(weight) ? 0 : weight / quantity)

            const formatedPesoMedio = averageWeight.toFixed(4)

            return { ...item, pesomedio_item: formatedPesoMedio.replace('.', ',') }
        })
        this.setState({ itemsList: updatedItems })
    }

    clearOpo = () => {
        this.itemRef.current.focus()

        this.setState({
            initialDate: dayjs(),
            portionedItemId: '',
            qty: '',
            weight: '',
            waste: '',
            batch: '',
            unit: '',
            itemsList: [],
            expirationDays: '',
            expirationDate: dayjs(),
            isCancelDialogOpen: false,
            focusedInput: 'portionedItemId'
        })
    }

    createOpo = async () => {
        await this.calculateWeightAverageTableItems()

        const { t } = this.props

        this.setState({ isSendingRequest: true }) // desabilita o botão de confirmar

        const { portionedItemId, weight, qty, itemsList, userInfos, initialDate, batch, expirationDate, waste, expirationDays, sumOfTableWeights } = this.state

        if (!portionedItemId || !weight || !qty) {  // Verifica se os campos (*) estão preenchidos
            this.setState({
                alertMessage: t('Preencha todos os campos obrigatórios (*)'),
                alertType: 'error',
                showAlert: true,
                isSendingRequest: false
            })
            return
        }

        if (sumOfTableWeights > parseFloat(weight)) { // se a soma dos pesos da tabela for maior que o input peso (Kg)
            this.setState({
                alertMessage: t('O peso total dos itens produzidos não pode ser maior que o peso do item a porcionar.'),
                alertType: 'error',
                showAlert: true,
                isSendingRequest: false
            })
            return
        }

        // Validação dos itens
        const parseValue = value => parseFloat(value.replace(',', '.'))
        const isAllZero = itemsList.every(item => parseValue(item.quantidade_item) === 0 && parseValue(item.peso_item) === 0)
        const isIncomplete = itemsList.some(item => {
            const quantidade = parseValue(item.quantidade_item)
            const peso = parseValue(item.peso_item)
            return (quantidade === 0 && peso > 0) || (quantidade > 0 && peso === 0)
        })

        if (isAllZero || isIncomplete) {
            this.setState({
                alertMessage: isAllZero
                    ? t('Pelo menos 1 item na grade deve ter quantidade e peso informado.')
                    : t('Existe(m) item(ns) informado(s) na grade sem peso ou quantidade, informe os dois campos ou informe zero para a quantidade e peso.'),
                alertType: 'error',
                showAlert: true,
                isSendingRequest: false
            })
            return
        }
        // -----------------

        let config = {
            endpoint: `opo/ordemporcionamento?x-Entidade=${this.state.userInfos.entidade}`,
            method: 'post'
        }
        const filteredItems = itemsList.filter(item => parseValue(item.quantidade_item) > 0 && parseValue(item.peso_item) > 0)
        let form = {
            cd_empresa: userInfos.data.cd_empresa,
            dh_hora_inicio: initialDate,
            dh_hora_fim: dayjs(),
            nm_responsavel: userInfos.data.nm_usuario,
            observacao: 'Porcionamento criado através do EverLojas',
            id_item: portionedItemId,
            peso: parseValue(weight),
            nr_lote: parseValue(batch),
            dt_validade: expirationDate,
            peso_refugo: parseValue(waste),
            dh_lancamento: dayjs(),
            at_situacao: 1,
            nr_dias_validade_itens_producao: Math.round(expirationDays),
            itens: filteredItems.map(item => ({
                id_item: item.id_item_producao,
                quantidade: parseValue(item.quantidade_item),
                peso: parseValue(item.peso_item),
                peso_medio: parseValue(item.pesomedio_item) || 0,
            }))
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    isDialogOpen: true,
                    dialogTitle: t('Ordem de Porcionamento Criada com Sucesso'),
                    dialogBody: <b>{t('N°:') + ' ' + r.data.nr_ordem_porcionamento.toString() + ' ' + t('Serie:') + ' ' + r.data.cd_serie.toString()}</b>,
                    isSendingRequest: false
                }, () => this.clearOpo())
            } else {
                this.setState({
                    alertMessage: t('Falha ao criar OPO'),
                    alertType: 'error',
                    showAlert: true,
                    isSendingRequest: false
                })
            }
        })
    }

    getData = () => {
        const { t } = this.props

        let config = {
            endpoint: `opo/usuariosetorproducao/${this.state.userInfos.data.cd_usuario}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.state.userInfos.entidade
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data) {
                this.setState({
                    data: r.data,
                    isLoading: false,
                },
                    () => this.itemRef.current.focus()
                )
            } else if (r.status && !r.data) {
                this.setState({
                    alertMessage: t('Nenhum item para porcionar.'),
                    alertType: 'error',
                    showAlert: true,

                    isLoading: false,
                })
            } else {
                this.setState({
                    alertMessage: t('Erro ao executar a operação.'),
                    alertType: 'error',
                    showAlert: true,

                    isLoading: false,
                })
            }
        })
    }

    getitemsList = () => {
        const { t } = this.props

        this.setState({ isLoadingTable: true })

        let config = {
            endpoint: `opo/itemproducao/${this.state.itemId}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.state.userInfos.entidade
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                const updatedItemsData = r.data
                    .filter(item => item.at_situacao === 1)
                    .map(item => ({
                        ...item,
                        quantidade_item: (0).toFixed(4).replace('.', ','),  // inicia como '0,0000'
                        peso_item: (0).toFixed(4).replace('.', ','),        // inicia como '0,0000'
                        pesomedio_item: (0).toFixed(4).replace('.', ','),   // inicia como '0,0000'
                        id_desc_item: `${item.cd_item} - ${item.ds_item}`   // Concatena o id e a descrição
                    }))

                this.setState({
                    itemsList: updatedItemsData,
                    itemsTotalSize: r.data.length,

                    alertMessage: t('Dados Retornados!'),
                    alertType: 'success',
                    showAlert: true,

                    isLoadingTable: false,
                })
            } else {
                this.setState({
                    alertMessage: t('Erro ao executar a operação.'),
                    alertType: 'error',
                    showAlert: true,

                    isLoadingTable: false,
                })
            }
        })
    }

    handleCellClick = (params) => {
        const { selectedId, numericValue, itemsList, selectedField, originalValue } = this.state
        const regex = /^\d,\d{4}$/

        const shouldUpdateItems = selectedId && !regex.test(numericValue)
        const updatedItemsList = shouldUpdateItems ? itemsList.map(item =>
            item.id_item_producao === selectedId ?
                { ...item, [selectedField]: originalValue } : item) : itemsList

        this.setState({
            shouldSelectAllValue: true,
            itemsList: updatedItemsList,
            focusedInput: '',
            numericValue: params.value || '',
            selectedId: params.id,
            selectedField: params.field,
            originalValue: params.value || ''
        }, () => this.numericKeyboardRef.current.focus())
    }

    handleChangeText = (event) => {
        let value = event.target.value

        if (value.length > 9) {
            value = value.substring(0, 9) // limita o valor digitado no teclado numérico para 9
        }

        this.setState({ [event.target.id]: value.toUpperCase() }) // Deixa em maiúsculo caso seja palavra
    }

    handleChangeTextItemToBePortioned = (event) => {
        this.setState({ [event.target.id]: event.target.value }, () => {

            const matchingItem = this.state.data.find(item => item.id_item === event.target.value)
            const updates = matchingItem
                ? { unit: matchingItem.sg_unidademedida, itemId: event.target.value, viewAsSelected: null } // Seta a unidade de medida e id do item selecionado, e limpa a célula selecionada
                : { unit: '', itemsList: {} }

            this.setState(updates, matchingItem ? () => this.getitemsList() : undefined)
        })
    }

    handleChangeTextNumericKeyboard = (number) => {
        const { selectedId, selectedField, itemsList, focusedInput } = this.state

        number = number.replace(',', '.')

        if (number.endsWith('.') && number.split('.').length === 2 && number.split('.')[1] === '') {
            this.setState({ numericValue: number })
            return;
        }

        // Limita o número de caracteres para 9
        if (number.length > 9) {
            number = number.substring(0, 9)
        }

        this.setState({ numericValue: number, shouldSelectAllValue: false }) // Adiciona o valor digitado da calc no estado 'numericValue'

        if (selectedId) {  // Se uma célula estiver selecionada, atualiza essa célula na lista
            number = number.replace('.', ',')
            this.setState({
                itemsList: itemsList.map(item =>
                    item.id_item_producao === selectedId
                        ? { ...item, [selectedField]: number }
                        : item
                )
            })
        }

        if (focusedInput) {  // Caso haja um input fora da grade que esteja em foco, faz handle change nele
            this.setState({ [focusedInput]: number })
        }
    }



    handleFullScreen = () => {
        const element = document.documentElement
        // Entrar em fullscreen
        const requestFullScreen = element.requestFullscreen || element.mozRequestFullScreen ||
            element.webkitRequestFullscreen || element.msRequestFullscreen
        // Sair de fullscreen
        const exitFullScreen = document.exitFullscreen || document.mozCancelFullScreen ||
            document.webkitExitFullscreen || document.msExitFullscreen
        // Verifica se já está em fullscreen
        const isFullScreen = document.fullscreenElement || document.mozFullScreenElement ||
            document.webkitFullscreenElement || document.msFullscreenElement
        try {
            if (isFullScreen) {
                exitFullScreen.call(document)
                this.setState({ isFull: false })
            } else {
                requestFullScreen.call(element)
                this.setState({ isFull: true })
            }
        } catch (err) {
        }
        this.setState({ isFullScreen: !this.state.isFullScreen })
    }

    handleKeyUp = (event) => {
        if (event.key === "Enter") {
            this.moveToNextInput()
        }
    }

    insertValue = (value) => {
        this.setState({ shouldSelectAllValue: true })   // seleciona todo o valor que está na célula, como apertar Ctrl + A

        value = parseFloat(value?.replace(',', '.') || 0).toFixed(4).replace('.', ',')   // Verifica se o valor é nulo ou não, e então formata corretamente para um número com quatro casas decimais.

        if (isNaN(parseFloat(value.replace(',', '.')))) { // Se value for NaN, seta para 0,0000
            value = "0,0000"
        }

        if (this.state.selectedId) {    // Verifica se uma célula está selecionada para inserir o valor.
            const columns = ['quantidade_item', 'peso_item']    // Define os campos da grade que podem ser editados.
            const updatedList = this.state.itemsList.map(item =>
                item.id_item_producao === this.state.selectedId     // Atualiza a lista de itens mapeando cada item para um novo objeto.
                    ? { ...item, [this.state.selectedField]: value }
                    : item
            )

            const currentFieldIndex = columns.indexOf(this.state.selectedField) // Obtém o índice da coluna atual e calcula o índice da próxima coluna
            const nextFieldIndex = (currentFieldIndex + 1) % columns.length
            const nextItemId = currentFieldIndex + 1 < columns.length   // Determina o ID do próximo item (se o próximo campo não existir, move para o próximo item na lista)
                ? this.state.selectedId
                : this.state.itemsList[(this.state.itemsList.findIndex(item => item.id_item_producao === this.state.selectedId) + 1) % this.state.itemsList.length].id_item_producao

            const nextValue = this.state.itemsList.find(item => item.id_item_producao === nextItemId)[columns[nextFieldIndex]] || ''    // Obtém o valor do próximo campo a ser selecionado

            this.setState({ // Atualiza o estado com a nova lista de itens e define o próximo campo a ser focado
                itemsList: updatedList,
                numericValue: nextValue,
                selectedId: nextItemId,
                selectedField: columns[nextFieldIndex],
            }, () => {
                this.calculateWeightAverageTableItems() // Recalcula a média de peso dos itens na tabela
                this.numericKeyboardRef.current.focus() // Foca no teclado numérico
            })
        } else {
            this.moveToNextInput()  // Se nenhuma célula da tabela estiver selecionada, move para o próximo input
        }
    }

    moveToNextInput = () => {
        switch (this.state.focusedInput) {
            case 'portionedItemId':
                this.qtyRef.current.focus()
                this.setState({ focusedInput: 'qty', numericValue: '' })
                break
            case 'qty':
                this.weightRef.current.focus()
                this.setState({ focusedInput: 'weight', numericValue: '' })
                break
            case 'weight':
                this.wasteRef.current.focus()
                this.setState({ focusedInput: 'waste', numericValue: '' })
                break
            case 'waste':
                this.batchRef.current.focus()
                this.setState({ focusedInput: 'batch', numericValue: '' })
                break
            case 'batch':
                this.expirationDaysRef.current.focus()
                this.setState({ focusedInput: 'expirationDays', numericValue: '' })
                break
            default:
                this.setState({ focusedInput: '', numericValue: '' })
                break
        }
    }

    onInputFocus = (params) => {
        this.setState({
            focusedInput: params.target.id,
            selectedId: '', // Quando clicar em um input externo, tirar o foco da célula da grade
        }, () => this.calculateWeightAverageTableItems())
        params.target.select() // seleciona todo o conteúdo quando em foco (ctrl + A)
    }


    render() {
        const { t } = this.props

        if (this.state.isLoading) {
            return (
                <LoadingGif />
            )
        }

        const backgroundStyle = { '& .MuiOutlinedInput-root': { backgroundColor: 'white' } }

        return (
            <Box ref={this.fullScreenRef} className={this.state.isFullScreen ? "full-screen" : ""}>

                {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                <DialogAlert
                    {...this.props}
                    type='confirm'
                    isOpen={this.state.isDialogOpen}
                    title={this.state.dialogTitle}
                    body={this.state.dialogBody}
                    onConfirm={() => { this.setState({ isDialogOpen: false, dialogTitle: '', dialogBody: '' }) }}
                />
                <DialogAlert
                    {...this.props}
                    type='cancel'
                    isOpen={this.state.isCancelDialogOpen}
                    title={t('Deseja Cancelar ?')}
                    body={t('As informações adicionadas serão perdidas.')}
                    onConfirm={() => this.clearOpo()}
                    onClose={() => { this.setState({ isCancelDialogOpen: false }) }}
                    onExit={() => { this.itemRef.current.focus() }}
                />

                <DialogAlert
                    {...this.props}
                    type="confirm"
                    isOpen={this.state.isCloseOpen}
                    title={t('Deseja Sair ?')}
                    body={t('Todas as alterações serão perdidas.')}
                    onClose={() => this.setState({ isCloseOpen: false })}
                    onConfirm={() => this.props.toggleVisiblePage('isOPOVisible')}
                />

                <PortionOrderHeader {...this.props} onClicked={() => this.clearOpo()} handleFullScreen={this.handleFullScreen} isFull={this.state.isFull} company={this.state.company} onClose={()=>this.setState({isCloseOpen: true})} />

                <Box sx={{ margin: '15px 20px' }}>
                    {/* Primeira Box */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            background: 'white'
                        }}
                    >
                        <Box>
                            <Typography sx={{ fontWeight: 'bold' }}>{t('Abertura do Porcionamento')}</Typography>
                            <Typography>{this.state.initialDate.format('DD/MM/YYYY | HH:mm:ss')}</Typography>
                        </Box>
                        <Box>
                            <Typography>{t('Usuário:')} <strong>{this.state.userInfos.data.nm_usuario}</strong></Typography>
                            <Typography>{t('Setor:')} <strong>{this.state.userInfos.data.ds_setorproducao}</strong></Typography>
                        </Box>
                        <Box>
                            <Typography>{t('Empresa:')} <strong>{this.state.userInfos.data.cd_empresa} - {this.state.company.razao}</strong></Typography>
                            <Typography>{t('Entidade:')} <strong>{this.state.userInfos.entidade}</strong></Typography>
                        </Box>
                    </Box>

                    {/* Segunda Box */}
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            padding: '10px 15px',
                            margin: '15px 0',
                            borderRadius: '5px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gap: '15px',
                                gridTemplateColumns: { md: '100%', lg: '45% 12% 13% 13% 13%' },
                            }}
                        >
                            <MainSelectField
                                ref={this.itemRef}
                                {...this.props}
                                id='portionedItemId'
                                value={this.state.portionedItemId}
                                optionsList={this.state.data.filter(item => item.at_situacao === 1).map(item => ({ label: `${item.cd_item} - ${item.ds_item}`, value: item.id_item }))}
                                label={t('Item a ser Porcionado')}
                                handleChange={this.handleChangeTextItemToBePortioned}
                                onFocus={this.onInputFocus}     // recebe os params do input selecionado
                                onKeyUp={this.handleKeyUp}
                                required
                            />
                            <MainTextField
                                {...this.props}
                                id='unit'
                                value={this.state.unit || ''}
                                label={t('Un. Medida')}
                                disabled={true}
                            />
                            <MainTextField
                                ref={this.qtyRef}
                                {...this.props}
                                id='qty'
                                value={this.state.qty || ''}
                                label={t('Quantidade')}
                                handleChange={this.handleChangeText}
                                onFocus={this.onInputFocus}
                                onKeyUp={this.handleKeyUp}
                                type="number"
                                maxDecimals={4}
                                required
                            />
                            <MainTextField
                                ref={this.weightRef}
                                {...this.props}
                                id='weight'
                                value={this.state.weight || ''}
                                label={t('Peso (Kg)')}
                                handleChange={this.handleChangeText}
                                onFocus={this.onInputFocus}
                                onKeyUp={this.handleKeyUp}
                                type="number"
                                maxDecimals={4}
                                required
                            />
                            <MainTextField
                                {...this.props}
                                id='averageWeight'
                                value={this.state.averageWeight || ''}
                                label={t('Peso Médio (Kg)')}
                                handleChange={() => { }}
                                type="number"
                                disabled={true}
                            />
                        </Box>

                        <Divider sx={{ backgroundColor: this.props.colors.primary[400], margin: '20px 0 0 0' }} />

                        <Box
                            sx={{
                                display: 'grid',
                                gap: '15px',
                                gridTemplateColumns: { xs: '100%', md: '100%', lg: '75% 25%' },
                            }}
                        >

                            <EditableTable
                                {...this.props}

                                selectedId={this.state.selectedId}
                                selectedField={this.state.selectedField}
                                viewAsSelected={this.state.viewAsSelected}

                                id='id_desc_item'
                                customMargin='20px 0 15px 0'
                                height='55vh'
                                data={this.state.itemsList}
                                columns={this.state.itemsColumns}
                                rowId='id_item_producao'
                                totalSize={this.state.itemsTotalSize}
                                onPageChange={() => { }}
                                onEditRow={() => { }}
                                onRowDoubleClick={() => { }}
                                isLoading={this.state.isLoadingTable}
                                editableFields={['peso_item', 'quantidade_item']}
                                onCellClick={this.handleCellClick} // Retorna 'params' da célula clicada
                                extraColumnsConfig={
                                    {
                                        'peso_item': {
                                            'type': 'number',
                                        },
                                        'quantidade_item': {
                                            'type': 'number',
                                        }
                                    }
                                }
                                customRowSize={
                                    {
                                        'id_desc_item': 600,
                                        'sg_unidademedida': 80,
                                    }
                                }
                            />

                            <MainNumericKeyboard {...this.props} focusedInput={this.state.focusedInput} shouldSelectAllValue={this.state.shouldSelectAllValue} ref={this.numericKeyboardRef} value={this.state.numericValue} handleChange={this.handleChangeTextNumericKeyboard} onButtonClick={this.insertValue} />

                        </Box>
                    </Box>

                    {/* Terceira Box */}
                    <Box
                        sx={{
                            display: 'grid',
                            gap: '15px',
                            alignItems: 'end',
                            gridTemplateColumns: {
                                xs: '1fr',
                                sm: '1fr',
                                md: '1fr 1.5fr 1fr 1fr 1fr 1fr 1fr',
                            },
                        }}
                    >

                        <MainButton {...this.props} sx={{ background: this.props.colors.redAccent[700] }} onButtonClick={() => { this.setState({ isCancelDialogOpen: true }) }} title={t('Cancelar')} />

                        <Box />

                        <MainTextField
                            sx={backgroundStyle}
                            ref={this.wasteRef}
                            {...this.props}
                            id='waste'
                            value={this.state.waste || ''}
                            label={t('Refugo/Perdas (Kg)')}
                            handleChange={this.handleChangeText}
                            onFocus={this.onInputFocus}
                            onKeyUp={this.handleKeyUp}
                            type="number"
                            maxDecimals={4}
                        />

                        <MainTextField
                            sx={backgroundStyle}
                            ref={this.batchRef}
                            {...this.props}
                            id='batch'
                            value={this.state.batch || ''}
                            label={t('Lote')}
                            handleChange={this.handleChangeText}
                            onFocus={this.onInputFocus}
                            onKeyUp={this.handleKeyUp}
                        />

                        <MainTextField
                            sx={backgroundStyle}
                            ref={this.expirationDaysRef}
                            {...this.props}
                            id='expirationDays'
                            value={this.state.expirationDays || ''}
                            label={t('Dias de validade')}
                            handleChange={this.handleChangeText}
                            onFocus={this.onInputFocus}
                            onKeyUp={this.handleKeyUp}
                            type="number"
                        />

                        <MainDateTimeInput
                            {...this.props}
                            id='expirationDate'
                            value={this.state.expirationDate}
                            label={t('Validade')}
                            handleChange={this.handleChangeText}
                            type='date'
                            disabled={true}
                        />

                        <MainButton {...this.props} sx={{ background: this.props.colors.greenAccent[200] }} onButtonClick={this.createOpo} title={t('Finalizar')} disabled={this.state.isSendingRequest ? true : false} />

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(PortionOrder);