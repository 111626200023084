import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import EventPopup from "./popups/EventPopup";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainCheckBoxInput from "../../../components/inputs/MainCheckBoxInput";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import { Box, Button, Divider, Grid, Typography, keyframes } from "@mui/material";
import { checkSendDate, defaultRequest } from "../../../utils/request/request";
import { getDeposit, getNonCompliance } from "../../../utils/request/requestSelects";
import { handleChangeText } from "../../../utils/handleChange";
import { withTranslation } from "react-i18next";


class Danfe extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        this.danfeScrollRef = React.createRef()
        this.itemsScrollRef = React.createRef()
        this.orderScrollRef = React.createRef()

        this.qtyReceivedRef = React.createRef()
        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'ProgramacaoEntrada_Danfe',
            isLoading: true,
            isLoadingTableNote: false,
            isLoadingTableOrder: false,

            activeTab: 'danfe',
            isEventPopupOpen: false,

            tableNoteList: [],
            tableNoteColumns: [
                ['id_danferecebidaitem', t('Item da Nota')],
                ['ds_item_fornecedor', t('Descrição')],
                ['sg_unidademedida', t('UM')],
                ['cd_item_fornecedor', t('Cód. Fab DANFE')],
                ['qt_embalagem', t('Q. Caixa')],
                ['ds_embalagem', t('Embalagem')],
                ['vl_unitario', t('V. Unitário')],
                ['qt_convertido', t('Q. Solicitada')],
                ['sg_unidadedemedidapadrao', t('UM Estoque')],
                ['vl_total', t('V. Total')],
                ['status', t('')],
            ],
            tableNoteTotalSize: 0,

            tableOrderList: [],
            tableOrderColumns: [
                ['cd_item', t('Pedido')],
                ['dt_pedido', t('D. Emissão')],
                ['dt_entrega', t('D. Entrada')],
                ['vl_pedido', t('V. Pedido')],
                ['id_danferecebidaitem', t('Item')],
                ['ds_item', t('Descrição')],
                ['qt_pedido', t('Q. Pedida')],
                ['qt_atendida', t('Q. Atendida')],
                ['vl_total_item', t('Total')],
            ],
            tableOrderTotalSize: 0,

            // campos
            releaseDate: dayjs(),
            nonComplianceList: [],
            confirmReceiptWithoutChecking: false,
            nonCompliance: false,
            nonComplianceItem: null,
            deposit: '',
            event: '',
            eventRFP: '',
            eventRFPList: [],
            depositList: [],
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchNonCompliance()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.qtyReceived !== this.state.qtyReceived) {
            this.calculateTable()
        }

        if (prevState.confirmReceiptWithoutChecking === false && this.state.confirmReceiptWithoutChecking === true) {
            this.setState({
                item: '',
                qtyReceived: '',
                difference: '',
                nonComplianceItem: '',
            })
        }

        if (prevState.nonCompliance === true && this.state.nonCompliance === false) {
            const newData = { ...this.state.data }

            newData.id_naoconformidade = null

            this.setState({ data: newData })
        }

        if (prevState.confirmReceiptWithoutChecking === false && this.state.confirmReceiptWithoutChecking === true) {
            const updatedTableNoteList = this.state.tableNoteList.map(item => ({
                ...item,
                qt_recebida: null,
                qt_diferenca: null,
                id_naoconformidade: null,
            }))
            this.setState({ tableNoteList: updatedTableNoteList })
        }
    }

    calculateTable = () => {
        const selectedRow = this.state.tableNoteList.find(row => row.id_danferecebidaitem === this.state.item)

        if (selectedRow) {
            const difference = selectedRow.qt_embalagem - this.state.qtyReceived
            this.setState({ difference: difference.toFixed(4).toString().replace('.', ',') })
        }
    }

    chekcButtonIsDisabled = () => {
        if (this.state.nonCompliance && this.state.data?.id_naoconformidade !== null) {
            const selectedNonCompliance = this.state.nonComplianceList.find(
                item => item.id_naoconformidade === this.state.data?.id_naoconformidade
            )

            if (selectedNonCompliance && selectedNonCompliance.fl_recusa_danfe === 'S') {
                return true
            }
        }

        const rowThatDisables = this.state.tableNoteList.some(row =>
            row.id_naoconformidade && this.state.nonComplianceList.some(
                nc => nc.id_naoconformidade === row.id_naoconformidade && nc.fl_recusa_danfe === 'S'
            )
        )

        if (rowThatDisables) {
            return true
        }

        return false
    }

    fetchCompanyStock = () => {
        let config = {
            endpoint: `everlojas/transferenciaestoque/${this.state.data.cd_empresa}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    serie: r.data.cd_serie,
                    movementType: r.data.id_movimentoestoquetipo,
                    originMovementTypeId: r.data.id_moviestoquetipo_romaneio,
                }, () => this.fetchStockMovementType())
            } else {
                console.error('Erro ao retornar Série e Tipo de Movimento')
            }
        })
    }

    fetchData = async () => {
        try {
            const depositData = getDeposit(this.props.selectedRow.cd_empresa, this.props.user.entidade)
            const danfeData = this.fetchDanfe()

            const [depositList, danfeList] = await Promise.all([depositData, danfeData])

            this.setState({
                depositList: depositList,
                data: danfeList,

                isLoading: false,
                isLoadingTableNote: false,
                isLoadingTableOrder: false
            }, () => {
                this.onNoteItemsTableChange();
                this.fetchCompanyStock();
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                depositList: [],
                data: [],

                isLoading: false,
                isLoadingTableNote: false,
                isLoadingTableOrder: false
            })
        }
    }

    fetchDanfe = async () => {
        let config = {
            endpoint: `dfi/danferecebida/${this.props.lineId}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                return r.data
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar o evento:', error)
            return []
        }
    }

    fetchNonCompliance = async () => {
        try {
            const data = await getNonCompliance(this.props.user.entidade)
            this.setState({ nonComplianceList: data })
        } catch (error) {
            console.error('Error fetching suppliers:', error)
            this.setState({ nonComplianceList: [] })
        }
    }

    fetchStockMovementType = () => {
        let config = {
            endpoint: `adm/movimentoestoquetipo/${this.state.movementType}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    stockMovementType: r.data,
                })
            } else {
                console.error('Erro ao retornar Tipo de Movimento de Estoque')
            }
        })
    }

    handleChangeText = (event) => {
        if (this.state.data && Object.keys(this.state.data).includes(event.target.id)) {    // caso o value esteja em um objeto
            handleChangeText(this.state.data, event.target.id, event.target.value, () => this.forceUpdate())
        } else {
            this.setState({ [event.target.id]: event.target.value })
        }
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.updateItem()
        }
    }

    handleTabClick = (event, tab) => {
        this.setState({ activeTab: event.target.id }, () => this.scrollToRefs(tab))
    }

    onNoteItemsTableChange = (page = 0) => {
        let config = {
            endpoint: `dfi/danferecebidaitem`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'id_danferecebida': this.state.data.id_danferecebida,
            'x-Pagina': page + 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.data && r.data.length > 0) {

                const updatedData = r.data.map(item => ({
                    ...item,
                    qt_recebida: null,
                    qt_diferenca: null,
                    id_naoconformidade: null,
                    status: 'gray',
                }))

                this.setState({
                    tableNoteList: updatedData,
                    tableNoteTotalSize: r.data.length,
                    isLoading: false,
                    isLoadingTableNote: false
                })
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingTableNote: false,
                    isLoadingTableOrder: false
                })
            }
        })
    }

    onOrderItemsTableChange = (page = 0) => {
        let config = {
            endpoint: `dfi/danferecebidapedidocompra`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'id_danferecebidaitem': this.state.selectedItem,
            'x-Pagina': page + 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && r.data.length > 0) {

                this.setState({
                    tableOrderList: r.data,
                    tableOrderTotalSize: r.data.length,

                    isLoading: false,
                    isLoadingTableOrder: false,
                    isLoadingTableNote: false,
                })
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingTableNote: false,
                    isLoadingTableOrder: false
                })
            }
        })
    }

    refuseDanfe = async () => {
        this.setState({ isLoading: true })

        const chekcButtonIsDisabled = this.chekcButtonIsDisabled()  // Se o botão 'Confirmar Recebimento' estiver desabilitado

        const showAlertAndReturn = (message) => {
            this.showAlert(message, 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasNullDifference = this.state.tableNoteList.some(row => row.qt_diferenca === null)           // Se tiver pelo menos um item sem validação
        const hasNoRowWithDifference = this.state.tableNoteList.every(row => row.qt_diferenca === '0,0000') // se todos os itens estiverem conforme
        const allRowsWithDifferenceNull = this.state.tableNoteList.every(row => row.qt_diferenca === null)  // Se todos os itens não estiverem validados

        if (this.state.nonCompliance) {
            if (!this.state.data.id_naoconformidade || !this.state.data.observacao_nota) {
                return showAlertAndReturn("Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.")
            }
        } else {
            if (allRowsWithDifferenceNull) {
                if (!this.state.nonCompliance || !this.state.data.id_naoconformidade || !this.state.data.observacao_nota) {
                    return showAlertAndReturn("Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.")
                }
            } else {
                if (hasNullDifference) {
                    return showAlertAndReturn("Necessário validar todos os itens.")
                }

                if (hasNoRowWithDifference) {
                    return showAlertAndReturn("Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.")
                }

                if (!chekcButtonIsDisabled) {
                    return showAlertAndReturn("Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.")
                }
            }
        }

        let config = {
            endpoint: `everlojas/danferecebida?x-Entidade=${this.props.user.entidade}`,
            method: 'put'
        }
        let form = {
            'id_danferecebida': this.props.lineId,
            'dt_lancamento': this.state.releaseDate,
            'cd_deposito': this.state.depositList[0].cd_deposito,
            'cd_usuario': this.props.user.data.cd_usuario,
            'at_situacao': 329, // recusado
            ...(this.state.nonCompliance && {
                'id_naoconformidade': this.state.data.id_naoconformidade,
                'observacao_naoconformidade': this.state.data.observacao_nota,
            }),
            'itens': (this.state.tableNoteList || []).map(item => ({
                'id_danferecebidaitem': item.id_danferecebidaitem,
                ...(item.qt_diferenca !== '0,0000' && !this.state.nonCompliance && !this.state.confirmReceiptWithoutChecking && {
                    'id_naoconformidade': item.id_naoconformidade,
                    'cd_usuario_naoconformidade': this.props.user.data.cd_usuario
                })
            }))
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({ isLoading: false, isRefusedOpen: true })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Falha ao recusar recebimento."
                this.props.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    scrollToRefs = (tab) => {
        if (this[tab] && this[tab].current) {
            this[tab].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    sendDanfe = async () => {
        this.setState({ isLoading: true })

        let response = await checkSendDate(this.state.releaseDate, this.state.data.cd_empresa, this.props.user.entidade) // verifica se o dia está liberado para a operação

        if (!response || !response.status) {
            this.setState({
                alertMessage: response.message ?? '',
                alertType: response.msg_type === 1 ? 'error' : 'warning',
                showAlert: true,

                isLoading: false
            })
            if (response.msg_type === 1) {
                return
            }
        }

        let config = {
            endpoint: `everlojas/danferecebida?x-Entidade=${this.props.user.entidade}`,
            method: 'put'
        }
        let form = {
            'id_danferecebida': this.props.lineId,
            'dt_lancamento': this.state.releaseDate,
            'cd_deposito': this.state.deposit,
            'cd_usuario': this.props.user.data.cd_usuario,
            'at_situacao': 12,
            ...(this.state.nonCompliance && {
                'id_naoconformidade': this.state.data.id_naoconformidade,
                'observacao_naoconformidade': this.state.data.observacao_nota,
            }),
            'itens': (this.state.tableNoteList || []).map(item => ({
                'id_danferecebidaitem': item.id_danferecebidaitem,
                ...(item.qt_diferenca !== '0,0000' && !this.state.nonCompliance && !this.state.confirmReceiptWithoutChecking && {
                    'id_naoconformidade': item.id_naoconformidade,
                    'cd_usuario_naoconformidade': this.props.user.data.cd_usuario
                })
            }))
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({ isLoading: false, isConfirmationOpen: true })
            } else {
                this.props.showAlert('Falha ao confirmar recebimento.', 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    sendEvent = async (nr_documento) => {
        let sum = this.state.tableNoteList.reduce((acc, item) => acc + item.vl_total, 0)
        let dreConta = this.state.eventRFPList.find(item => item.nr_propostarfp === this.state.eventRFP).cd_dreconta

        let config = {
            endpoint: `gpro/propostarfpdespesa?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'dt_lancamento': this.state.releaseDate,
            'at_origem': 539,
            'dt_documento': this.state.releaseDate,
            'nr_documento': nr_documento,   // nr_documento que vem do adm - movimentoestoque
            'cd_dreconta': dreConta,   // vem na proposta RFP
            'cd_serie': this.state.serie,
            'vl_documento': sum, // soma do vl_total das linhas da tabela
            'cd_fornecedor': this.state.company,
            'at_movimentaestoque': 142,
            'ds_observacao': this.state.data.observacao_nota,
            'id_propostarfp': this.state.eventRFP,
            'cd_usuario': this.props.user.data.cd_usuario,
            'cd_empresa': null,
            'nr_parcela': null,
            'cd_propostaPDV': null,
            'id_documento': null,
        }
        try {
            const response = await defaultRequest(config, form)
            if (response.status) {
                return { success: true }
            }
            const cleanMessage = (typeof response.data === 'string') ? response.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
            return { success: false, message: cleanMessage }

        } catch (error) {
            console.log('Erro ao enviar evento: ', error)
            return false
        }
    }

    sendStockMovement = async () => {
        let config = {
            endpoint: `adm/movimentoestoque?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'cd_empresa': this.state.data.cd_empresa,
            'cd_serie': this.state.serie,
            'dt_movimento': this.state.releaseDate,
            'cd_deposito_origem': this.state.deposit,
            'observacao': this.state.data.observacao_nota,
            'cd_usuario': this.props.user.data.cd_usuario,
            'cd_usuario_responsavel': this.props.user.data.cd_usuario,
            'at_situacao': 1,
            'id_movimentoestoquetipo_origem': this.state.originMovementTypeId || null, // vai vir originMovementTypeId

            'itens': (this.state.tableNoteList && this.state.tableNoteList.length > 0) ?
                this.state.tableNoteList.map((item, index) => {
                    return {
                        'id_item': item.id_item,
                        'id_embalagem': item.id_embalagem,
                        'qt_embalagem': item.qt_embalagem,
                        'vl_embalagem_unitario': item.vl_unitario,
                        'vl_embalagem_total': item.vl_total,
                        'qt_convertido': item.qt_convertido,
                        'vl_convertido_unitario': item.vl_convertido_unitario,
                        'at_movimentacao': 142,
                        'fl_movimento_custo': this.state.stockMovementType?.fl_movimento_custo || 0,    // recebe do fetchStockMovementType
                    }
                }) : []
        }
        try {
            const response = await defaultRequest(config, form)
            if (response.status) {
                const eventResult = await this.sendEvent(response.data.nr_documento)
                if (!eventResult.success) {
                    return { success: false, message: eventResult.message }
                }
                return { success: true }
            }

            const cleanMessage = (typeof response.data === 'string') ? response.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
            return { success: false, message: cleanMessage }

        } catch (error) {
            console.log('Erro ao enviar movimento de estoque: ', error)
            return false
        }
    }

    setItemToCheck = (row) => {
        const selectedRow = this.state.tableNoteList.find(
            item => item.id_danferecebidaitem === row.id_danferecebidaitem
        )

        if (selectedRow && !this.state.confirmReceiptWithoutChecking && !this.state.nonCompliance) {
            const difference = selectedRow.qt_embalagem - (selectedRow.qt_recebida || 0)
            const qtDiferencaValue = difference.toFixed(4).toString().replace('.', ',')

            this.setState({
                item: selectedRow.id_danferecebidaitem,
                qtyReceived: selectedRow.qt_recebida !== null ? selectedRow.qt_recebida : '',
                difference: qtDiferencaValue,
                nonComplianceItem: selectedRow.id_naoconformidade,
                selectedRowIds: selectedRow.id_danferecebidaitem, // seta o id da linha que deve ser selecionada
            },
                () => this.qtyReceivedRef.current.focus()   // foca em itemPacking após mudar de linha
            )
        }
    }

    setList = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    updateItem = () => {
        const { item, qtyReceived, nonComplianceItem, difference, tableNoteList } = this.state

        if (!item) {
            this.showAlert('Nenhum item selecionado.', 'error')
            return
        }

        if (!qtyReceived) {
            this.showAlert("O campo 'Q. Recebida' é obrigatório.", 'error')
            return
        }

        if (difference === '0,0000' && nonComplianceItem) {
            this.showAlert("Não é necessário selecionar 'Não Conformidade Item' quando não há diferença.", 'error')
            return
        }

        if (qtyReceived && difference !== '0,0000' && !nonComplianceItem) {
            this.showAlert("O campo 'Não Conformidade Item' é obrigatório quando há diferença.", 'error')
            return
        }

        const itemIndex = tableNoteList.findIndex(row => row.id_danferecebidaitem === item)

        if (itemIndex !== -1) {
            const updatedTableNoteList = [...tableNoteList]

            updatedTableNoteList[itemIndex] = {
                ...updatedTableNoteList[itemIndex],
                qt_recebida: qtyReceived,
                id_naoconformidade: nonComplianceItem,
                qt_diferenca: difference,
                status: difference === '0,0000' ? 'green' : 'yellow'
            }
            this.setState({ tableNoteList: updatedTableNoteList, item: '', qtyReceived: '', difference: '', nonComplianceItem: '' },
                () => {
                    this.showAlert('Item Conferido.', 'success');

                    // Verifica se não está na última linha
                    if (itemIndex < tableNoteList.length - 1) {
                        // Seleciona a próxima linha
                        this.setItemToCheck(tableNoteList[itemIndex + 1])
                    }
                }
            )
        }
    }

    verifyRequest = () => {
        const { confirmReceiptWithoutChecking, data, releaseDate, nonCompliance, tableNoteList } = this.state

        const rowWithDifferenceNull = tableNoteList.some(row =>
            row.qt_diferenca === null
        )

        if (!releaseDate) {
            this.showAlert("Preencha todos os campos obrigatórios (*)", 'error')
            return
        }

        if (nonCompliance && (!data.id_naoconformidade || !data.observacao_nota)) {
            this.showAlert("Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.", 'error')
            return
        }

        if (!confirmReceiptWithoutChecking && rowWithDifferenceNull && !nonCompliance) {
            this.showAlert("Há itens sem conferência na tabela.", 'error')
            return
        }

        this.setState({ isEventPopupOpen: true })
    }


    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.getInnerBoxStyle(this.props, rightSlide)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Danfe')}: ${this.state.data?.nr_nota}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => { this.props.toggleVisiblePage('isDANFEVisible'); this.props.onEntrySchedulingTableChange() }}
                        />

                        <DialogAlert
                            {...this.props}
                            type="cancel"
                            isOpen={this.state.isRefusedOpen}
                            title={`${t('Danfe')}: ${this.state.data?.nr_nota}, ${t('recusada com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => { this.props.toggleVisiblePage('isDANFEVisible'); this.props.onEntrySchedulingTableChange() }}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isDANFEVisible')}
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'fixed', marginTop: '10px' }}>
                            <Button
                                id="danfe"
                                sx={styles.getButtonStyle(this.props, this.state.activeTab, 'danfe')}
                                onClick={(params) => this.handleTabClick(params, 'danfeScrollRef')}
                            >
                                {t('Danfe')}
                            </Button>
                            <Button
                                id="items"
                                sx={styles.getButtonStyle(this.props, this.state.activeTab, 'items')}
                                onClick={(params) => this.handleTabClick(params, 'itemsScrollRef')}
                            >
                                {t('Itens')}
                            </Button>
                            <Button
                                id="pedidos"
                                sx={styles.getButtonStyle(this.props, this.state.activeTab, 'pedidos')}
                                onClick={(params) => this.handleTabClick(params, 'orderScrollRef')}
                            >
                                {t('Pedidos')}
                            </Button>
                        </Box>

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box ref={this.danfeScrollRef} sx={{ padding: { xs: '0 10px 0 120px', md: '0 10px 0 120px' } }}>

                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                        <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                            {t('DANFE')}:
                                        </Typography>

                                        <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                        <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                            <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                        </Button>
                                    </Box>

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '48% 52%' }, margin: { md: '0', lg: '10px 70px 20px 0' } }}>

                                        <Grid container columnSpacing={3} rowSpacing={2} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                                            <Grid item md={4}><MainTextField {...this.props} id='nr_nota' value={this.state.data.nr_nota} label={t('DANFE')} placeholder={t('[000000]')} type='number' disabled={true} /></Grid>
                                            <Grid item md={3}><MainTextField {...this.props} id='cd_serie_fiscal' value={this.state.data.cd_serie_fiscal} label={t('Série')} placeholder={t('[0]')} type='number' disabled={true} /></Grid>
                                            <Grid item md={5}><MainDateTimeInput {...this.props} id='dh_emissao' value={this.state.data.dh_emissao} label={t('D. Emissão')} placeholder={t('[00/00/0000 - 00:00:00]')} type='date' formatInput="DD/MM/YYYY HH:mm:ss" disabled={true} /></Grid>
                                            <Grid item md={12}><MainTextField {...this.props} id='razao_emitente' value={this.state.data.razao_emitente} label={t('Fornecedor')} placeholder={t('[digite...]')} disabled={true} /></Grid>
                                            <Grid item md={12}><MainTextField {...this.props} id='razao_destinatario' value={this.state.data.razao_destinatario} label={t('Destinatário')} placeholder={t('[digite...]')} disabled={true} /></Grid>
                                            <Grid item md={5}><MainDateTimeInput required {...this.props} id='dh_integracao' value={this.state.data.dh_integracao} label={t('D. Entrada')} placeholder={t('[00/00/0000]')} type='date' disabled={true} /></Grid>
                                            <Grid item md={5}><MainDateTimeInput required {...this.props} id='releaseDate' value={this.state.releaseDate} label={t('D. Lançamento')} placeholder={t('[00/00/0000]')} handleChange={this.handleChangeText} type='date' ref={this.firstFieldRef} /></Grid>

                                            <Grid item md={2}>
                                                <Button className='main-icon-button' sx={{ marginTop: '18px' }} onClick={() => console.log('imprimir')}>
                                                    <i style={{ margin: '5px', fontSize: '20px' }} className="fa-solid fa-print"></i>
                                                </Button>
                                            </Grid>

                                            <Grid item md={8}><MainCheckBoxInput {...this.props} sx={{ marginTop: '15px' }} id='confirmReceiptWithoutChecking' value={this.state.confirmReceiptWithoutChecking} label={t('Confirmar Recebimento Sem Conferência')} handleChange={this.handleChangeText} /></Grid>

                                            <Grid item md={4}>
                                                <MainButton
                                                    {...this.props}
                                                    sx={{ background: this.props.colors.greenAccent[200], marginTop: '20px' }}
                                                    title={t("Confirmar Recebimento")}
                                                    onButtonClick={this.verifyRequest}
                                                    disabled={this.chekcButtonIsDisabled()}
                                                />
                                                {/* <MainButton
                                                    {...this.props}
                                                    sx={{ background: this.props.colors.greenAccent[200], marginTop: '20px' }}
                                                    title={t("Confirmar Recebimento")}
                                                    onButtonClick={this.sendDanfe}
                                                /> */}
                                            </Grid>

                                        </Grid>

                                        <Grid container columnSpacing={3} rowSpacing={1} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                                            <Grid item md={12}><MainTextField {...this.props} id='observacao_nota' value={this.state.data.observacao_nota} label={t('Observação DANFE')} placeholder={t('[digite...]')} handleChange={this.handleChangeText} multiline={true} minRows='3' /></Grid>
                                            <Grid item md={12}><MainTextField {...this.props} id='ds_libera_naoconformidade' value={this.state.data.ds_libera_naoconformidade} label={t('Motivo Autorização')} placeholder={t('[digite...]')} multiline={true} minRows='3' disabled={true} /></Grid>
                                            <Grid item md={7}><MainCheckBoxInput {...this.props} sx={{ marginTop: '15px' }} id='nonCompliance' value={this.state.nonCompliance} label={t('Não Conformidade')} handleChange={this.handleChangeText} /></Grid>
                                            <Grid item md={1}></Grid>

                                            <Grid item md={4}>
                                                <MainButton
                                                    {...this.props}
                                                    sx={{ background: this.props.colors.redAccent[700], marginTop: '20px' }}
                                                    title={t("Recusar DANFE")}
                                                    onButtonClick={this.refuseDanfe}
                                                />
                                            </Grid>

                                            <Grid item md={12}><MainSelectField {...this.props} id='id_naoconformidade' value={this.state.data.id_naoconformidade} label={t('Não Conformidade DANFE')} placeholder={t('[selecione...]')} optionsList={this.state.nonComplianceList.map(item => ({ label: item.ds_naoconformidade, value: item.id_naoconformidade }))} handleChange={this.handleChangeText} disabled={this.state.nonCompliance ? false : true} /></Grid>
                                        </Grid>
                                    </Box>

                                    {!this.state.confirmReceiptWithoutChecking && !this.state.nonCompliance ?
                                        <>
                                            <Divider sx={{ flexGrow: 1, marginRight: '50px' }} />

                                            <Box sx={{ padding: '10px 30px', border: `1px solid ${this.props.colors.orangeAccent[800]}`, margin: '30px 50px 20px 0', borderRadius: '10px' }}>
                                                <Box>
                                                    <Typography sx={{ fontWeight: '500', fontSize: '15px' }}>
                                                        {t('Conferência dos Itens')}:
                                                    </Typography>
                                                </Box>

                                                {/* Campos de adição */}
                                                <Box sx={{ display: 'grid', gap: '0', gridTemplateColumns: { xs: '100%', lg: '8% 13% 10% 62% 10%' }, marginTop: '10px' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '20px 0 0 20px' }}>{t('Item')}</Typography>
                                                        <MainTextField {...this.props} id='item' value={this.state.item} placeholder={t('[0]')} sx={styles.AddItemMainTextField(this.props)} disabled={true} />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography sx={styles.addItemHeader(this.props)}>{t('Q. Recebida')}</Typography>
                                                        <MainTextField {...this.props} id='qtyReceived' value={this.state.qtyReceived} placeholder={t('[digite...]')} handleChange={this.handleChangeText} sx={styles.AddItemMainTextField(this.props)} type="number" maxDecimals={4} ref={this.qtyReceivedRef} onKeyUp={this.handleEnterKeyUp} />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography sx={styles.addItemHeader(this.props)}>{t('Diferença')}</Typography>
                                                        <MainTextField {...this.props} id='difference' value={this.state.difference} placeholder="//" handleChange={this.handleChangeText} sx={styles.AddItemMainTextField(this.props)} disabled={true} />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '0 20px 20px 0' }}>{t('Não Conformidade Item')}</Typography>
                                                        <MainSelectField {...this.props} id='nonComplianceItem' value={this.state.nonComplianceItem} placeholder="//" optionsList={this.state.nonComplianceList.map(item => ({ label: item.ds_naoconformidade, value: item.id_naoconformidade }))} handleChange={this.handleChangeText} sx={styles.AddItemMainSelectField(this.props)} type="number" maxDecimals={4} onKeyUp={this.handleEnterKeyUp} />
                                                    </Box>
                                                    <MainButton {...this.props} title={t('Inserir')} onButtonClick={this.updateItem} sx={{ width: { xs: '100%', lg: '70%' }, height: '30px', border: `1px solid ${this.props.colors.blueAccent[100]}`, textColor: this.props.colors.blueAccent[100], margin: { xs: '30px 0 10px 0', lg: '50px 0 0 15px' } }} />
                                                </Box>
                                            </Box>
                                        </>
                                        :
                                        <></>
                                    }


                                    <Box ref={this.itemsScrollRef} sx={{ padding: { xs: '20px 0 0 0', lg: '0 50px 0 0' }, marginBottom: '20px' }}>
                                        <Accordion
                                            {...this.props}
                                            startOpen
                                            title={t('Itens da Nota:')}
                                            content={
                                                <>
                                                    <EditableTable
                                                        selectedRowIds={this.state.selectedRowIds} // id da linha que deve ser selecionada

                                                        allowEdit
                                                        extraButton
                                                        noEditButton
                                                        noDeleteButton
                                                        {...this.props}
                                                        id='id_danferecebidaitem'
                                                        rowId='id_danferecebidaitem'
                                                        customMargin='0'
                                                        data={this.state.tableNoteList}
                                                        columns={this.state.tableNoteColumns}
                                                        isLoading={this.state.isLoadingTableNote}
                                                        totalSize={this.state.tableNoteTotalSize}
                                                        onPageChange={this.onNoteItemsTableChange}
                                                        onRowClick={(params) => this.setItemToCheck(params)}
                                                        onRowButtonClick={(params) => this.setState({ selectedItem: params.id_danferecebidaitem },
                                                            () => {
                                                                this.onOrderItemsTableChange()
                                                                this.setState({ isLoadingTableOrder: true, isLoadingTableNote: true })
                                                            })}
                                                        extraColumnsConfig={
                                                            {
                                                                'qt_embalagem': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'vl_unitario': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                    'prefix': 'R$'
                                                                },
                                                                'qt_convertido': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'vl_total': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '2',
                                                                    'prefix': 'R$'
                                                                },
                                                                'status': {
                                                                    'type': 'status',
                                                                },
                                                            }
                                                        }
                                                        customRowSize={
                                                            {
                                                                'ds_item_fornecedor': 380,
                                                                'sg_unidademedida': 60,
                                                                'vl_total': 90,
                                                                'status': 50,
                                                            }
                                                        }
                                                    />
                                                </>
                                            }
                                        />
                                    </Box>

                                    <Box ref={this.orderScrollRef} sx={{ padding: { xs: '20px 0 0 0', lg: '0 50px 0 0' } }}>
                                        <Accordion
                                            {...this.props}
                                            startOpen
                                            title={t('Itens do Pedido:')}
                                            content={
                                                <>
                                                    <EditableTable
                                                        {...this.props}
                                                        id='id_pedidocompraitem'
                                                        rowId='id_pedidocompraitem'
                                                        customMargin='0'
                                                        data={this.state.tableOrderList}
                                                        columns={this.state.tableOrderColumns}
                                                        isLoading={this.state.isLoadingTableOrder}
                                                        totalSize={this.state.tableOrderTotalSize}
                                                        onPageChange={this.onOrderItemsTableChange}
                                                        onRowDoubleClick={() => { }}
                                                        extraColumnsConfig={
                                                            {
                                                                'dt_pedido': {
                                                                    'type': 'date',
                                                                },
                                                                'dt_entrega': {
                                                                    'type': 'date',
                                                                },
                                                                'vl_pedido': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                    'prefix': 'R$'
                                                                },
                                                                'qt_pedido': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'qt_atendida': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'vl_total_item': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '2',
                                                                    'prefix': 'R$'
                                                                },
                                                            }
                                                        }
                                                    />
                                                </>
                                            }
                                        />
                                    </Box>
                                </Box>
                            </>
                        }

                        {this.state.isEventPopupOpen && (
                            <EventPopup
                                {...this.props}
                                {...this.state}
                                isOpen={this.state.isEventPopupOpen}
                                closePopup={() => this.setState({ isEventPopupOpen: false })}
                                handleChangeText={this.handleChangeText}
                                setList={this.setList}
                                sendStockMovement={this.sendStockMovement}
                                sendEvent={this.sendEvent}
                                sendDanfe={this.sendDanfe}
                            />
                        )}

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(Danfe);

const rightSlide = keyframes`from {right: -100%;} to {right: 0;}`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    getInnerBoxStyle: (props, rightAnimation) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px 10px 10px 10px',
        padding: '5px 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        animation: `${rightAnimation} 0.5s`,
    }),
    getButtonStyle: (props, activeTab, tabId) => ({
        height: '50px',
        borderRadius: '0 30px 30px 0',
        letterSpacing: '1px',
        fontWeight: 'bold',
        background: activeTab === tabId ? props.colors.blueAccent[100] : 'transparent',
        color: activeTab === tabId ? 'white' : 'inherit',
        border: `1px solid ${props.colors.grey[800]}`,
        borderLeft: '0',
        ':hover': { background: activeTab === tabId ? props.colors.blueAccent[200] : props.colors.primary[400] }
    }),
    addItemHeader: (props) => ({
        marginTop: '10px',
        color: props.colors.blueAccent[100],
        backgroundColor: props.colors.grey[900],
        padding: '5px 0 5px 10px',
        fontWeight: '600',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    }),
    AddItemMainTextField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiInputBase-input': { boxShadow: 'none !important' },
        '& .MuiOutlinedInput-root': {
            'fieldset': { borderColor: props.colors.orangeAccent[800] },
            '&.Mui-focused fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800] }
        },
        '& .MuiInputBase-input.Mui-disabled': { backgroundColor: 'transparent' }
    }),
    AddItemMainSelectField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800], boxShadow: 'none !important' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { border: 'none' } }
    })
}
