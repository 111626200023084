export const menuList = [
    {
        idmenu: '1',
        descmenu: 'Programação de Entrada',
        icone: <i style={{ color: '#011F3A' }} className="fa-solid fa-box-open"></i>
    },
    {
        idmenu: '2',
        descmenu: 'Suprimentos',
        icone: <i style={{ color: '#011F3A' }} className="fa-solid fa-cart-flatbed"></i>
    },
    // {
    //     idmenu: '3',
    //     descmenu: 'Lançamentos',
    //     icone: <i style={{ color: '#011F3A' }} className="fa-solid fa-file-lines"></i>,
    // },
    // {
    //     idmenu: '4',
    //     descmenu: 'Consultas',
    //     icone: <i style={{ color: '#011F3A' }} className="fa-solid fa-eye"></i>,
    // },
    // {
    //     idmenu: '5',
    //     descmenu: 'Fechamento',
    //     icone: <i style={{ color: '#011F3A' }} className="fa-solid fa-file-invoice-dollar"></i>,
    // },
    // {
    //     idmenu: '6',
    //     descmenu: 'Dashboard',
    //     icone: <i style={{ color: '#011F3A' }} className="fa-solid fa-table-columns"></i>,
    // },
    // {
    //     idmenu: '7',
    //     descmenu: 'Planejamento de Vendas',
    //     icone: <i style={{ color: '#011F3A' }} className="fa-solid fa-magnifying-glass-dollar"></i>,
    // },
]