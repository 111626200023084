export function formatMultipleSelect(input) {
    if (Array.isArray(input)) {
        return input.map(item => item.value).join(', ')
    } else {
        return String(input)
    }
}

export function formatPhoneNumber(phone) {
    // Remove todos os caracteres não numéricos
    const cleaned = phone.replace(/\D/g, '')

    // Verifica o tamanho e aplica o formato correto
    if (cleaned.length === 10) {
        // Formato (XX) XXXX-XXXX
        return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
    } else if (cleaned.length === 11) {
        // Formato (XX) 9XXXX-XXXX
        return cleaned.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2$3-$4')
    } else {
        // Retorna o número original se não for um tamanho esperado
        return phone
    }
}