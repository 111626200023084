import React from "react";

import dayjs from "dayjs";

import i18n from "./i18n";
import Login from "./scenes/login/Login";
import Navbar from "./scenes/global/Navbar";
import Sidebar from "./scenes/global/Sidebar";
import TabBar from "./TabBar";
import TabContent from "./TabContent";
import User from "./scenes/default/User";
import PortionOrder from "./scenes/portioning/PortionOrder";

import { defaultRequest } from "./utils/request/request";
import { I18nextProvider } from 'react-i18next';
import { useMode } from "./typograhpy";
import { TabsProvider } from "./TabsContext";
import { ThemeProvider } from "@mui/material";
import { tokens } from "./typograhpy";

import './css/main.css'

function withHooks(WrappedComponent) {
    return function (props) {
        const [theme] = useMode()
        const colors = tokens()

        return (
            <WrappedComponent colors={colors} theme={theme} {...props} />
        )
    }
}

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 0,
            isLoaded: true,
            loggedIn: false,
            user: {},
            userEdit: false
        }
        dayjs.locale('pt-br')
    }

    componentDidMount() {
        localStorage.removeItem('userData')
        // const user = localStorage.getItem('userData')
        // const userToJson = JSON.parse(user)

        // if (userToJson) {
        //     this.setState({ user: userToJson })
        // }
    }

    getUserImg = (data) => {
        let config = {
            endpoint: `ads/usuarioimagem/${data.data.cd_usuario}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': data.entidade
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    avatar: r.data.im_usuario,
                    avatarMiniature: r.data.im_usuario_miniatura,
                })
            }
        })
    }

    handleCollapse = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed,
            hoverSidebar: !this.state.isCollapsed ? true : false
        })
    }

    handleMouseEnter = () => {
        if (this.state.hoverSidebar) {
            this.setState({
                isCollapsed: !this.state.isCollapsed
            })
        }
    }

    logIn = (data) => {
        this.setState({ loggedIn: true, user: data })
    }

    switchTab = (title) => {
        this.setState({ activeTab: title, userEdit: false })
    }
    

    render() {
        if (!this.state.loggedIn) {
            return (
                <>
                    <Login {...this.props} loggedIn={this.logIn} getUserImg={this.getUserImg} />
                </>
            )
        } else {
            return (
                <I18nextProvider i18n={i18n}>
                    <TabsProvider>
                        <ThemeProvider theme={this.props.theme}>
                        {/* <PortionOrder {...this.props} user={this.state.user}/> */}
                        <div className="app">

                            <Navbar className="navbar" {...this.props} user={this.state.user} handleUserEditScreen={(param) => this.setState({ userEdit: param })} avatarMiniature={this.state.avatarMiniature} />

                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {this.state.userEdit ?
                                    <User {...this.props} handleUserEditScreen={(param) => this.setState({ userEdit: param })} user={this.state.user} loggedIn={this.logIn} avatar={this.state.avatar} getUserImg={this.getUserImg} />
                                    :
                                    <>
                                        <Sidebar className="sidebar" {...this.props} user={this.state.user} handleCollapse={this.handleCollapse} isCollapsed={this.state.isCollapsed} hoverSidebar={this.state.hoverSidebar} handleMouseEnter={this.handleMouseEnter} activeTab={this.state.activeTab} switchTab={this.switchTab} />

                                        <main className="main max-sidebar">
                                            <TabBar {...this.props} user={this.state.user} switchTab={this.switchTab} />
                                            <TabContent {...this.props} user={this.state.user} />
                                        </main>
                                    </>
                                }
                            </div>
                        </div>
                        </ThemeProvider>
                    </TabsProvider>
                </I18nextProvider>
            )
        }
    }
}

export default withHooks(App)