import React from "react";

import dayjs from "dayjs";
import Danfe from "./lateralViews/Danfe";
import EditableTable from "../../components/tables/EditableTable";
import Filter from "./lateralViews/Filter";
import LoadingGif from "../../components/visual/LoadingGif";
import MainTextField from "../../components/inputs/MainTextField";
import Nfse from "./lateralViews/Nfse";
import PedidoCompra from "./lateralViews/PedidoCompra";
import Romaneio from "./lateralViews/Romaneio";
import SnackbarAlert from "../../components/alerts/SnackbarAlert";

// Icons
import SearchIcon from "@mui/icons-material/Search";

import { Box, Button, Tooltip } from "@mui/material";
import { defaultRequest } from "../../utils/request/request";
import { formatMultipleSelect } from "../../utils/format";
import { withTranslation } from "react-i18next";


class EntryScheduling extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        const defaultCompany = this.props.user.data.empresas.filter(empresa => empresa.cd_empresa === this.props.user.data.cd_empresa)[0]
        const selectCompany = [{ label: `${defaultCompany.cd_empresa} - ${defaultCompany.fantasia}`, value: defaultCompany.cd_empresa }]

        this.state = {
            screenId: 'ProgramacaoEntrada',
            isLoading: true,
            isLoadingTable: true,

            totalValue: '',
            lineId: '',

            alertType: null,
            alertMessage: null,
            showAlert: false,

            isFilterVisible: false,
            isDANFEVisible: false,
            isROMANEIOVisible: false,
            isCOMPRAVisible: false,
            isNFSEVisible: false,

            tableList: [],
            tableColumns: [
                ['ds_origem', t('Origem')],
                ['cd_numero', t('Número')],
                ['ds_fornecedor_fantasia', t('Fornecedor - Fantasia')],
                ['ds_fornecedor_razao', t('Fornecedor - Razão')],
                ['dt_chegada', t('Data')],
                ['vl_total', t('Valor')],
                ['cd_serie', t('Série')],
                ['ds_loja', t('Loja')],
                ['pedidos', t('Pedidos Vinculados')],
            ],
            tableTotalSize: 0,

            // Campos
            search: '',

            company: selectCompany,
            supplier: [],
            queryCode: '',

            inicialDate: dayjs(),
            finalDate: dayjs(),

            danfe: true,
            romaneio: true,
            pedidoCompra: false,
            nfse: false,

            defaultCompany: defaultCompany,
        }
    }

    componentDidMount() {
        this.onEntrySchedulingTableChange()
    }

    changeFavicon = (iconURL) => {
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = iconURL
        document.getElementsByTagName('head')[0].appendChild(link)
    }

    clearFields = () => {
        const defaultCompany = this.props.user.data.empresas.filter(empresa => empresa.cd_empresa === this.props.user.data.cd_empresa)[0]
        const selectCompany = [{ label: `${defaultCompany.cd_empresa} - ${defaultCompany.fantasia}`, value: defaultCompany.cd_empresa }]

        this.setState({
            company: selectCompany,
            queryCode: '',
            supplier: [],
            selectedRow: null,

            inicialDate: dayjs(),
            finalDate: dayjs(),

            danfe: true,
            romaneio: true,
            pedidoCompra: false,
            nfse: false,
        })
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.onEntrySchedulingTableChange()
        }
    }

    handleChangeText = (event) => {
        if (event.target.id === 'search' && event.target.value === '6666969') {
            this.changeFavicon('easter.ico')
        }

        this.setState({ [event.target.id]: event.target.value })
    }

    onEntrySchedulingTableChange = (page = 0) => {
        this.setState({ isLoadingTable: true, isDisabled: true })

        let formattedSearch = this.state.search.replace(/,/g, '.')

        const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/   // Verificar se o valor está no formato de data DD/MM/YYYY
        if (datePattern.test(formattedSearch)) {
            formattedSearch = formattedSearch.replace(datePattern, '$3-$2-$1')  // Se for uma data no formato DD/MM/YYYY, converte para YYYY-MM-DD
        }

        let config = {
            endpoint: 'everlojas/programacaoentrada',
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': page + 1,
            'cd_usuario': this.props.user.data.cd_usuario,

            'cd_empresa': formatMultipleSelect(this.state.company) || 0,
            'cd_fornecedor': formatMultipleSelect(this.state.supplier),
            'cd_consulta': this.state.queryCode,

            'dt_periodo_inicial': this.state.inicialDate ? this.state.inicialDate.format('YYYY-MM-DD') : '',
            'dt_periodo_final': this.state.finalDate ? this.state.finalDate.format('YYYY-MM-DD') : '',

            'pesquisa_danfe': this.state.danfe ? 'S' : 'N',
            'pesquisa_romaneio': this.state.romaneio ? 'S' : 'N',
            'pesquisa_pedido_compra': this.state.pedidoCompra ? 'S' : 'N',
            'pesquisa_nfse': this.state.nfse ? 'S' : 'N',

            'tx_busca': formattedSearch,
        }
        defaultRequest(config, form, true).then((r) => {
            if (r.status && r.data.length > 0) {
                this.setState({
                    tableList: r.data,
                    tableTotalSize: r.data.totalSize ?? r.data.length,

                    isDisabled: false,
                    isLoading: false,
                    isLoadingTable: false,
                    isFilterVisible: false,
                })
                this.showAlert('Dados retornados', 'success')
            } else {
                this.setState({
                    tableList: [],
                    tableTotalSize: 0,

                    isDisabled: false,
                    isLoading: false,
                    isLoadingTable: false,
                })
                this.showAlert('Nenhum dado retornado', 'warning')
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    toggleVisiblePage = (view, lineId = null) => {
        if (view === 'isNFS-EVisible') {
            view = 'isNFSEVisible'
        }
        this.setState({
            [view]: !this.state[view],
            lineId: lineId !== null ? lineId : this.state.lineId,
            selectedRow: this.state.tableList.find(item => item.id_chave === lineId)
        })
    }


    render() {
        const { t } = this.props
        if (this.state.isLoading) {
            return (
                <LoadingGif />
            )
        }
        return (
            <>
                {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}
                <Box className='main-view-box' >

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            flexDirection: { sm: 'column', md: 'row' },
                            gap: '15px',
                        }}
                    >

                        <Tooltip
                            title="Informamos que esta funcionalidade está em fase de desenvolvimento e será disponibilizada em breve. 
                            Agradecemos sua compreensão e paciência enquanto trabalhamos para entregar a melhor experiência possível."
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        minWidth: '700px',
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        backgroundColor: '#A4A4A9',
                                        color: 'white',
                                    },
                                },
                            }}
                            placement="bottom-end"
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', color: this.props.colors.redAccent[700] }}>
                                <i style={{ fontSize: '25px', margin: '0 5px', color: this.props.colors.redAccent[700] }} class="fa-solid fa-circle-exclamation" />
                                As funções dos ícones
                                <i style={{ margin: '0 8px' }} className="fa-solid fa-print" />
                                ainda estão em desenvolvimento.
                            </Box>
                        </Tooltip>

                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', width: '450px' }}>
                            <MainTextField
                                {...this.props}
                                id='search'
                                value={this.state.search}
                                placeholder={t('Buscar na tabela [origem, número, fornecedor, etc]...')}
                                handleChange={this.handleChangeText}
                                onKeyUp={this.handleEnterKeyUp}
                                sx={{ '& .MuiInputBase-root': { borderRadius: '25px !important' }, '& .MuiInputBase-input': { borderRadius: '25px !important' } }}
                            />
                            <Button sx={{ minWidth: '0', borderRadius: '50%', position: 'absolute', right: '2px', top: '3px', ':hover': { background: this.props.colors.grey[900] } }} onClick={() => this.onEntrySchedulingTableChange(0)}>
                                <SearchIcon sx={{ background: 'transparent', color: this.props.colors.orangeAccent[400], fontSize: '25px' }} />
                            </Button>
                        </Box>

                        <Button sx={{ background: this.props.colors.grey[900], borderRadius: '50%', minWidth: '40px', maxWidth: '40px', maxHeight: '40px' }} onClick={() => this.toggleVisiblePage('isFilterVisible')}>
                            <i style={{ margin: '5px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-sliders"></i>
                        </Button>

                    </Box>

                    <EditableTable
                        {...this.props}
                        allowEdit
                        noDeleteButton
                        noEditButton
                        EntrySchedulingFilter
                        id='id_linha'
                        height='70vh'
                        data={this.state.tableList}
                        columns={this.state.tableColumns}
                        rowId='id_linha'
                        totalSize={this.state.tableTotalSize}
                        onPageChange={this.onEntrySchedulingTableChange}
                        onRowDoubleClick={(params) => this.toggleVisiblePage(`is${params.ds_origem}Visible`, params.id_chave)}
                        isLoading={this.state.isLoadingTable}
                        toggleVisiblePage={this.toggleVisiblePage}
                        extraColumnsConfig={
                            {
                                'dt_chegada': {
                                    'type': 'date',
                                },
                                'vl_total': {
                                    'type': 'number',
                                    'maxDecimals': '2',
                                    'prefix': 'R$'
                                },
                                'pedidos': {
                                    'type': 'number',
                                },
                            }
                        }
                        customRowSize={
                            {
                                'ds_origem': 90,
                                'cd_numero': 90,
                                'ds_fornecedor_fantasia': 200,
                                'ds_fornecedor_razao': 250,
                                'dt_chegada': 90,
                                'vl_total': 90,
                                'cd_serie': 80,
                                'ds_loja': 190,
                                'pedidos': 150,
                            }
                        }
                    />

                    {this.state.isFilterVisible &&
                        (
                            <Filter {...this.props} {...this.state} handleChangeText={this.handleChangeText} toggleVisiblePage={this.toggleVisiblePage} clearFields={this.clearFields} getData={this.onEntrySchedulingTableChange} />
                        )
                    }
                    {this.state.isDANFEVisible &&
                        (
                            <Danfe {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} onEntrySchedulingTableChange={this.onEntrySchedulingTableChange} showAlert={this.showAlert} />
                        )
                    }
                    {this.state.isROMANEIOVisible &&
                        (
                            <Romaneio {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} onEntrySchedulingTableChange={this.onEntrySchedulingTableChange} showAlert={this.showAlert} />
                        )
                    }
                    {this.state.isCOMPRAVisible &&
                        (
                            <PedidoCompra {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                        )
                    }
                    {this.state.isNFSEVisible &&
                        (
                            <Nfse {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} onEntrySchedulingTableChange={this.onEntrySchedulingTableChange} showAlert={this.showAlert} />
                        )
                    }
                </Box>
            </>
        )
    }
}


export default withTranslation()(EntryScheduling);
