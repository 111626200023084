import React from "react";

import i18n from "../../i18n";
import MainButton from "../../components/inputs/MainButton";
import MainImageUpload from "../../components/inputs/MainImageUpload";
import MainSelectField from "../../components/inputs/MainSelectField";
import MainTextField from "../../components/inputs/MainTextField";
import SnackbarAlert from "../../components/alerts/SnackbarAlert";

// icons
import LoadingGif from "../../components/visual/LoadingGif";

import { Box, Grid, Typography } from "@mui/material";
import { defaultRequest } from "../../utils/request/request";
import { formatCPF } from "../../utils/helpers";
import { resizeImage } from "../../utils/helpers";
import { withTranslation } from 'react-i18next';


class User extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,

            userInfos: {},
            imageBase64: this.props.avatar || '',

            fullName: this.props.user.data.nm_usuario,
            cpf: formatCPF(this.props.user.data.cpf_cnpj) || '',
            phone: this.props.user.data.nr_fone || '',
            email: this.props.user.data.email_particular,
            language: this.props.user.data.cd_idioma || 'PT_BR',

            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',

            list: [],
            languageOptions: [
                { 'value': 'PT_BR', 'label': 'Português' },
                { 'value': 'EN_US', 'label': 'Inglês' },
                { 'value': 'ES_ES', 'label': 'Espanhol' },
            ],
        }
    }

    componentDidMount() {
        this.getUserInfos()
    }

    componentDidUpdate(prevProps, prevState) {
        const { newPassword, newPasswordConfirmation } = this.state

        if (prevState.newPassword !== newPassword || prevState.newPasswordConfirmation !== newPasswordConfirmation) {
            if (newPassword && newPasswordConfirmation) {
                const newList = this.validatePasswords()
                this.setState({ list: newList })
            } else {
                this.setState({ list: [] })
            }
        }
    }

    getUserInfos = () => {
        let config = {
            endpoint: `ads/usuariosenha/${this.props.user.data.cd_usuario}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    userInfos: r.data,
                    isLoading: false,
                })
            } else {
                this.setState({
                    isLoading: false
                })
            }
        })
    }

    handleChangeImage = (newHex) => {
        this.setState({ imageBase64: newHex })
    }

    handleChangeText = (event) => {
        if (event.target.id === 'phone') { // formatar telefone
            const formattedValue = event.target.value
                .replace(/\D/g, '')              // Remove qualquer coisa que não seja número
                .replace(/(\d{2})(\d)/, '$1 $2') // Coloca espaço após os dois primeiros dígitos
                .replace(/(\d{5})(\d)/, '$1 $2') // Coloca espaço após os cinco próximos dígitos
                .substring(0, 13)                // Limita a 13 caracteres

            this.setState({ [event.target.id]: formattedValue })
        }
        else {
            this.setState({ [event.target.id]: event.target.value })
        }
    }

    updateUserImage = async () => {
        const { t } = this.props
        const resizedImage = await resizeImage(this.state.imageBase64, 200)
        const resizedThumbnail = await resizeImage(this.state.imageBase64, 50)

        let config = {
            endpoint: `ads/usuarioimagem/${this.props.user.data.cd_usuario}?x-Entidade=${this.props.user.entidade}`,
            method: 'put'
        }
        let form = {
            cd_usuario: this.props.user.data.cd_usuario,
            im_usuario: resizedImage,
            im_usuario_miniatura: resizedThumbnail
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.props.getUserImg(this.props.user) // fazer get na imagem no componente App

                this.setState({
                    alertMessage: t('Dados atualizados com sucesso!'),
                    alertType: 'success',
                    showAlert: true,

                    isLoading: false,
                })
            } else {
                this.setState({
                    alertMessage: t('Erro ao executar a operação.'),
                    alertType: 'error',
                    showAlert: true,

                    isLoading: false
                })
            }
        })
    }

    updateUserInfos = (password) => {
        const { t } = this.props

        this.setState({ isLoading: true })

        let config = {
            endpoint: `ads/usuariosenha/${this.props.user.data.cd_usuario}?x-Entidade=${this.props.user.entidade}`,
            method: 'put'
        }
        let form = {
            'nm_usuario': this.state.fullName,
            'nr_fone': this.state.phone,
            'cd_idioma': this.state.language,
            'senha': password,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                const data = {
                    ...this.props.user,
                    data: {
                        ...this.props.user.data,
                        nm_usuario: this.state.fullName,
                        nr_fone: this.state.phone,
                        cd_idioma: this.state.language,
                    },
                }
                this.props.loggedIn(data) // atualiza a prop 'user' com os novos dados
                i18n.changeLanguage(this.state.language)
                this.setState({
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: '',
                }, () =>
                    this.updateUserImage() // chama função para atualizar imagem
                )
            } else {
                this.setState({
                    alertMessage: t('Erro ao executar a operação.'),
                    alertType: 'error',
                    showAlert: true,

                    isLoading: false
                })
            }
        })
    }

    validatePasswords = () => {
        const { t } = this.props

        const newList = []

        const isLengthValid = (password) => password.length >= 8 && password.length <= 20

        const isPasswordValid = (password) => {
            const hasUpperCase = /[A-Z]/.test(password)
            const hasLowerCase = /[a-z]/.test(password)
            const hasNumber = /\d/.test(password)
            const hasSpecialChar = /[@$!%*?&]/.test(password)

            return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar
        }

        const newPassword = this.state.newPassword.trim()
        const newPasswordConfirmation = this.state.newPasswordConfirmation.trim()

        if (!isLengthValid(newPassword) || !isLengthValid(newPasswordConfirmation)) {
            newList.push(
                <p>{t('As senhas devem ter entre 8 e 20 caracteres.')}</p>
            )
        }
        if (!isPasswordValid(newPassword) || !isPasswordValid(newPasswordConfirmation)) {
            newList.push(
                <p>{t('As senhas devem conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.')}</p>
            )
        }
        if (newPassword !== newPasswordConfirmation) {
            newList.push(
                <p>{t('As senhas devem coincidir.')}</p>
            )
        }
        return newList
    }

    verifyUpdate = () => {
        const { t } = this.props
        const { phone, currentPassword, newPassword, newPasswordConfirmation, userInfos } = this.state

        if (phone.length < 13 && phone.length !== 0) {  // Se phone não está completo e é diferente de zero
            this.setState({
                alertMessage: t('Telefone inválido.'),
                alertType: 'error',
                showAlert: true,
            })
            return
        }

        if (!currentPassword && !newPassword && !newPasswordConfirmation) {
            this.updateUserInfos(this.state.userInfos.senha)
        }
        else {
            if (!currentPassword || !newPassword || !newPasswordConfirmation) {     // Verifica se todos os campos de senha foram preenchidos para alterar a senha atual
                this.setState({
                    alertMessage: t('Necessário preencher todos os campos para alterar a senha atual.'),
                    alertType: 'error',
                    showAlert: true,
                })
                return
            }

            if (currentPassword !== userInfos.senha) {  // se a senha atual informada estiver errada
                this.setState({
                    alertMessage: t('A Senha atual informada está incorreta.'),
                    alertType: 'error',
                    showAlert: true,
                })
                return
            }

            if (newPassword && newPasswordConfirmation) {   // Valida se as novas senhas estão no padrão
                const errors = this.validatePasswords()
                if (errors.length > 0) {
                    this.setState({
                        alertMessage: t('As senhas não atendem aos requisitos necessários.'),
                        alertType: 'error',
                        showAlert: true,
                    })
                    return
                }
            }
            this.updateUserInfos(this.state.newPasswordConfirmation)
        }
    }


    render() {
        const { t } = this.props

        if (this.state.isLoading) {
            return (
                <LoadingGif />
            )
        }

        const backgroundStyle = { '& .MuiOutlinedInput-root': { backgroundColor: 'white' } }

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '85vh',
                    width: '100vw',
                    padding: '30px 50px'
                }}
            >
                {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}
                <Box>
                    <Typography sx={{ color: this.props.colors.grey[500], display: 'flex', alignItems: 'center', gap: '5px', fontSize: '16px', fontWeight: '500', marginBottom: '10px' }}>
                        <i style={{ fontSize: 20 }} className="fa-solid fa-user-pen" />
                        {t('Editar Perfil')}
                    </Typography>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { md: '100%', lg: '19% 52% 29%' },
                            padding: '0 40px'
                        }}
                    >
                        {/* Avatar */}
                        <Box>
                            <MainImageUpload {...this.props} onImageChange={this.handleChangeImage} defaultImageBase64={this.state.imageBase64} component={this} />
                        </Box>

                        {/* Dados pessoais */}
                        <Box sx={{ padding: '10px 10px' }} >
                            <Grid container columnSpacing={4} rowSpacing={2} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                                <Grid item md={12}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                                        {t('Dados Pessoais') + ':'}
                                    </Typography></Grid>
                                <Grid item md={8}>
                                    <MainTextField
                                        {...this.props}
                                        sx={backgroundStyle}
                                        id='fullName'
                                        value={this.state.fullName}
                                        label={t('Nome Completo')}
                                        placeholder={t('Nome Sobrenome') + '...'}
                                        handleChange={this.handleChangeText}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <MainTextField
                                        {...this.props}
                                        sx={backgroundStyle}
                                        id='cpf'
                                        value={this.state.cpf}
                                        label={t('CPF')}
                                        placeholder='000.000.000-00'
                                        handleChange={this.handleChangeText}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item md={8}>
                                    <MainTextField
                                        {...this.props}
                                        id='email'
                                        value={this.state.email}
                                        label={t('E-mail Profissional')}
                                        placeholder='empresa@empresa.com.br'
                                        handleChange={this.handleChangeText}
                                        sx={backgroundStyle}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <MainTextField
                                        {...this.props}
                                        id='phone'
                                        value={this.state.phone}
                                        label={this.state.phone.length < 13 && this.state.phone.length !== 0 ? t('Telefone (Inválido)') : t('Telefone')}
                                        placeholder='(41) 00000-0000'
                                        handleChange={this.handleChangeText}
                                        sx={backgroundStyle}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <MainSelectField
                                        {...this.props}
                                        id='language'
                                        value={this.state.language}
                                        label={t('Idioma da Plataforma')}
                                        optionsList={this.state.languageOptions}
                                        handleChange={this.handleChangeText}
                                        sx={backgroundStyle}
                                    /></Grid>
                                <Grid item md={9}>

                                </Grid>
                                <Grid item md={2}>
                                    <MainButton
                                        {...this.props}
                                        sx={{ textColor: this.props.colors.grey[500], background: this.props.colors.custom['white'], border: `1px solid ${this.props.colors.grey[500]}`, marginTop: '15px', }}
                                        onButtonClick={() => { this.props.handleUserEditScreen(false) }}
                                        title={t('Voltar')}
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <MainButton
                                        {...this.props}
                                        onButtonClick={() => this.verifyUpdate()}
                                        title={t('Salvar')}
                                        sx={{ background: this.props.colors.orangeAccent[400], marginTop: '15px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        {/* Alterar senha */}
                        <Box sx={{ padding: '10px 20px' }}>
                            <Grid container columnSpacing={1} rowSpacing={2} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>

                                <Grid item md={12}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                                        {t('Alterar Senha') + ':'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <MainTextField
                                        {...this.props}
                                        id='currentPassword'
                                        value={this.state.currentPassword}
                                        label={t('Senha Atual') + ':'}
                                        placeholder={t('Insira sua Senha Atual') + '...'}
                                        handleChange={this.handleChangeText}
                                        type='password'
                                        sx={backgroundStyle}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <MainTextField
                                        {...this.props}
                                        id='newPassword'
                                        value={this.state.newPassword}
                                        label={t('Nova Senha') + ':'}
                                        placeholder={t('Insira sua Nova Senha') + '...'}
                                        handleChange={this.handleChangeText}
                                        type='password'
                                        sx={backgroundStyle}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <MainTextField
                                        {...this.props}
                                        id='newPasswordConfirmation'
                                        value={this.state.newPasswordConfirmation}
                                        label={t('Confirme sua Nova Senha') + ':'}
                                        placeholder={t('Confirme sua Nova Senha') + ':'}
                                        handleChange={this.handleChangeText}
                                        type='password'
                                        sx={backgroundStyle}
                                    />

                                </Grid>

                                {this.state.list}
                            </Grid>
                        </Box>
                    </Box>

                </Box>
            </Box>

        )
    }
}

export default withTranslation()(User);