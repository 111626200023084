import React, { forwardRef } from 'react';

import _ from 'lodash';
import dayjs from 'dayjs';

// Icons
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Button, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getNestedProperty } from '../../utils/helpers';
import { GridRowModes, DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridEditInputCell } from '@mui/x-data-grid';


class NumberEditInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: (props.value || '').toString().replace('.', ',')
        }
    }

    handleChange = (event) => {
        const { maxDigits, maxDecimals } = this.props
        const newValue = event.target.value
        // Colocar máximo de dígitos antes e depois da vírgula
        const regex = new RegExp(`^\\d{0,${maxDigits}}(,\\d{0,${maxDecimals}})?$`)

        if (newValue.match(regex)) {
            this.setState({ inputValue: newValue })
            this.props.api.setEditCellValue({
                id: this.props.id,
                field: this.props.field,
                value: newValue.replace(',', '.')
            }, event)
        }
    }

    onInputFocus = (params) => {
        params.target.select()
    }

    render() {
        return (
            <GridEditInputCell
                {...this.props}
                value={this.state.inputValue}
                onChange={this.handleChange}
                onFocus={this.onInputFocus}
                type="text"
            />
        )
    }
}

class SelectEditInput extends React.Component {
    render() {
        const { value, onChange, options } = this.props

        return (
            <Select
                sx={{
                    width: '100%',
                    '& fieldset': { border: 'none' },
                    '& .MuiInputBase-input': { padding: '5px' },
                    '& .MuiInputAdornment-root': { marginLeft: '0 !important' },
                }}
                value={value || ''}
                onChange={onChange}
                displayEmpty
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        )
    }
}

class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDate: props.value
        }
    }

    handleChange = (newValue) => {
        this.setState({
            selectedDate: newValue
        })

        const { id, field, api } = this.props
        api.setEditCellValue({ id, field, value: newValue })
    }

    render() {
        return (
            <DatePicker
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root fieldset': { border: 'none' },
                    '& .MuiInputBase-input': { padding: '5px' }
                }}
                value={this.state.selectedDate}
                onChange={this.handleChange}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
            />
        )
    }
}

class CustomFooter extends React.Component {
    render() {
        const { page, totalSize, onPageChange } = this.props
        const itemsPerPage = 300

        const totalPages = Math.ceil(totalSize / itemsPerPage)
        const start = (page * itemsPerPage) + 1
        const end = Math.min(totalSize, (page + 1) * itemsPerPage)

        if (totalSize !== 0) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '30px', padding: '0 30px', borderRadius: '15px', background: this.props.colors.primary[400] }}>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography>
                            Página {page + 1} de {totalPages}
                        </Typography>
                        <IconButton
                            onClick={() => onPageChange(Math.max(0, page - 1))}
                            disabled={page === 0}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                            onClick={() => onPageChange(Math.min(totalPages - 1, page + 1))}
                            disabled={page === totalPages - 1}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </Box>

                    <Typography sx={{ marginLeft: 'auto' }}>
                        {`${start} a ${end} de ${totalSize}`}
                    </Typography>
                </Box>
            )
        } else {
            return <></>
        }
    }
}


class EditableTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: this.props.data,
            columns: [],
            rowModesModel: {},
            paginationModel: { page: 0, pageSize: 100 },
            page: 0,
            isLoaded: false
        }
    }

    componentWillMount() {
        this.createColumns()
    }

    componentDidUpdate(prevProps) {
        if (this.state.rows !== this.props.data) {
            this.setState({
                rows: this.props.data
            })
        }
        if (prevProps.columns !== this.props.columns) {
            this.setState({
                isLoaded: false
            }, () => this.createColumns())
        }
    }

    calculateColumnWidths(columns, rows) {
        return columns.map(column => {
            let maxWidth = 0
            maxWidth = Math.max(maxWidth, this.getTextWidth(column.headerName))
            rows?.forEach(row => {
                const cellValue = getNestedProperty(row, column.field)?.toString()
                maxWidth = Math.max(maxWidth, this.getTextWidth(cellValue))
            })
            if (this.props.customRowSize && this.props.customRowSize[column.field]) {
                maxWidth = this.props.customRowSize[column.field]
            }
            return { ...column, minWidth: maxWidth }
        })
    }

    createColumns = () => {
        let columns = []
        let keys = this.props.columns

        keys.map((value, index) => {
            const extraColumnsConfig = this.props.extraColumnsConfig?.[value[0]]

            const isEditable = this.props.editableFields
                ? this.props.editableFields.includes(value[0]) && this.props.allowEditOnRow && !(extraColumnsConfig?.disabled)
                : this.props.allowEditOnRow && !(extraColumnsConfig?.disabled)

            var column = {
                field: value[0],
                headerName: value[1].toUpperCase(),
                cellClassName: value[0] + '-column--cell' + (this.props.editableFields?.includes(value[0]) && !(extraColumnsConfig?.disabled) ? ' borders' : ''),
                flex: 1,
                headerAlign: 'left',
                align: 'left',
                editable: isEditable
            }

            if (this.props.extraColumnsConfig && value[0] in this.props.extraColumnsConfig) {
                let type = extraColumnsConfig['type']

                if (type === 'date') {
                    column['type'] = 'date'
                    column['valueGetter'] = (params) => dayjs(params)
                    column['valueFormatter'] = (params) => dayjs(params).format('DD/MM/YYYY')
                    column['renderEditCell'] = (params) => <CustomDatePicker {...params} />
                }
                else if (type === 'select') {
                    column['type'] = 'singleSelect'
                    column['valueOptions'] = extraColumnsConfig['options'].map(option => ({
                        value: option.value,
                        label: option.label,
                    }))
                    column['renderEditCell'] = (params) => (
                        <SelectEditInput
                            value={params.value || ''}
                            onChange={(event) => params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value })}
                            options={extraColumnsConfig['options']}
                        />
                    )
                }
                else if (type === 'number') {
                    column['type'] = 'number'
                    column['align'] = 'right'
                    column['headerAlign'] = 'right'
                    column['renderEditCell'] = (params) => <NumberEditInput {...params} maxDigits={extraColumnsConfig?.maxDigits || 5} maxDecimals={extraColumnsConfig?.maxDecimals || 0} />
                    column['renderCell'] = (params) => {
                        if (params.value !== null && params.value !== undefined) {
                            return `${extraColumnsConfig?.prefix || ''} 
                                    ${params.value.toLocaleString('pt-BR', { minimumFractionDigits: extraColumnsConfig?.maxDecimals || 0, maximumFractionDigits: extraColumnsConfig?.maxDecimals || 0 })}
                                    ${extraColumnsConfig?.suffix || ''}`
                        }
                    }
                }
                else if (type === 'status') {
                    column['renderCell'] = (params) => {
                        const color = params.row.status

                        return <i style={{ fontSize: '18px', color: color, marginTop: '5px' }} class="fa-solid fa-circle-check" />
                    }
                }
                else if (type === 'button') {
                    column['renderCell'] = (params) => {
                        return (
                            <Button
                                disableRipple
                                sx={{
                                    background: 'transparent',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    ":hover": { background: 'transparent' },
                                }}
                                onClick={(event) => {
                                    event.stopPropagation() // Impede onRowClick de disparar
                                    this.props.onRowButtonClick(params.row)
                                }}
                            >
                                <i style={{ fontSize: '18px', color: this.props.colors.grey[500] }} className="fa-solid fa-computer-mouse" />
                            </Button>
                        )
                    }
                }
            }

            if (value[0].split('.').length > 1) {
                var path = 'params.row.' + value[0]
                column['valueGetter'] = (params) => { return eval(path) }
            }

            columns.push(column)
        })

        this.setState({
            columns: this.calculateColumnWidths(columns, this.props.data),
            isLoaded: true
        })
    }

    focusFirstCell = (id) => {
        setTimeout(() => {
            const tableElement = document.querySelector(`#${this.props.id}`)

            if (tableElement) {

                const rowElement = tableElement.querySelector(`[data-id='${id}']`)

                if (rowElement) {
                    // Itera pelos campos editáveis e foca no primeiro que encontrar
                    for (let field of this.props.editableFields) {
                        const cell = rowElement.querySelector(`[data-field='${field}']`)
                        if (cell) {
                            cell.click() // Dispara o modo de edição
                            const inputElement = cell.querySelector('input, textarea, select')
                            if (inputElement) {
                                inputElement.focus()
                                break // Sai do loop após focar no primeiro campo editável
                            }
                        }
                    }
                } else {
                    console.warn('Row not found inside the table.')
                }
            } else {
                console.warn('Table not found.')
            }
        }, 100)
    }

    getTextWidth(text) {
        // Criar um elemento canvas para medir o tamanho do texto
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = '16px Arial' // Ajuste isso para a fonte que você está usando
        return context.measureText(text).width
    }

    handleCancelClick = (id) => () => {
        this.setState({
            rowModesModel: {
                ...this.state.rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            },
        })

        const editedRow = this.state.rows.find((row) => row[this.props.rowId] === id)
        if (editedRow.isNew) {
            this.setState({
                rows: this.state.rows.filter((row) => row[this.props.rowId] !== id),
            })
        }
    }

    handleDeleteClick = (id) => () => {
        const updatedRows = this.state.rows.filter((row) => row[this.props.rowId] !== id)

        this.setState({
            rows: updatedRows,
        }, () => this.setRowsCallback(updatedRows, 'delete', id))
    }

    handleEditClick = (id) => () => {
        this.setState({
            rowModesModel: { ...this.state.rowModesModel, [id]: { mode: GridRowModes.Edit } },
        }, () => {
            this.focusFirstCell(id)
        })
    }

    handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.enterKeyDown || event.key === 'Enter') {
            // O Enter foi pressionado, vamos salvar a linha atual e mover para a próxima
            const currentRowIndex = this.state.rows.findIndex(row => row[this.props.rowId] === params.id)

            if (currentRowIndex >= 0 && currentRowIndex < this.state.rows.length - 1) {
                const nextRow = this.state.rows[currentRowIndex + 1]
                this.handleSaveClick(params.id)() // Salva a linha atual
                this.handleEditClick(nextRow[this.props.rowId])() // Habilita a próxima linha para edição
            }
        }
    }

    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({ rowModesModel: newRowModesModel })
    }

    handleSaveClick = (id) => () => {
        this.setState({
            rowModesModel: { ...this.state.rowModesModel, [id]: { mode: GridRowModes.View } },
        }, () => {
            const currentRowIndex = this.state.rows.findIndex(row => row[this.props.rowId] === id)

            if (currentRowIndex >= 0 && currentRowIndex < this.state.rows.length - 1) {
                const nextRow = this.state.rows[currentRowIndex + 1]
                this.handleEditClick(nextRow[this.props.rowId])()
            }
        })
    }

    onPageChange = (newPage) => {
        this.setState({
            paginationModel: {
                ...this.state.paginationModel,
                page: newPage.page
            }
        }, () => this.props.onPageChange(this.state.paginationModel.page))
    }

    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false }
        this.setState({
            rows: this.state.rows.map((row) => (row[this.props.rowId] === newRow[this.props.rowId] ? updatedRow : row)),
        }, () => this.setRowsCallback(this.state.rows, 'edit', newRow))
        return updatedRow
    }

    setRows = (rows, id) => {
        this.setState({
            rows: rows
        }, this.setRowsCallback(rows[0], 'add'))
    }

    setRowsCallback = (rows, method, extraParam = null) => {
        this.props.onEditRow(rows, method, extraParam, this.state.currentRow)
    }


    render() {
        const { t } = this.props

        var appendedColumns = this.state.columns

        if (this.props.allowEdit) {
            appendedColumns = [
                ...this.state.columns,
                {
                    field: 'actions',
                    type: 'actions',
                    headerName: t('AÇÕES'),
                    width: 120,
                    cellClassName: 'actions',
                    getActions: ({ id, row }) => {
                        const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit

                        if (isInEditMode) {
                            return [
                                <GridActionsCellItem
                                    icon={<SaveIcon />}
                                    label="Save"
                                    onClick={this.handleSaveClick(id)}
                                    sx={{ "& .MuiSvgIcon-root": { 'color': this.props.colors.grey[100] } }}
                                />,
                                <GridActionsCellItem
                                    icon={<CancelIcon />}
                                    label="Cancel"
                                    className="textPrimary"
                                    onClick={this.handleCancelClick(id)}
                                    sx={{ "& .MuiSvgIcon-root": { 'color': this.props.colors.grey[100] } }}
                                />,
                            ]
                        }

                        var buttonList = []

                        if (this.props.extraButton) {
                            buttonList.push(
                                <GridActionsCellItem
                                    icon={<i style={{ color: 'black' }} className="fa-solid fa-computer-mouse"></i>}
                                    label="extra"
                                    onClick={() => this.props.onRowButtonClick ? this.props.onRowButtonClick(row) : null}
                                    sx={{ "& .MuiSvgIcon-root": { color: this.props.colors.grey[100] } }}
                                />
                            )
                        }

                        if (!this.props.noEditButton) {
                            if (this.props.allowEditOnRow) {
                                buttonList.push(
                                    <GridActionsCellItem
                                        icon={<i style={{ color: 'black' }} className="fa-solid fa-pencil"></i>}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={this.handleEditClick(id)}
                                        sx={{ "& .MuiSvgIcon-root": { 'color': this.props.colors.grey[100] } }}
                                    />
                                )
                            } else {
                                buttonList.push(
                                    <GridActionsCellItem
                                        icon={<i style={{ color: 'black' }} className="fa-solid fa-pencil"></i>}
                                        label="Edit"
                                        onClick={() => {
                                            row['id'] = row[this.props.rowId]
                                            this.props.onRowClick(row)
                                        }}
                                        sx={{ "& .MuiSvgIcon-root": { 'color': this.props.colors.grey[100] } }}
                                    />
                                )
                            }
                        }

                        if (!this.props.noDeleteButton) {
                            buttonList.push(
                                <GridActionsCellItem
                                    icon={<i style={{ color: this.props.colors.redAccent[700] }} className="fa-solid fa-trash-can"></i>}
                                    label="Delete"
                                    onClick={this.handleDeleteClick(id)}
                                    sx={{ "& .MuiSvgIcon-root": { color: this.props.colors.grey[100] } }}
                                />
                            )
                        }

                        if (this.props.EntrySchedulingFilter) {
                            if (row.ds_origem === 'NFS-E') {
                                row.ds_origem = 'NFSE'
                            }
                            buttonList.push(
                                <GridActionsCellItem
                                    icon={<i
                                        className="fa-solid fa-print"
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />}
                                    label="pdf"
                                    onClick={() => { }}
                                />,
                                <GridActionsCellItem
                                    icon={<i
                                        className="fa-solid fa-note-sticky"
                                        style={{
                                            color:
                                                row.ds_origem === 'DANFE' ? '#004E94' :
                                                    row.ds_origem === 'ROMANEIO' ? '#61B4FF' :
                                                        row.ds_origem === 'COMPRA' ? '#FD9157' :
                                                            '#965D43',
                                            fontSize: '20px'
                                        }}
                                    />}
                                    label="filter"
                                    onClick={() => this.props.toggleVisiblePage(`is${row.ds_origem}Visible`, row.id_chave)}
                                />
                            )
                        }
                        return buttonList
                    },
                },
            ]
        }
        const calculatedHeight = this.props.height ? this.props.height : this.state.rows.length < 5 ? '30vh' : 'auto'

        return (
            <Box
                m={this.props.customMargin ?? '20px 0 0 0'}
                height={calculatedHeight}
                id={this.props.id}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DataGrid
                        className='editable-table'
                        paginationMode="server"
                        editMode="row"
                        checkboxSelection={this.props.hasCheckBox ?? false}
                        rowSelectionModel={this.props.selectedRowIds} // id da linha que deve ser selecionada
                        disableColumnMenu
                        disableColumnSorting
                        rowHeight={40}
                        loading={this.props.isLoading}
                        initialState={{ pagination: { paginationModel: { pageSize: 100, page: 0 } } }}
                        columns={appendedColumns}
                        rows={this.state.rows}
                        rowCount={this.props.totalSize}
                        getRowId={(row) => row[this.props.rowId]}
                        processRowUpdate={this.processRowUpdate}
                        rowModesModel={this.state.rowModesModel}
                        onPaginationModelChange={(newPage) => this.onPageChange(newPage)}
                        onRowModesModelChange={this.handleRowModesModelChange}
                        onRowEditStop={this.handleRowEditStop}
                        onRowDoubleClick={(params, event) => this.props.onRowDoubleClick(params.row, event)}
                        hideFooterPagination={this.props.hideFooterPagination ?? false}
                        onRowClick={(params, event) => this.props.onRowClick ? this.props.onRowClick(params.row, event) : null}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            if (this.props.handleRowSelection) {
                                this.props.handleRowSelection(newRowSelectionModel)
                            }
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
                        }
                        slots={{
                            footer: () => (
                                <CustomFooter
                                    {...this.props}
                                    page={this.state.paginationModel.page}
                                    totalSize={this.props.totalSize}
                                    pageSize={this.state.paginationModel.pageSize}
                                    onPageChange={(newPage) => this.onPageChange({ page: newPage })}
                                />
                            )
                        }}
                        onCellClick={(params) => { // envia o params da célula clicada
                            const isEditable = this.props.editableFields
                                ? this.props.editableFields.includes(params.field) && !(this.props.extraColumnsConfig?.[params.field]?.disabled)
                                : !(this.props.extraColumnsConfig?.[params.field]?.disabled)

                            if (isEditable && this.props.onCellClick) {
                                this.props.onCellClick(params)
                                this.setState({ selectedCellId: params.id, selectedCellField: params.field })
                            }
                        }}
                        getCellClassName={(params) => {
                            let className = ''
                            // utilizado na OPO para destacar a célula selecionada
                            if (params.id === this.props.selectedId && params.field === this.props.selectedField) { className = 'cell-highlight' }
                            // Aplicar a classe 'long-text-cell' se o texto for maior que X caracteres
                            if (params) { className += ' long-text-cell' }
                            return className.trim()
                        }}

                        sx={{
                            '& .MuiDataGrid-columnHeader': { // borderRadius no cabeçalho da primeira e última coluna da tabela
                                '&[aria-colindex="1"]': {
                                    borderRadius: '20px 0 0 20px'
                                },
                                [`&[aria-colindex="${this.state.columns.length + (this.props.allowEdit && this.props.hasCheckBox ? 2 : this.props.allowEdit || this.props.hasCheckBox ? 1 : 0)}"]`]: {
                                    borderRadius: '0 20px 20px 0'
                                },
                            },
                            "& .cell-highlight": {  // Estilização de célula selecionada
                                backgroundColor: "white",
                                fontWeight: "bold",
                                textAlign: "end",
                                paddingRight: "30px"
                            },
                            "& .long-text-cell": { // Quebrar em duas linhas caso o texto da célula tenha mais de 20 caracteres
                                height: '40px !important',

                                whiteSpace: 'normal !important',
                                overflow: 'hidden !important',
                                display: '-webkit-box !important',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                textOverflow: 'ellipsis',
                                lineHeight: '20px !important',
                                maxHeight: '40px !important',
                            },
                        }}

                    />
                </LocalizationProvider>
            </Box>
        )
    }
}

const ForwardedEditableTable = forwardRef((props, ref) => {
    return <EditableTable {...props} innerRef={ref} />
})

export default ForwardedEditableTable;
