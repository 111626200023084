import React from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { menuList } from "../../utils/layout";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { tokens } from "../../typograhpy";
import { TabsContext } from "../../TabsContext";
import { withTranslation } from "react-i18next";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <MenuItem
            active={selected === title}              // Aba ativa
            style={{ color: colors.grey[500], padding: '10px' }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    )
}

class SidebarComponent extends React.Component {
    static contextType = TabsContext
    constructor(props) {
        super(props)
        this.state = {
            hoverSidebar: this.props.isHoverSideBar,
            selected: '',
            menu: menuList,

            isLoaded: true
        }
    }

    changeActiveMenu = (title, key) => { // tirar o if quando for codar as outras telas
        if (key === '1' || key === '2') {
            this.props.switchTab(key)
            this.setState({
                selected: title,
            }, () => this.context.openTab({ key: key, title: title, content: <></> }))
        }
    }

    handleCollapse = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed,
            hoverSidebar: !this.state.hoverSidebar
        }, () => {
            this.props.handleCollapse(this.state.isCollapsed)
        })
    }

    render() {
        const { t } = this.props
        return (!this.state.isLoaded ? <></> :
            <Box
                sx={{
                    '& .ps-sidebar-root': {
                        width: '140px',
                        minWidth: '140px',
                    },
                    '& .ps-menuitem-root': {
                        marginLeft: this.props.isCollapsed ? '35px' : '0',
                        transition: 'margin-left 0.5s ease-in-out'
                    },
                    '& .ps-sidebar-container': {
                        backgroundColor: this.props.colors.custom['white'],
                        borderRadius: '0 0 15px 0',
                    },
                    '& .ps-menu-button': { // estilo do menu
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto !important',
                        marginBottom: '5px',
                        borderRadius: '10px',
                    },
                    '& .ps-menu-icon': { // Centralizar o icone
                        height: '55px',
                        margin: '0px',
                        marginBottom: '-10px'
                    },
                    '& .ps-menu-icon .fa-solid': { // Tamanho do ícone
                        fontSize: '30px',
                        color: '#A5AAC0'
                    },
                    '& .ps-menu-label': { // estilo da Label
                        fontSize: '14px',
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        color: '#666666'
                    },
                    '& .ps-submenu-content ul li a span': { // estilo do submenu
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                    '& .ps-submenu-expand-icon': {  // remove o ícone de flechinha
                        display: 'none'
                    },
                }}
            >
                <Box sx={{ position: 'relative' }}>
                    <Sidebar id='sidebar' collapsed={this.props.isCollapsed} onMouseEnter={this.props.handleMouseEnter} onMouseLeave={this.props.handleMouseEnter} collapsedWidth="20px" transitionDuration={300}>
                        <Menu>
                            {/* MENU ITEMS */}
                            <Box paddingLeft={this.state.isCollapsed ? undefined : '0%'} sx={{ color: this.props.colors.blueAccent[100], padding: '10px 20px' }}>
                                {this.state.menu?.map((value, index) => {
                                    return (
                                        <React.Fragment key={value.idmenu}>
                                            {value.submenus ?
                                                <SubMenu id={value.idmenu} key={value.idmenu} label={t(value.descmenu)} icon={value.icone}>
                                                    {value.submenus?.map((valueS, indexS) => {
                                                        return (
                                                            <Item
                                                                key={valueS.idmenu}
                                                                title={t(valueS.descmenu)}
                                                                to={valueS.url_redirect}
                                                                selected={this.state.selected}
                                                                setSelected={(title) => this.changeActiveMenu(title, valueS.idmenu)}
                                                            />
                                                        )
                                                    })}
                                                </SubMenu>
                                                :
                                                <Item
                                                    icon={value.icone}
                                                    key={value.idmenu}
                                                    title={t(value.descmenu)}
                                                    to={value.url_redirect}
                                                    selected={this.state.selected}
                                                    setSelected={(title) => this.changeActiveMenu(title, value.idmenu)}
                                                />
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </Box>
                        </Menu>
                    </Sidebar>
                    <Box
                        onClick={this.handleCollapse}
                        sx={{ position: 'absolute', right: '-14px', bottom: '20px', borderRadius: '30px', zIndex: '3', background: this.props.colors.grey[800], height: '20px', width: '20px', padding: '5px', cursor: 'pointer' }}
                    >
                        {this.props.isCollapsed ?
                            (
                                <KeyboardArrowRightIcon />
                            )
                            :
                            (
                                <KeyboardArrowLeftIcon />
                            )
                        }
                    </Box>
                </Box>

            </Box>
        )
    }
}

export default withTranslation()(SidebarComponent);