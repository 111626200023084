import React from "react";

import Count from "./subViews/Count";
import EditableTable from "../../components/tables/EditableTable";
import Entries from "./subViews/Entries";
import EventTransfer from "./subViews/EventTransfer";
import Filter from "./subViews/Filter";
import Inventory from "./subViews/Inventory";
import ItemsMovement from "./subViews/ItemsMovement";
import LoadingGif from "../../components/visual/LoadingGif";
import Losses from "./subViews/Losses";
import MainTextField from "../../components/inputs/MainTextField";
import PortionOrder from "../portioning/PortionOrder"
import ProductionOrder from "./subViews/ProductionOrder";
import PurchaseBySuggestion from "./subViews/PurchaseBySuggestion";
import PurchaseRequest from "./subViews/PurchaseRequest";
import RMI from "./subViews/RMI";
import SnackbarAlert from "../../components/alerts/SnackbarAlert";

// Icons
import SearchIcon from "@mui/icons-material/Search";

import { Box, Button, Tooltip, Typography } from "@mui/material";
import { defaultRequest } from "../../utils/request/request";
import { formatMultipleSelect } from "../../utils/format";
import { getGroup, getLargeGroup, getDeposit, getFamily, getInventoryGroup, getItemType, getManufacturer, getProductList, getRequestGroup, getSubGroup, getSupplier } from "../../utils/request/requestSelects"
import { withTranslation } from "react-i18next";

class Supplies extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        this.state = {
            isLoading: true,
            isLoadingTable: false,
            hoverSideBar: true,

            isFilterVisible: false,
            isLateralViewVisible: true,
            isInventoryVisible: false,
            isRMIVisible: false,
            isPurchaseRequestVisible: false,
            isPurchaseBySuggestionVisible: false,
            isLossesVisible: false,
            isItemsMovementVisible: false,
            isEventTransferVisible: false,
            isCountVisible: false,
            isEntriesVisible: false,
            isProductionOrderVisible: false,
            isOPOVisible: false,

            tableList: [],
            newTableList: [],
            itemsList: [],
            supplierList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição')],
                ['ds_embalagem', t('Embalagem')],
                ['sg_unidademedida', t('UM')],
                ['qt_item', t('Q. na Embalagem')],
                ['qt_saldo', t('Q. Saldo')],
                ['vl_custo', t('V. Saldo')]
            ],
            tableTotalSize: 0,

            // campos
            productionItem: false,
            pendingItem: false,
            balanceItem: false,
            stockItem: false,
            generateExcel: false,

            company: this.props.user.data.cd_empresa,
            deposit: '',
            descriptionItem: '',
            item: '',
            family: '',
            largeGroup: '',
            group: '',
            subGroup: '',
            manufacturer: '',
            locator: '',
            itemType: '',
            inventoryGroup: '',
            requestGroup: '',
            productList: '',
            supplier: '',
            search: '',

            itemsList: [],
            supplierList: [],
        }
    }

    componentDidMount() {
        this.setState({ company: this.props.user.data.cd_empresa }, () => this.fetchData())
    }

    clearFields = () => {
        this.setState({
            company: this.props.user.data.cd_empresa,
            deposit: this.state.depositList[0].cd_deposito,
            descriptionItem: '',
            item: '',
            family: '',
            largeGroup: '',
            group: '',
            subGroup: '',
            manufacturer: '',
            locator: '',
            itemType: '',
            inventoryGroup: '',
            requestGroup: '',
            productList: '',
            supplier: '',
        })
    }

    fetchData = async () => {
        const fetchDataWithErrorHandling = async (requestFunc, ...args) => {
            try {
                const response = await requestFunc(...args)
                return response || []
            } catch (error) {
                console.error(`Error fetching data from ${requestFunc.name}:`, error)
                return []
            }
        }
        try {
            const [
                deposit,
                family,
                group,
                inventoryGroup,
                itemType,
                largeGroup,
                manufacturer,
                productList,
                requestGroup,
                subGroup,
            ] = await Promise.all([
                fetchDataWithErrorHandling(getDeposit, this.state.company, this.props.user.entidade),   // depende da empresa
                fetchDataWithErrorHandling(getFamily, this.props.user.entidade),
                fetchDataWithErrorHandling(getGroup, this.props.user.entidade),
                fetchDataWithErrorHandling(getInventoryGroup, this.state.company, this.props.user.entidade),    // depende da empresa
                fetchDataWithErrorHandling(getItemType, this.props.user.entidade),
                fetchDataWithErrorHandling(getLargeGroup, this.props.user.entidade),
                fetchDataWithErrorHandling(getManufacturer, this.props.user.entidade),
                fetchDataWithErrorHandling(getProductList, this.props.user.entidade),
                fetchDataWithErrorHandling(getRequestGroup, this.state.company, this.props.user.entidade),  // depende da empresa
                fetchDataWithErrorHandling(getSubGroup, this.props.user.entidade),
            ])
            this.setState({
                deposit: deposit[0].cd_deposito,
                depositList: deposit,
                familyList: family,
                groupList: group,
                inventoryGroupList: inventoryGroup,
                itemTypeList: itemType,
                largeGroupList: largeGroup,
                manufacturerList: manufacturer,
                productListList: productList,
                requestGroupList: requestGroup,
                subGroupList: subGroup,

                isLoading: false
            })
        } catch (error) {
            console.error('Error fetching data:', error)
            this.setState({ isLoading: false })
        }
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.onSuppliesTableChange()
        }
    }

    handleRowSelection = (selectedIds) => {
        const selectedRows = this.state.tableList.filter(row => selectedIds.includes(row.cd_item))
        this.setState({ newTableList: selectedRows })
    }

    onSuppliesTableChange = (page = 0) => {
        let formattedSearch = this.state.search.replace(/,/g, '.')

        this.setState({ tableList: [], isLoadingTable: true, isDisabled: true })

        let config = {
            endpoint: 'everlojas/suprimentos',
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': page + 1,

            'item_producao': this.state.productionItem ? 'S' : 'N',
            'item_pendente': this.state.pendingItem ? 'S' : 'N',
            'item_saldo': this.state.balanceItem ? 'S' : 'N',
            'item_estoque': this.state.stockItem ? 'S' : 'N',
            'gerar_excel': this.state.generateExcel ? 'S' : 'N',
            'cd_empresa': this.state.company || 0,

            'cd_usuario': this.props.user.data.cd_usuario,
            'cd_deposito': this.state.deposit || '',
            'id_item': this.state.item || '',
            'ds_item': this.state.descriptionItem || '',
            'cd_familia': this.state.family || '',
            'cd_grandegrupo': this.state.largeGroup || '',
            'cd_grupo': this.state.group || '',
            'cd_subgrupo': this.state.subGroup || '',
            'cd_fabricante': this.state.manufacturer || '',
            'localizador': this.state.locator || '',
            'cd_fornecedor': formatMultipleSelect(this.state.supplier) || '',
            'cd_itemtipo': this.state.itemType || '',
            'cd_grupoinventario': this.state.inventoryGroup || '',
            'cd_gruposolicitacao': this.state.requestGroup || '',

            'tx_busca': formattedSearch,
        }
        defaultRequest(config, form, true).then((r) => {
            if (r.status && r.data.length > 0) {
                this.setState({
                    tableList: r.data,
                    tableTotalSize: r.data.totalSize ?? r.data.length,

                    isDisabled: false,
                    isLoadingTable: false,
                    isFilterVisible: false,
                    isLateralViewVisible: true,
                })
                this.showAlert('Dados retornados', 'success')
            } else {
                this.setState({
                    tableList: [],

                    isDisabled: false,
                    isLoadingTable: false,
                })
                this.showAlert('Nenhum dado retornado', 'warning')
            }
        })
    }

    setList = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    toggleVisiblePage = (view) => {
        if (view !== 'isFilterVisible' && view !== 'isOPOVisible' && this.state.newTableList.length === 0) {
            this.showAlert('Selecione ao menos um item.', 'warning')
            return
        }
        this.setState({ [view]: !this.state[view], isLateralViewVisible: !this.state.isLateralViewVisible })
    }


    render() {
        const { t } = this.props

        var isInvalidProduction = this.state.newTableList.some(item => item.fl_producao !== 'S')
        var isInvalidPurchase = this.state.newTableList.some(item => item.fl_liberar_compra !== 'S')
        var isInvalidLoss = this.state.newTableList.some(item => item.at_tipo_baixa === 243)
        var isInvalidLossItems = this.state.newTableList.filter(item => item.at_tipo_baixa === 243).map(item => `${item.cd_item} - ${item.ds_item}`).join(', ')

        if (this.state.isLoading) {
            return (
                <LoadingGif />
            )
        }
        if (this.state.isOPOVisible) {
            return (
                <PortionOrder {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
            )
        }
        return (
            <>
                {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}
                <Box sx={{ display: 'flex', position: 'relative' }}>
                    <Box className='main-view-box' sx={{ flexGrow: 1 }} >

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                flexDirection: { sm: 'column', md: 'row' },
                                gap: '15px',
                            }}
                        >

                            <Tooltip
                                title="Informamos que esta funcionalidade está em fase de desenvolvimento e será disponibilizada em breve. 
                            Agradecemos sua compreensão e paciência enquanto trabalhamos para entregar a melhor experiência possível."
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            minWidth: '700px',
                                            borderRadius: '10px',
                                            fontSize: '16px',
                                            backgroundColor: '#A4A4A9',
                                            color: 'white',
                                        },
                                    },
                                }}
                                placement="bottom-end"
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', color: this.props.colors.redAccent[700] }}>
                                    <i style={{ fontSize: '25px', margin: '0 5px', color: this.props.colors.redAccent[700] }} class="fa-solid fa-circle-exclamation" />
                                    As funções dos ícones
                                    <i style={{ margin: '0 8px' }} className="fa-solid fa-print" />
                                    ainda estão em desenvolvimento.
                                </Box>
                            </Tooltip>

                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', width: '450px' }}>
                                <MainTextField
                                    {...this.props}
                                    id='search'
                                    value={this.state.search}
                                    placeholder={t('Buscar na tabela [origem, número, fornecedor, etc]...')}
                                    handleChange={this.handleChangeText}
                                    onKeyUp={this.handleEnterKeyUp}
                                    sx={{ '& .MuiInputBase-root': { borderRadius: '25px !important' }, '& .MuiInputBase-input': { borderRadius: '25px !important' } }}
                                />
                                <Button sx={{ minWidth: '0', borderRadius: '50%', position: 'absolute', right: '2px', top: '3px', ':hover': { background: this.props.colors.grey[800] } }} onClick={() => this.onSuppliesTableChange(0)}>
                                    <SearchIcon sx={{ background: 'transparent', color: this.props.colors.orangeAccent[400], fontSize: '25px' }} />
                                </Button>
                            </Box>

                            <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isFilterVisible')}>
                                <i style={{ margin: '5px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-sliders"></i>
                            </Button>
                        </Box>

                        <EditableTable
                            {...this.props}
                            handleRowSelection={this.handleRowSelection}
                            hasCheckBox
                            id='cd_item'
                            height='60vh'
                            data={this.state.tableList}
                            columns={this.state.tableColumns}
                            rowId='cd_item'
                            totalSize={this.state.tableTotalSize}
                            onPageChange={this.onSuppliesTableChange}
                            onRowDoubleClick={() => { }}
                            isLoading={this.state.isLoadingTable}
                            extraColumnsConfig={
                                {
                                    'qt_item': {
                                        'type': 'number',
                                        'maxDecimals': '4',
                                    },
                                    'qt_saldo': {
                                        'type': 'number',
                                        'maxDecimals': '4',
                                    },
                                    'vl_custo': {
                                        'type': 'number',
                                        'maxDecimals': '4',
                                        'prefix': 'R$',
                                    },
                                }
                            }
                        />

                        {this.state.isFilterVisible &&
                            (
                                <Filter {...this.props} {...this.state} handleChangeText={this.handleChangeText} clearFields={this.clearFields} toggleVisiblePage={this.toggleVisiblePage} getData={this.onSuppliesTableChange} fetchData={this.fetchData} setList={this.setList} />
                            )
                        }
                        {this.state.isInventoryVisible &&
                            (
                                <Inventory {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isRMIVisible &&
                            (
                                <RMI {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isPurchaseRequestVisible &&
                            (
                                <PurchaseRequest {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isPurchaseBySuggestionVisible &&
                            (
                                <PurchaseBySuggestion {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isLossesVisible &&
                            (
                                <Losses {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isItemsMovementVisible &&
                            (
                                <ItemsMovement {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isEventTransferVisible &&
                            (
                                <EventTransfer {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isCountVisible &&
                            (
                                <Count {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isEntriesVisible &&
                            (
                                <Entries {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }
                        {this.state.isProductionOrderVisible &&
                            (
                                <ProductionOrder {...this.props} {...this.state} toggleVisiblePage={this.toggleVisiblePage} />
                            )
                        }

                    </Box>

                    {this.state.isLateralViewVisible && !this.state.isFilterVisible ?

                        <Box sx={{ background: this.props.colors.custom['white'], borderRadius: '10px 0 0 10px' }} onMouseEnter={() => this.setState({ hoverSideBar: true })} onMouseLeave={() => this.setState({ hoverSideBar: false })}>
                            <Box sx={{ display: 'grid', gridTemplateColumns: this.state.hoverSideBar ? '20% 80%' : '100%', padding: '25px', borderRadius: '10px 0 0 10px', gap: '10px' }}>

                                <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isInventoryVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-boxes-stacked"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => this.toggleVisiblePage('isInventoryVisible')}>{t('Inventário')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isRMIVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-file-lines"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => this.toggleVisiblePage('isRMIVisible')}>{t('Lançamento RMI')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => isInvalidPurchase ? this.showAlert('Todos os items devem ser de compra.', 'error') : this.toggleVisiblePage('isPurchaseRequestVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-file-invoice"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => isInvalidPurchase ? this.showAlert('Todos os items devem ser de compra.', 'error') : this.toggleVisiblePage('isPurchaseRequestVisible')}>{t('Solicitação de Compra')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => isInvalidPurchase ? this.showAlert('Todos os items devem ser de compra.', 'error') : this.toggleVisiblePage('isPurchaseBySuggestionVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-bag-shopping"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => isInvalidPurchase ? this.showAlert('Todos os items devem ser de compra.', 'error') : this.toggleVisiblePage('isPurchaseBySuggestionVisible')}>{t('Compra por Sugestão')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => isInvalidLoss ? this.showAlert(`Itens não configurados para baixar estoque como item de consumo: ${isInvalidLossItems}`, 'error') : this.toggleVisiblePage('isLossesVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-xmark"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => isInvalidLoss ? this.showAlert(`Itens não configurados para baixar estoque como item de consumo: ${isInvalidLossItems}`, 'error') : this.toggleVisiblePage('isLossesVisible')}>{t('Perdas')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isItemsMovementVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-arrows-rotate"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => this.toggleVisiblePage('isItemsMovementVisible')}>{t('Movimentação')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isEventTransferVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-arrow-right-arrow-left"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => this.toggleVisiblePage('isEventTransferVisible')}>{t('Transferência de Eventos')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isCountVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-box"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => this.toggleVisiblePage('isCountVisible')}>{t('Contagem')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isEntriesVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-list-check"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => this.toggleVisiblePage('isEntriesVisible')}>{t('Entradas')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => isInvalidProduction ? this.showAlert('Todos os items devem ser de produção.', 'error') : this.state.newTableList.length > 1 ? this.showAlert('Só é possível selecionar 1 item por vez para produção.', 'error') : this.toggleVisiblePage('isProductionOrderVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-check"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => isInvalidProduction ? this.showAlert('Todos os items devem ser de produção.', 'error') : this.state.newTableList.length > 1 ? this.showAlert('Só é possível selecionar 1 item por vez para produção.', 'error') : this.toggleVisiblePage('isProductionOrderVisible')}>{t('Produção')}</Typography> : <></>}

                                <Button className='main-icon-button' onClick={() => this.toggleVisiblePage('isOPOVisible')}>
                                    <i style={{ margin: '10px', fontSize: '20px', color: this.props.colors.blueAccent[100] }} className="fa-solid fa-shop"></i>
                                </Button>
                                {this.state.hoverSideBar ? <Typography className='label-button-sidebar' onClick={() => this.toggleVisiblePage('isOPOVisible')}>{t('Porcionamento')}</Typography> : <></>}
                            </Box>
                        </Box>
                        :
                        <></>
                    }

                </Box>
            </>
        )
    }
}

export default withTranslation()(Supplies);