import React from "react";

import dayjs from "dayjs";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import { Box, Button, Divider, Typography, keyframes } from "@mui/material";
import { checkSendDate, defaultRequest } from "../../../utils/request/request";
import { handleChangeText } from "../../../utils/handleChange";
import { withTranslation } from "react-i18next";


class Romaneio extends React.Component {
    constructor(props) {
        super(props)
        const { t } = props

        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'ProgramacaoEntrada_Romaneio',
            isLoading: true,
            isLoadingTable: true,
            isDialogOpen: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição')],
                ['ds_embalagem', t('Embalagem')],
                ['sg_unidademedida', t('UM')],
                ['qt_embalagem', t('Q. na Embalagem')],
                ['vl_embalagem_unitario', t('V. Unitário')],
                ['qt_convertido', t('Q. Atendido')],
                ['vl_embalagem_total', t('V. Total')],
            ],
            tableTotalSize: 0,

            // campos 
            company: '',
            supplier: '',
        }
    }

    componentDidMount() {
        this.onRomaneioTableChange()
    }

    handleChangeTextObject = (event) => {
        handleChangeText(this.state.data, event.target.id, event.target.value, () => this.forceUpdate())
    }

    onRomaneioTableChange = () => {
        let config = {
            endpoint: `adm/movimentoestoque/${this.props.lineId}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    data: r.data,
                    tableList: r.data.itens,
                    tableTotalSize: r.data.itens.length || 0,

                    isLoading: false,
                    isLoadingTable: false,
                }, () => {
                    this.setFields();
                    if (this.firstFieldRef.current) {
                        this.firstFieldRef.current.focus()
                    }
                })
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingTable: false,
                })
            }
        })
    }

    setFields = () => {
        this.setState((prevState) => ({
            company: this.state.data.cd_empresa_destino,
            supplier: this.props.selectedRow.ds_loja,
            data: {
                ...prevState.data,
                dt_movimento_destino: dayjs()
            }
        }));
    }

    sendRomaneio = async () => {
        const { t } = this.props

        this.setState({ isLoading: true, isDialogOpen: false })

        if (!this.state.data.dt_movimento_destino) {
            this.setState({
                alertMessage: t('Preencha todos os campos obrigatórios (*)'),
                alertType: 'error',
                showAlert: true,

                isLoading: false
            })
            return
        }

        let response = await checkSendDate(this.state.data.dt_movimento_destino, this.state.data.cd_empresa, this.props.user.entidade)

        if (!response || !response.status) {
            this.setState({
                alertMessage: response.message ?? '',
                alertType: response.msg_type === 1 ? 'error' : 'warning',
                showAlert: true,

                isLoading: false
            })
            if (response.msg_type === 1) {
                return
            }
        }

        let config = {
            endpoint: `everlojas/movimentoestoqueromaneio/${this.state.data.id_movimentoestoque}?x-Entidade=${this.props.user.entidade}`,
            method: 'put'
        }
        let form = {
            'id_movimentoestoque': this.state.data.id_movimentoestoque,
            'observacao': this.state.data.observacao,
            'dt_movimento_destino': this.state.data.dt_movimento_destino,
        }

        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({ isLoading: false, isConfirmationOpen: true })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.props.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    // Necessário adicionar position: 'relative' na Box por fora fo  filtro
    render() {
        const { t } = this.props
        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Romaneio de Transferência')}
                            body={t('Após a confirmação de recebimento desse romaneio, a operação não poderá ser estornada. Confirma o recebimento do romaneio ?')}
                            onConfirm={this.sendRomaneio}                          // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Romaneio')}: ${this.state.data?.nr_documento}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => { this.props.toggleVisiblePage('isROMANEIOVisible'); this.props.onEntrySchedulingTableChange() }}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isROMANEIOVisible')}
                        />

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>{t('Romaneio de Transferência')}:</Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>
                                {console.log(this.props)}
                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 60px 0 0' } }}>
                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '10% 25% 25% 15% 15% 3%' }, marginRight: { md: '0', lg: '30px' } }}>
                                        <MainTextField
                                            {...this.props}
                                            id='nr_documento'
                                            value={this.state.data?.nr_documento}
                                            label={t('Romaneio')}
                                            placeholder={t('[0]')}
                                            handleChange={this.handleChangeTextObject}
                                            type='number'
                                            disabled={true}
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='company'
                                            value={this.state.company}
                                            label={t('Empresa')}
                                            placeholder={t('[digite...]')}
                                            optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                            handleChange={this.handleChangeTextObject}
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='supplier'
                                            value={this.state.supplier}
                                            label={t('Fornecedor')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeTextObject}
                                            disabled={true}
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            id='dt_movimento'
                                            value={this.state.data.dt_movimento}
                                            label={t('D. Transferência')}
                                            placeholder={t('[00/00/0000]')}
                                            handleChange={this.handleChangeTextObject}
                                            type='date'
                                            disabled={true}
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            ref={this.firstFieldRef}
                                            required
                                            id='dt_movimento_destino'
                                            value={this.state.data.dt_movimento_destino}
                                            label={t('D. Entrada')}
                                            placeholder={t('[00/00/0000]')}
                                            handleChange={this.handleChangeTextObject}
                                            type='date'
                                        />

                                        <Button className='main-icon-button' sx={{ marginTop: '18px' }} onClick={() => console.log('imprimir')}>
                                            <i style={{ margin: '5px', fontSize: '20px' }} className="fa-solid fa-print"></i>
                                        </Button>
                                    </Box>

                                    <EditableTable
                                        {...this.props}
                                        id='id_item'
                                        rowId='id_item'
                                        height='35vh'
                                        data={this.state.tableList}
                                        columns={this.state.tableColumns}
                                        totalSize={this.state.tableTotalSize}
                                        onPageChange={() => { }}
                                        onRowDoubleClick={() => { }}
                                        isLoading={this.state.isLoadingTable}
                                        extraColumnsConfig={
                                            {
                                                'qt_embalagem': {
                                                    'type': 'number',
                                                    'maxDecimals': '4',
                                                },
                                                'vl_embalagem_unitario': {
                                                    'type': 'number',
                                                    'maxDecimals': '4',
                                                },
                                                'qt_convertido': {
                                                    'type': 'number',
                                                    'maxDecimals': '4',
                                                },
                                                'vl_embalagem_total': {
                                                    'type': 'number',
                                                    'maxDecimals': '2',
                                                },
                                            }
                                        }
                                        customRowSize={
                                            {
                                                'cd_item': 80,
                                                'ds_item': 250,
                                            }
                                        }
                                    />

                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: '20px' }}>
                                        <MainTextField
                                            {...this.props}
                                            id='observacao'
                                            value={this.state.data.observacao}
                                            label={t('Observação')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeTextObject}
                                            multiline={true}
                                            minRows='3'
                                        />
                                        <MainButton
                                            {...this.props}
                                            sx={{ background: this.props.colors.greenAccent[200], marginTop: '20px', width: '200px' }}
                                            title={t('Confirmar Recebimento')}
                                            onButtonClick={() => this.setState({ isDialogOpen: true })}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(Romaneio);

const right = keyframes` from {right: -100%; } to { right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    })
}
