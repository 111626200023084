import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import EditableTable from "../../../components/tables/EditableTable";
import DialogAlert from "../../../components/alerts/DialogAlert";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import ProductionReport from "../../../components/reports/ProductionReport";

import { Box, Button, Divider, Typography, keyframes } from "@mui/material";
import { defaultRequest } from "../../../utils/request/request";
import { getDeposit, getUser } from "../../../utils/request/requestSelects";
import { handleChangeText } from "../../../utils/handleChange";
import { withTranslation } from "react-i18next";


class ProductionOrder extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        const hasNewTableList = Array.isArray(this.props.newTableList) && this.props.newTableList.length > 0

        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'Suprimentos_OrdemProducao',
            isLoading: true,
            isLoadingTable: true,
            isReportOpen: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['ds_embalagem', t('Embalagem')],
                ['sg_unidademedida', t('UM')],
                ['ds_itemtipo', t('Tipo de Item')],
                ['qt_baixa_estoque', t('Q. Baixa Estoque')],
                ['qt_baixa_teorica', t('Q. Baixa Teórica')],
                ['qt_apontada', t('Q. Apontada')], // unico editavel
                ['vl_custo_medio', t('V. Custo')],
                ['vl_custo_total', t('V. Custo Total')],
            ],
            tableTotalSize: 0,

            depositList: [],
            seriesList: [],
            userList: [],
            statusList: [
                { 'value': 1, 'label': 'Ativo' }
            ],

            itemObject: {
                id_item: null,
                ds_item: '',
                id_embalagem: null,
                ds_embalagem: '',
                um: '',
                vl_custo_medio: '',
                vl_custo_prod: '',
                tempo: '',
                lote: null,
                dd_validade: null,
                dt_validade: null,
                qt_receita: null,
                qt_producao: null,
                total_producao: null,
                producao_teorica: null
            },

            company: hasNewTableList ? this.props.newTableList[0].cd_empresa : null,
            deposit: hasNewTableList ? this.props.newTableList[0].cd_deposito : null,
            responsible: this.props.user.data.cd_usuario,
            releaseDate: dayjs(),
            startDate: dayjs(),
            endDate: dayjs(),
            orderNumber: '',
            observation: '',
            status: 1,

            isDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchData()
        this.setState({
            selectedRow: this.props.newTableList[0],
        }, () => this.fetchInputs())
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableList !== this.state.tableList) {
            this.calculateTable()
        }
    }

    calculateTable = () => {
        var totalValue = 0
        const updateTable = this.state.tableList.map(item => {

            const vlTotal = item.qt_apontada * item.vl_custo_medio || 0

            totalValue += vlTotal

            return { ...item, vl_custo_total: vlTotal }
        })

        if (JSON.stringify(this.state.tableList) !== JSON.stringify(updateTable)) {
            setTimeout(() => {
                this.setState({ tableList: updateTable, tableTotalSize: updateTable.length, itemObject: { ...this.state.itemObject, vl_custo_prod: totalValue } })
            }, 100)
        }
    }

    fetchConfig = async () => {
        let config = {
            endpoint: 'opr/configuracaoempresa/' + this.state.company,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }

        const response = await defaultRequest(config, form)
        if (response.status) {
            this.setState({
                companyConfig: response.data,
                isLoading: false
            }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } else {
            this.setState({
                companyConfig: {},
                isLoading: false
            })
        }
    }

    fetchData = async () => {
        try {
            const depositData = getDeposit(this.state.company, this.props.user.entidade)
            const seriesData = this.fetchSeries()
            const userData = getUser(this.props.user.entidade)

            const [depositList, seriesList, userList] = await Promise.all([depositData, seriesData, userData])

            this.setState({
                depositList: depositList,
                seriesList: seriesList,
                userList: userList,

            }, () => this.fetchConfig())
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                depositList: [],
                seriesList: [],
                userList: []
            })
        }
    }

    fetchInputs = async (reFetch = false) => {
        let config = {
            endpoint: `adm/fichatecnica/insumo/${this.state.selectedRow.id_item}`,
            method: 'get',
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'fl_sugerir_qtde_apontada': 'S',
            'qt_receita': this.state.itemObject.qt_receita || 0,
            'qt_producao': this.state.itemObject.qt_producao
        }
        defaultRequest(config, form).then((response) => {
            if (response.status && response.data && Object.keys(response.data).length > 0) {
                this.processInputs(response.data, reFetch)
            } else {
                this.setState({ inputsList: [], isLoadingTable: false, refreshInputs: false })
            }
        })
    }

    fetchSeries = async () => {
        let config = {
            endpoint: `componente/usuarioserie/classe`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'at_classe': 290,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                return r.data
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar o serie:', error)
            return []
        }
    }

    fetchTechnicalSheet = async () => {
        let config = {
            endpoint: `adm/fichatecnica/${this.state.technicalSheetId}`,
            method: 'get',
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }

        try {
            const response = await defaultRequest(config, form)

            if (response.status && response.data && Object.keys(response.data).length > 0) {
                this.setState({
                    technicalSheet: response.data,
                    itemObject: {
                        ...this.state.itemObject,
                        qt_producao: response.data.qt_producao,
                        qt_producao_original: response.data.qt_producao,
                        qt_receita: 1,
                        id_item: this.state.selectedRow.id_item,
                        cd_item: this.state.selectedRow.cd_item,
                        ds_item: this.state.selectedRow.ds_item,
                        id_embalagem: this.state.selectedRow.id_embalagem,
                        ds_embalagem: this.state.selectedRow.ds_embalagem,
                        um: this.state.selectedRow.sg_unidademedida,
                        vl_custo_medio: this.state.selectedRow.vl_unitario,
                        vl_custo_prod: 0, // Somatorio do campo V Custo Total da tabela
                        tempo: response.data.hr_producao.toString().padStart(2, '0') + ':' + response.data.mn_producao.toString().padStart(2, '0'),
                        lote: '', // Se tiver fl_controlar_lote = 's' fica obrigatorio
                        dd_validade: response.data.dd_validade, // Se tiver fl_controlar_lote = 's' pode editar
                        dt_validade: dayjs().add(response.data.dd_validade || 0, 'day'), // Se tiver fl_controlar_lote = 's' pode editar
                        total_producao: response.data.qt_producao * 1, // qt_receita * qt_producao
                        producao_teorica: response.data.qt_producao * 1, // qt_receita * qt_producao (mas só muda quando muda qt_receita),
                    },
                }, () => this.fetchInputs(true))
            }
        } catch (error) {
            console.error('Erro ao buscar inputs:', error)
            this.setState({ inputsList: [], isLoadingTable: false, refreshInputs: false })
        }
    }

    handleChangeItemText = (event) => {
        if (event.target.id === 'qt_producao') {
            this.setState({
                itemObject: {
                    ...this.state.itemObject,
                    qt_producao: event.target.value,
                    total_producao: this.state.itemObject.qt_receita * (event.target.value || 0)
                }
            }, () => this.fetchInputs(true))
        } else if (event.target.id === 'qt_receita') {
            this.setState({
                itemObject: {
                    ...this.state.itemObject,
                    qt_receita: event.target.value,
                    total_producao: this.state.itemObject.qt_producao * (event.target.value || 0),
                    producao_teorica: this.state.technicalSheet?.qt_producao * (event.target.value || 0)
                }
            })
        } else {
            handleChangeText(this.state.itemObject, event.target.id, event.target.value, () => this.forceUpdate())
        }

    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleChangeTextObject = (event) => {
        handleChangeText(this.state.companyConfig, event.target.id, event.target.value, () => this.forceUpdate())
    }

    handleEditRow = (updatedList, method, id) => {
        if (method === 'edit') {
            this.setState({
                tableList: updatedList
            })
        }
        if (method === 'delete') {
            this.setState({
                tableList: updatedList,
                tableTotalSize: updatedList.length
            })
        }
    }

    processInputs = (inputs, reFetch = false) => {
        if (!Array.isArray(inputs)) {
            return console.error('Erro: resposta inesperada da API. Esperado um array, mas recebido:', inputs)
        }

        const technicalSheetId = inputs[0].id_ficha

        const mapOrdem = []

        inputs.forEach(item => {
            mapOrdem.push({ ...item, vl_custo_total: (item.qt_apontada || 0) * (item.vl_custo_medio || 0) })
        })

        this.setState({
            tableList: mapOrdem,
            tableTotalSize: mapOrdem.length,
            technicalSheetId: technicalSheetId,

            isLoadingTable: false,
        }, () => {
            if (!reFetch) {
                this.fetchTechnicalSheet()
            }
        })
    }

    sendProductionOrder = () => {
        const { tableList, companyConfig, itemObject, technicalSheet, company, startDate, endDate, responsible, observation, status, releaseDate } = this.state

        this.setState({ isDialogOpen: false, isLoading: true })

        if (
            // Valida os campos de cima
            !companyConfig.cd_serie ||
            !startDate ||
            !endDate ||
            !responsible ||
            !companyConfig.cd_deposito_insumo ||
            !companyConfig.cd_deposito_producao ||

            // Valida os campos do item
            !itemObject.qt_producao ||
            !itemObject.qt_receita ||

            // Valida os campos caso fl_controlar_lote === 'S'
            (technicalSheet?.fl_controlar_lote === 'S' && (!itemObject.lote || !itemObject.dd_validade || !itemObject.dt_validade))
        ) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasInvalidItem = tableList.some(item => !('qt_apontada' in item) || item.qt_apontada === 0)

        if (hasInvalidItem || tableList.length === 0) {
            this.showAlert('Há items não preenchidos na tabela.', 'error')
            this.setState({ isLoading: false })
            return
        }

        const startDateUtcMinus3 = dayjs(startDate).tz("America/Sao_Paulo").utcOffset(-3, true)
        const endDateUtcMinus3 = dayjs(endDate).tz("America/Sao_Paulo").utcOffset(-3, true)

        let config = {
            endpoint: `opr/ordemproducao?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            "cd_empresa": company,
            "cd_serie": companyConfig.cd_serie,
            "nr_ordem_producao": null,
            "dh_hora_inicio": startDateUtcMinus3.format('YYYY-MM-DDTHH:mm:ss'),  // Formatar para utc -3
            "dh_hora_fim": endDateUtcMinus3.format('YYYY-MM-DDTHH:mm:ss'),   // Formatar para utc -3
            "nm_responsavel": responsible,
            "cd_deposito_insumo": companyConfig.cd_deposito_insumo,
            "cd_deposito_producao": companyConfig.cd_deposito_producao,
            "observacao": observation,
            "id_item": itemObject.id_item,
            "qt_producao": itemObject.qt_producao,
            "vl_custo_medio": itemObject.vl_custo_medio,
            "fl_controlar_lote": technicalSheet.fl_controlar_lote,
            "nr_lote": itemObject.lote,
            "dt_validade": itemObject.dt_validade,
            "nr_dias_validade": itemObject.dd_validade,
            "at_situacao": status,
            "dh_lancamento": releaseDate,
            "qt_producao_total": itemObject.total_producao,
            "qt_producao_teorica": itemObject.producao_teorica,
            "fl_programada": 'N',
            "dt_programacao": null,
            "at_estagio": null,
            "dt_atraso": null,
            "vl_custo": itemObject.vl_custo_prod,
            "id_movimentoestoquetipo_producao": companyConfig.id_movimentoestoquetipo_producao,
            "qt_receita": itemObject.qt_receita,
            "qt_minutos_producao": technicalSheet.mn_producao,
            "id_movimentoestoquetipo_insumo": companyConfig.id_movimentoestoquetipo_insumo,
            "qt_horas_producao": technicalSheet.hr_producao,
            "insumos": (tableList && tableList.length > 0) ?
                tableList.map((item, index) => {
                    return {
                        "id_item": item.id_item,
                        "quantidade": item.qt_apontada,
                        "qt_baixa_estoque": item.qt_baixa_estoque,
                        "vl_custo_medio": item.vl_custo_medio,
                        "at_tipo_baixa": item.at_tipo_baixa,
                        "qt_baixa_teorica": item.qt_baixa_teorica
                    }
                }) : []
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.showAlert('Ação realizada com com sucesso!', 'success')
                this.setState({
                    isLoading: false,
                    isDisabled: true,
                    isConfirmationOpen: true,
                    orderNumber: r.data.nr_ordem_producao,
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }


    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendProductionOrder}                          // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Nr. Ordem')}: ${this.state.orderNumber}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => this.setState({ isConfirmationOpen: false })}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isProductionOrderVisible')}
                        />

                        {this.state.isReportOpen ?
                            <ProductionReport
                                {...this.state}
                                {...this.props}
                                supplier={this.state.supplier}
                                supplierList={this.state.supplierList}
                                columns={this.state.tableColumns}
                                rows={this.state.tableList}
                                onClose={() => this.setState({ isReportOpen: false })}
                            /> : <></>}

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                        {t('Ordem de Produção')}:
                                    </Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                {/* Campos Gerais */}
                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <Accordion
                                        {...this.props}
                                        startOpen
                                        title={`${t('Itens')}:`}
                                        content={
                                            <>
                                                <EditableTable
                                                    {...this.props}
                                                    allowEdit
                                                    allowEditOnRow
                                                    noDeleteButton
                                                    id='table_production_order'
                                                    rowId='id_item'
                                                    data={this.state.tableList}
                                                    columns={this.state.tableColumns}
                                                    totalSize={this.state.tableTotalSize}
                                                    onPageChange={() => { }}
                                                    onRowDoubleClick={() => { }}
                                                    onEditRow={this.handleEditRow}
                                                    isLoading={this.state.isLoadingTable}
                                                    editableFields={['qt_apontada']}
                                                    extraColumnsConfig={
                                                        {
                                                            'qt_baixa_estoque': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_apontada': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_baixa_teorica': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_custo_medio': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_custo_total': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            }
                                                        }
                                                    }
                                                    customRowSize={
                                                        {
                                                            'cd_item': 80,
                                                            'ds_item': 210,
                                                            'sg_unidademedida': 60,
                                                            'ds_itemtipo': 210,
                                                            'qt_baixa_estoque': 130,
                                                            'qt_baixa_teorica': 130,
                                                        }
                                                    }
                                                />
                                            </>
                                        }
                                    />

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '35% 15% 25% 25%' }, margin: { md: '10px 0 0 0', lg: '10px 60px 0 0' } }}>
                                        <MainSelectField
                                            {...this.props}
                                            id='company'
                                            value={this.state.company}
                                            label={t('Empresa')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeText}
                                            optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                            disabled
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            id='releaseDate'
                                            value={this.state.releaseDate}
                                            label={t('D. Lançamento')}
                                            placeholder={t('[00/00/0000]')}
                                            handleChange={this.handleChangeText}
                                            type='date'
                                            disabled
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='orderNumber'
                                            value={this.state.orderNumber}
                                            label={t('Nr. Ordem')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeText}
                                            disabled
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='status'
                                            value={this.state.status}
                                            label={t('Situação')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeText}
                                            optionsList={this.state.statusList}
                                            disabled
                                        />
                                    </Box>

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '35% 17% 17% 31%' }, margin: { md: '10px 0 0 0', lg: '10px 60px 0 0' } }}>
                                        <MainSelectField
                                            {...this.props}
                                            id='cd_serie'
                                            value={this.state.companyConfig.cd_serie}
                                            label={t('Série')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeTextObject}
                                            optionsList={this.state.seriesList.map(item => ({ label: item.ds_serie, value: item.cd_serie }))}
                                            required
                                            ref={this.firstFieldRef}
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            id='startDate'
                                            value={this.state.startDate}
                                            label={t('D. Inicial')}
                                            placeholder={t('[00/00/0000]')}
                                            handleChange={this.handleChangeText}
                                            type='date'
                                            formatInput='DD/MM/YYYY HH:mm'
                                            required
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            id='endDate'
                                            value={this.state.endDate}
                                            label={t('D. Final')}
                                            placeholder={t('[00/00/0000]')}
                                            handleChange={this.handleChangeText}
                                            type='date'
                                            formatInput='DD/MM/YYYY HH:mm'
                                            required
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='responsible'
                                            value={this.state.responsible}
                                            label={t('Responsável')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeText}
                                            optionsList={this.state.userList.map(item => ({ label: item.nm_usuario, value: item.cd_usuario }))}
                                            required
                                        />
                                    </Box>

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '35% 35% 30%' }, margin: { md: '10px 0 0 0', lg: '10px 40px 0 0' } }}>
                                        <MainSelectField
                                            {...this.props}
                                            id='cd_deposito_insumo'
                                            value={this.state.companyConfig.cd_deposito_insumo}
                                            label={t('Depósito do Consumo de Insumos')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeTextObject}
                                            optionsList={this.props.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                            required
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='cd_deposito_producao'
                                            value={this.state.companyConfig.cd_deposito_producao}
                                            label={t('Depósito de Destino da Produção')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeTextObject}
                                            optionsList={this.props.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                            required
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='observation'
                                            value={this.state.observation}
                                            label={t('Observação')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeText}
                                        />
                                    </Box>
                                </Box>

                                {/* Campos do Item */}
                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <Accordion
                                        title={t('Itens a Produzir') + ':'}
                                        content={
                                            <>
                                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '35% 15% 10% 20% 20%' }, marginRight: { md: '0', lg: '100px' } }}>
                                                    <MainTextField
                                                        {...this.props}
                                                        id='ds_item'
                                                        value={this.state.itemObject.ds_item}
                                                        label={t('Item')}
                                                        placeholder={t('[digite...]')}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='ds_embalagem'
                                                        value={this.state.itemObject.ds_embalagem}
                                                        label={t('Embalagem')}
                                                        placeholder={t('[digite...]')}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='um'
                                                        value={this.state.itemObject.um}
                                                        label={t('UM')}
                                                        placeholder={t('[digite...]')}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='vl_custo_medio'
                                                        value={this.state.itemObject.vl_custo_medio}
                                                        label={t('V. Custo Médio')}
                                                        placeholder={t('[digite...]')}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='vl_custo_prod'
                                                        value={this.state.itemObject.vl_custo_prod}
                                                        label={t('V. Custo Produção')}
                                                        placeholder={t('[digite...]')}
                                                        disabled
                                                    />
                                                </Box>

                                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '7% 15% 11% 12% 15% 10% 10% 10% 10%' }, marginTop: '10px' }}>
                                                    <MainTextField
                                                        {...this.props}
                                                        id='tempo'
                                                        value={this.state.itemObject.tempo}
                                                        label={t('Tempo')}
                                                        placeholder={t('[digite...]')}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='lote'
                                                        value={this.state.itemObject.lote}
                                                        label={t('Lote')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeItemText}
                                                        required={this.state.technicalSheet?.fl_controlar_lote === 'S' ? true : false}
                                                        disabled={this.state.technicalSheet?.fl_controlar_lote === 'S' ? false : true}
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='dd_validade'
                                                        value={this.state.itemObject.dd_validade}
                                                        label={t('Dias de Validade')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeItemText}
                                                        type='number'
                                                        required={this.state.technicalSheet?.fl_controlar_lote === 'S' ? true : false}
                                                        disabled={this.state.technicalSheet?.fl_controlar_lote === 'S' ? false : true}
                                                    />
                                                    <MainDateTimeInput
                                                        {...this.props}
                                                        id='dt_validade'
                                                        value={this.state.itemObject.dt_validade}
                                                        label={t('D. Validade')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeItemText}
                                                        type='date'
                                                        required={this.state.technicalSheet?.fl_controlar_lote === 'S' ? true : false}
                                                        disabled={this.state.technicalSheet?.fl_controlar_lote === 'S' ? false : true}
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='qt_receita'
                                                        value={this.state.itemObject.qt_receita}
                                                        label={t('Q. Receita')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeItemText}
                                                        type='number'
                                                        maxDecimals={6}
                                                        required
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='qt_producao'
                                                        value={this.state.itemObject.qt_producao}
                                                        label={t('Q. Produção')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeItemText}
                                                        type='number'
                                                        maxDecimals={6}
                                                        required
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='total_producao'
                                                        value={this.state.itemObject.total_producao?.toFixed(6) ?? 0}
                                                        label={t('Total Produção')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeItemText}
                                                        type='number'
                                                        maxDecimals={6}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='producao_teorica'
                                                        value={this.state.itemObject.producao_teorica?.toFixed(6) ?? 0}
                                                        label={t('Produção Teórica')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeItemText}
                                                        type='number'
                                                        maxDecimals={6}
                                                        disabled
                                                    />
                                                </Box>
                                            </>
                                        }
                                    />
                                </Box>

                                <Box sx={{ display: 'grid', gap: '20px', justifyContent: 'end', gridTemplateColumns: { md: '100%', lg: '12%' }, margin: { md: '10px 0 20px 0', lg: '10px 40px 20px 0' } }}>
                                    {this.state.isDisabled ?
                                        <Button className='main-icon-button' sx={{ marginTop: '20px !important', marginLeft: 'auto', height: '40px' }} onClick={() => this.setState({ isReportOpen: true })}>
                                            <i style={{ margin: '5px', fontSize: '20px' }} className="fa-solid fa-print"></i>
                                        </Button>
                                        :
                                        <MainButton
                                            {...this.props}
                                            sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                            title={t('Gerar Ordem')}
                                            onButtonClick={() => this.setState({ isDialogOpen: true })}
                                            disabled={this.state.isDisabled || this.props.user.configs[0].fl_utiliza_gestao_eventos === "N" ? true : false}
                                        />
                                    }
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(ProductionOrder);

const right = keyframes` from {right: -100%; } to {right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    })
}