export function getNestedProperty(obj, path) {
    return path.split('.').reduce((currentObject, key) => {
        return currentObject ? currentObject[key] : undefined
    }, obj)
}

// retorna true se o cpf for inválido
export function isValidCPF(cpf) {
    if (cpf.length !== 11) {
        return false
    }

    const isAllEqual = cpf.split('').every(c => c === cpf[0])
    if (isAllEqual) return false

    const calculateCheckDigit = (base, factor) => {
        const sum = base.split('').reduce((acc, current, index) => {
            return acc + parseInt(current) * (factor - index)
        }, 0)
        const remainder = sum % 11
        return (remainder < 2) ? 0 : 11 - remainder
    }

    const digit1 = calculateCheckDigit(cpf.substring(0, 9), 10)
    const digit2 = calculateCheckDigit(cpf.substring(0, 10), 11)

    return digit1 === parseInt(cpf[9]) && digit2 === parseInt(cpf[10])
}

// retorna o cpf formatado
export function formatCPF(cpf) {
    const cleanedCpf = cpf.replace(/\D/g, '')

    const finalCpf = cleanedCpf.slice(0, 11)

    return finalCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

// formata imagem base64 para o tamanho desejado
export function resizeImage(imageHex, size) {         // passar o base 64 e tamanho, se passar 20, será 20px por 20px
    return new Promise((resolve, reject) => {
        if (!imageHex) {
            resolve('')
            return
        }
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = size
        canvas.height = size

        const image = new Image()
        // Detecção melhorada do formato da imagem a partir do prefixo base64
        const format = imageHex.startsWith('iVBOR') ? 'image/png' : 'image/jpg'

        image.onload = () => {
            ctx.drawImage(image, 0, 0, size, size)

            const resizedImageHex = canvas.toDataURL(format).replace(/^data:image\/(png|jpeg);base64,/, '')
            resolve(resizedImageHex)
        }
        image.onerror = () => reject(new Error('Erro ao carregar a imagem'))

        image.src = `data:${format};base64,${imageHex}`
    })
}