import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainCheckBoxInput from "../../../components/inputs/MainCheckBoxInput";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import OnScreenVisible from "../../../components/visual/OnScreenVisible";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import { Box, Button, Divider, Grid, keyframes, Typography } from "@mui/material";
import { checkSendDate, defaultRequest } from "../../../utils/request/request";
import { getDeposit } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class RMI extends React.Component {
    constructor(props) {
        super(props)

        const { t } = this.props

        this.initialRef = React.createRef()
        this.finalRef = React.createRef()
        this.firstFieldRef = React.createRef()

        this.state = {
            arrowButton: 'down',
            refToSet: '',

            screenId: 'Suprimentos_Rmi',
            isLoading: true,
            isLoadingTable: false,

            isIncludeItemTableVisible: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['sg_unidademedida', t('UM')],
                ['ds_embalagem', t('Embalagem')],
                ['qt_item', t('Q. na Embalagem')],
                ['qt_aplicada', t('Q. Requisitada')],
                ['qt_estoque', t('Q. Estoque')]
            ],
            tableTotalSize: 0,

            // campos
            company: this.props.company,
            requestDate: dayjs(),
            serviceDate: dayjs(),
            lossMovement: false,
            requester: this.props.user.data.nm_usuario,
            seriesList: [],
            eventList: [],
            pointOfSaleList: [],
            depositList: [],

            item: '',
            itemsList: [],

            itemPacking: '',
            itemPackagingList: [],

            qtdRequested: '',

            status: 11,
            statusOptions: [
                { 'label': t('Pendente'), 'value': 11 },
            ],

            isDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchData()
        this.setState({
            tableList: this.props.newTableList.map((item, index) => ({
                ...item,
                id_linha: index + 1 // Adiciona um id único para cada objeto
            }))
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // se o item mudar, faz request das embalagens novamente
        if (this.state.item !== prevState.item) {
            this.state.item
                ? this.getItemsPackaging()
                : this.setState({ itemPackagingList: [], itemPacking: '', um: '', qtdPacking: '', qtdRequested: '' })
        }
        // fazer cálculo no "Adicionar Item"
        if (prevState.qtdRequested !== this.state.qtdRequested || prevState.qtdPacking !== this.state.qtdPacking) {
            const operationType = this.state?.itemPackagingList.find(item => item.id_embalagem === this.state.itemPacking)?.at_operacao

            const qtdRequested = this.state.qtdRequested
            const qtdPacking = this.state.qtdPacking

            if (!isNaN(qtdRequested) && !isNaN(qtdPacking)) {
                const result = operationType === 228 ? (qtdRequested * qtdPacking) : (qtdRequested / qtdPacking)
                const newResult = result.toFixed(4)

                this.setState({ qtdStock: newResult })
            } else {
                this.setState({ qtdStock: '' })
            }
        }

        //fazer cálculo na tabela
        if (prevState.tableList !== this.state.tableList) {
            this.calculateTable()
        }

        // se a empresa mudar, busca o depósito novamenente
        if (prevState.company !== this.state.company && this.state.company) {
            this.fetchData()
        }

        // se o evento mudar, faz o request do PDV
        if (this.state.event && prevState.event !== this.state.event) {
            this.fetchPointOfSale()
        }

        // se apagar o evento, apaga o PDV
        if (prevState.event !== this.state.event && !this.state.event) {
            this.setState({ pointOfSaleList: [] })
        }
    }

    calculateTable = () => {
        const updateTable = this.state.tableList.map(item => {
            const operation = item.at_operacao

            const qtdEmb = item.qt_item || 0
            const requisitada = item.qt_aplicada || 0

            const result = operation === 228 ? requisitada * qtdEmb : requisitada / qtdEmb || 0

            return { ...item, qt_estoque: result }

        })
        if (JSON.stringify(this.state.tableList) !== JSON.stringify(updateTable)) {
            setTimeout(() => {
                this.setState({ tableList: updateTable, tableTotalSize: updateTable.length })
            }, 100)
        }
    }

    checkButtonIsDisabled = () => {
        if (!this.state.item || !this.state.um || !this.state.itemPacking || !this.state.qtdRequested) {
            return true
        }
        return false
    }

    fetchData = async () => {
        try {
            const depositData = getDeposit(this.state.company, this.props.user.entidade)
            const eventData = this.fetchEvent()
            const seriesData = this.fetchSeries()

            const [depositList, eventList, seriesList] = await Promise.all([depositData, eventData, seriesData])

            this.setState({
                depositList: depositList,
                eventList: Array.isArray(eventList) ? eventList : [],
                seriesList: seriesList,

                isLoading: false
            }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                depositList: [],
                eventList: [],
                seriesList: [],

                isLoading: false
            })
        }
    }

    fetchEvent = async () => {
        let config = {
            endpoint: `componente/proposta`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'cd_empresa': this.state.company,
            'at_situacao_lista': 891,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                return r.data
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar o evento:', error)
            return []
        }
    }

    fetchPointOfSale = () => {
        let config = {
            endpoint: `componente/propostapdv`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'cd_propostaexecucao': this.state.eventList.find(item => item.id_proposta === this.state.event).cd_propostalocalexecucao,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    pointOfSaleList: r.data
                })
            } else {
                this.setState({
                    pointOfSaleList: [],
                })
            }
        })
    }

    fetchSeries = async () => {
        let config = {
            endpoint: `sis/usuariormi/usuario/${this.props.user.data.cd_usuario}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data) {
                if (Object.keys(r.data).length === 0 && r.data.constructor === Object) {
                    return []
                } else {
                    return r.data
                }
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar a série:', error)
            return []
        }
    }

    getItems = (item) => {
        const { t } = this.props
        let config = {
            endpoint: `componente/item/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'tx_busca': item,
            'x-Pagina': 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemsList: r.data
                })
            } else {
                this.setState({
                    itemsList: [],

                    alertMessage: t('Nenhum dado retornado'),
                    alertType: 'warning',
                    showAlert: true,
                })
            }
        })
    }

    getItemsPackaging = (page = 0) => {
        let config = {
            endpoint: `componente/itemembalagem/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'id_item': this.state.item,
            'x-Pagina': page + 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemPacking: '',
                    itemPackagingList: r.data,
                })
            } else {
                this.setState({
                    itemPackagingList: []
                })
            }
        })
    }

    handleChangePacking = (event) => {
        const itemPackingData = this.state.itemPackagingList.find(item => item.id_embalagem === event.target.value) || []

        const um = itemPackingData.sg_unidademedida || ''
        const qtdPacking = itemPackingData.qt_item || 0

        if (event.target.value) {
            this.setState({ [event.target.id]: event.target.value, um: um, qtdPacking: qtdPacking.toFixed(4) })
        } else {
            this.setState({ [event.target.id]: event.target.value, um: '', qtdPacking: '' })
        }
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleEditRow = (updatedList, method, id) => {
        if (method === 'delete') {
            this.setState({
                tableList: updatedList,
                tableTotalSize: updatedList.length
            })
        } else if (method === 'edit') {
            this.setState({
                tableList: updatedList
            })
        }
    }

    includeItem = () => {
        if (!this.state.item || this.state.item.length === 0 || !this.state.itemPacking || this.state.itemPacking.length === 0) {
            this.showAlert('Necessário informar o item e embalagem.', 'error')
            return
        }

        const item = this.state.itemsList.find(item => item.id_item === this.state.item)
        const packing = this.state.itemPackagingList.find(itemPacking => itemPacking.id_embalagem === this.state.itemPacking)

        if (item && packing) {

            const newItem = {
                id_linha: this.state.tableList.length + 1,  // cria um id único para poder adicionar duas ou + vezes o mesmo item
                id_item: item.id_item,
                id_embalagem: packing.id_embalagem,

                cd_item: item.cd_item,
                ds_item: item.ds_item,
                sg_unidademedida: packing.sg_unidademedida,
                ds_embalagem: packing.ds_embalagem,
                qt_item: packing.qt_item,
                qt_aplicada: Number(parseFloat(this.state.qtdRequested).toFixed(4)),
                qt_estoque: this.state.qtdStock,
                at_operacao: packing.at_operacao
            }

            const itemExists = this.state.tableList.some(tableItem =>   // se já existir o mesmo item com a mesma embalagem na tabela
                tableItem.id_item === newItem.id_item && tableItem.id_embalagem === newItem.id_embalagem
            )

            if (itemExists) {
                this.showAlert('Já existe um registro para este item e embalagem na tabela.', 'error')
            } else {
                this.setState(prevState => ({
                    tableList: [...prevState.tableList, newItem],
                    item: '',
                    itemPacking: '',
                    qtdRequested: '',

                    itemsList: [],
                    itemPackagingList: [],
                }))
                this.showAlert('Item adicionado com sucesso.', 'success')
            }
        }
    }

    sendRMI = async () => {
        this.setState({ isDialogOpen: false, isLoading: true })

        if (!this.state.company || !this.state.requester || !this.state.series || !this.state.serviceDate) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        if (this.state.tableList.length <= 0) {
            this.showAlert('Necessário adicionar pelo menos um item.', 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasInvalidItem = this.state.tableList.some(item => !('qt_aplicada' in item) || item.qt_aplicada === 0)

        if (hasInvalidItem) {
            this.showAlert('Há items não preenchidos na tabela.', 'error')
            this.setState({ isLoading: false })
            return
        }

        let response = await checkSendDate(this.state.requestDate, this.state.company, this.props.user.entidade)

        if (!response || !response.status) {
            this.setState({
                alertMessage: response.message ?? '',
                alertType: response.msg_type === 1 ? 'error' : 'warning',
                showAlert: true,

                isLoading: false,
            })
            if (response.msg_type === 1) {
                return
            }
        }

        let config = {
            endpoint: `adm/rmi?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'cd_usuario': this.props.user.data.cd_usuario,
            'cd_usuario_requisitante': this.props.user.data.cd_usuario,
            'cd_empresa': this.state.company,
            'cd_serie': this.state.series,
            'ds_requisitante': this.state.requester,
            'cd_deposito_perda': this.state.deposit || null,
            'dt_requisicao': this.state.requestDate,
            'dt_atendimento': this.state.serviceDate,
            'nr_RMI': this.state.numberRMI,
            'at_situacao': this.state.status,
            'id_proposta': this.state.event,
            'cd_propostaPDV': this.state.pointOfSale,
            'ds_motivo': this.state.wasteMotive,
            'observacao': this.state.observation,
            'itens': (this.state.tableList && this.state.tableList.length > 0) ?
                this.state.tableList.map(item => {
                    return {
                        'id_item': item.id_item,
                        'id_embalagem': item.id_embalagem,
                        'qt_requisitada': item.qt_aplicada,
                        'qt_convertido': item.qt_estoque,
                        'at_situacao': 1,
                    }
                }) : []
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    isLoading: false,
                    isDisabled: true,
                    isConfirmationOpen: true,
                    numberRMI: r.data.nr_RMI,
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    setArrowButton = (arrow) => {
        if (arrow === 'up') {
            this.setState({ arrowButton: arrow, refToSet: 'initialRef' })
        } else {
            this.setState({ arrowButton: arrow, refToSet: 'finalRef' })
        }
    }


    render() {
        const { t } = this.props
        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendRMI}                                // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Número RMI')}: ${this.state.numberRMI}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => this.props.toggleVisiblePage('isRMIVisible')}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isRMIVisible')}
                        />

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box ref={this.initialRef} sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                        {t('RMI - Requisição de Materiais e Insumos')}:
                                    </Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <OnScreenVisible onVisible={() => this.setArrowButton('down')} />

                                <Box sx={{ marginRight: { md: '0', lg: '40px' } }}>
                                    <Accordion
                                        {...this.props}
                                        startOpen
                                        title={t('Itens da RMI:')}
                                        content={
                                            <>

                                                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '100%', lg: '15%' } }}>
                                                    <MainButton
                                                        {...this.props}
                                                        sx={{ background: this.state.isIncludeItemTableVisible ? this.props.colors.grey[900] : this.props.colors.blueAccent[100] }}
                                                        title={t('Adicionar Item')}
                                                        onButtonClick={() => this.setState({ isIncludeItemTableVisible: true })}
                                                    />
                                                </Box>

                                                {this.state.isIncludeItemTableVisible &&
                                                    (
                                                        <Box sx={{ padding: '10px 30px', border: `1px solid ${this.props.colors.orangeAccent[800]}`, margin: '20px 0', borderRadius: '10px' }}>

                                                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>{t('Adicionar Item')}:</Typography>

                                                                <Divider sx={{ flexGrow: 1, margin: '0 0 0 20px' }} />

                                                                <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isIncludeItemTableVisible: false })}>
                                                                    <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                                                </Button>
                                                            </Box>

                                                            {/* Campos de adição */}
                                                            <Box sx={{ display: 'grid', gap: '0', gridTemplateColumns: { xs: '100%', lg: '30% 10% 20% 10% 10% 10% 10%' }, marginTop: '10px' }}>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '20px 0 0 20px' }}>{t('Descrição do Item')}</Typography>
                                                                    <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='item' value={this.state.item} placeholder={t('[digite para buscar as opções...]')} optionsList={this.state.itemsList.map(item => ({ label: `${item.cd_item} - ${item.ds_item}`, value: item.id_item }))} handleChange={this.handleChangeText} delayFunction={this.getItems} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('UM')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='um' value={this.state.um} placeholder={t('[unidade]')} handleChange={this.handleChangeText} disabled={true} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('Embalagem')}</Typography>
                                                                    <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='itemPacking' value={this.state.itemPacking} placeholder={t('[embalagem...]')} optionsList={this.state.itemPackagingList.map(item => ({ label: item.ds_embalagem, value: item.id_embalagem }))} handleChange={this.handleChangePacking} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('Q. na Embalagem')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='qtdPacking' value={this.state.qtdPacking} placeholder={t('[0,00]')} handleChange={this.handleChangeText} disabled={true} type='number' maxDecimals={4} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('Q. Requisitada')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='qtdRequested' value={this.state.qtdRequested} placeholder={t('[0,00]')} handleChange={this.handleChangeText} type='number' maxDecimals={4} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '0 20px 20px 0' }}>{t('Q. Estoque')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='qtdStock' value={this.state.qtdStock} placeholder={t('[0,00]')} handleChange={this.handleChangeText} disabled={true} type='number' maxDecimals={4} />
                                                                </Box>
                                                                <MainButton sx={styles.AddItemMainButton(this.props)} {...this.props} title={t('Inserir')} onButtonClick={this.includeItem} disabled={this.checkButtonIsDisabled() || this.state.isDisabled} />
                                                            </Box>

                                                            <Divider sx={{ flexGrow: 1, marginTop: '5px' }} />

                                                        </Box>
                                                    )
                                                }

                                                <EditableTable
                                                    {...this.props}
                                                    allowEdit
                                                    allowEditOnRow
                                                    id='table_rmi'
                                                    data={this.state.tableList}
                                                    columns={this.state.tableColumns}
                                                    rowId='id_linha'
                                                    totalSize={this.state.tableTotalSize}
                                                    onPageChange={() => { }}
                                                    onRowDoubleClick={() => { }}
                                                    isLoading={this.state.isLoadingTable}
                                                    onEditRow={this.handleEditRow}
                                                    editableFields={['qt_aplicada']}
                                                    extraColumnsConfig={
                                                        {
                                                            'qt_item': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_aplicada': {
                                                                'type': 'number',
                                                                'maxDigits': '5',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_estoque': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                        }
                                                    }
                                                    customRowSize={
                                                        {
                                                            'cd_item': 80,
                                                            'ds_item': 250,
                                                            'qt_item': 130,
                                                        }
                                                    }
                                                />
                                            </>
                                        }
                                    />
                                </Box>

                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 60px 20px 0' } }}>

                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' }, marginBottom: '10px' }}>
                                        <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                            {t('Dados da RMI')}:
                                        </Typography>

                                        <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />
                                    </Box>

                                    <Box sx={{ display: 'grid', gap: '30px', gridTemplateColumns: { xs: '100%', lg: '40% 60%' }, margin: { xs: '20px 0', lg: '0 30px 0 0' } }}>

                                        <Grid container columnSpacing={3} rowSpacing={2} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                                            <Grid item md={12}>
                                                <MainSelectField
                                                    required
                                                    {...this.props}
                                                    id='company'
                                                    value={this.state.company}
                                                    label={t('Empresa')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                                    handleChange={this.handleChangeText}
                                                    ref={this.firstFieldRef}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <MainSelectField
                                                    required
                                                    {...this.props}
                                                    id='series'
                                                    value={this.state.series}
                                                    label={t('Série da RMI')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.state.seriesList.map(item => ({ label: item.ds_serie, value: item.cd_serie }))}
                                                    handleChange={this.handleChangeText}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <MainTextField
                                                    required
                                                    {...this.props}
                                                    id='requester'
                                                    value={this.state.requester}
                                                    label={t('Requisitante')}
                                                    placeholder={t('[digite...]')}
                                                    handleChange={this.handleChangeText}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <MainSelectField
                                                    {...this.props}
                                                    id='deposit'
                                                    value={this.state.deposit}
                                                    label={t('Depósito Origem')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.state.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                                    handleChange={this.handleChangeText}
                                                    disabled={this.state.lossMovement ? false : true}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container columnSpacing={3} rowSpacing={2} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                                            <Grid item md={3}>
                                                <MainDateTimeInput
                                                    {...this.props}
                                                    id='requestDate'
                                                    value={this.state.requestDate}
                                                    label={t('D. Requisição')}
                                                    handleChange={this.handleChangeText}
                                                    type='date'
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item md={3}>
                                                <MainDateTimeInput
                                                    required
                                                    {...this.props}
                                                    id='serviceDate'
                                                    value={this.state.serviceDate}
                                                    label={t('D. Atendimento')}
                                                    handleChange={this.handleChangeText}
                                                    type='date'
                                                />
                                            </Grid>
                                            <Grid item md={3}>
                                                <MainTextField
                                                    {...this.props}
                                                    id='numberRMI'
                                                    value={this.state.numberRMI}
                                                    label={t('Número RMI')}
                                                    placeholder={t('[000000000]')}
                                                    handleChange={this.handleChangeText}
                                                    type='number'
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item md={3}>
                                                <MainSelectField
                                                    {...this.props}
                                                    id='status'
                                                    value={this.state.status}
                                                    label={t('Situação')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.state.statusOptions}
                                                    handleChange={this.handleChangeText}
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <MainSelectField
                                                    {...this.props}
                                                    id='event'
                                                    value={this.state.event}
                                                    label={t('Evento')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.state.eventList.map(item => ({ label: item.ds_proposta, value: item.id_proposta }))}
                                                    handleChange={this.handleChangeText}
                                                    disabled={this.props.user.configs[0].fl_utiliza_gestao_eventos === "N" ? true : false}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <MainSelectField
                                                    {...this.props}
                                                    id='pointOfSale'
                                                    value={this.state.pointOfSale}
                                                    label={t('Ponto de Venda (PDV)')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.state?.pointOfSaleList.map(item => ({ label: item.ds_propostaPDV, value: item.cd_propostaPDV }))}
                                                    handleChange={this.handleChangeText}
                                                />
                                            </Grid>
                                            <Grid item md={4}>
                                                <MainCheckBoxInput
                                                    {...this.props}
                                                    id='lossMovement'
                                                    value={this.state.lossMovement}
                                                    label={t('Movimento de Perda')}
                                                    handleChange={this.handleChangeText}
                                                    sx={{ marginTop: '15px' }}
                                                />
                                            </Grid>
                                            <Grid item md={8}>
                                                <MainTextField
                                                    {...this.props}
                                                    id='wasteMotive'
                                                    value={this.state.wasteMotive}
                                                    label={t('Motivo da Perda')}
                                                    placeholder={t('[digite...]')}
                                                    handleChange={this.handleChangeText}
                                                    disabled={this.state.lossMovement ? false : true}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <MainTextField
                                                    {...this.props}
                                                    id='observation'
                                                    value={this.state.observation}
                                                    label={t('Observação')}
                                                    placeholder={t('[digite...]')}
                                                    handleChange={this.handleChangeText}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box ref={this.finalRef} sx={{ display: 'grid', justifyContent: 'end', gridTemplateColumns: { xs: '100%', lg: '15%' }, marginTop: '20px' }}>
                                        <MainButton
                                            {...this.props}
                                            sx={{ background: this.props.colors.orangeAccent[400] }}
                                            title={t('Gravar RMI')}
                                            onButtonClick={() => this.setState({ isDialogOpen: true })}
                                            disabled={this.state.isDisabled}
                                        />
                                    </Box>

                                    <OnScreenVisible onVisible={() => this.setArrowButton('up')} />
                                </Box>
                            </>
                        }
                    </Box>

                    <Button
                        sx={{
                            position: 'absolute',
                            right: '30px',
                            bottom: '25px',

                            borderRadius: '50%',
                            minWidth: '40px',
                            padding: '10px',
                            background: this.props.colors.orangeAccent[400],
                            ':hover': { background: this.props.colors.orangeAccent[300] }
                        }}
                        onClick={() => this[this.state.refToSet].current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >
                        <i style={{ fontSize: '22px', color: 'white' }} class={`fa-solid fa-arrow-${this.state.arrowButton}`} />
                    </Button>

                </Box>
            </Box>
        )
    }
}

export default withTranslation()(RMI);

const right = keyframes` from {right: -100%; } to { right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',  // adicionar padding na direita para espaçar
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    }),
    AddItemBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    addItemHeader: (props) => ({
        marginTop: '10px',
        color: props.colors.blueAccent[100],
        backgroundColor: props.colors.grey[900],
        padding: '5px 0 5px 10px',
        fontWeight: '600',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    }),
    AddItemMainTextField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiInputBase-input': { boxShadow: 'none !important' },
        '& .MuiOutlinedInput-root': {
            'fieldset': { borderColor: props.colors.orangeAccent[800] },
            '&.Mui-focused fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800] }
        },
        '& .MuiInputBase-input.Mui-disabled': { backgroundColor: 'transparent' }
    }),
    AddItemMainSelectField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800], boxShadow: 'none !important' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { border: 'none' } }
    }),
    AddItemMainButton: (props) => ({
        width: { xs: '100%', lg: '70%' },
        height: '30px',
        border: `1px solid ${props.colors.blueAccent[100]}`,
        textColor: props.colors.blueAccent[100],
        margin: { xs: '30px 0 10px 0', lg: '50px 0 0 15px' }
    })
}
