import React from "react";
import dayjs from "dayjs";

import Accordion from "../../../components/visual/Accordion";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import loading1 from "../../../data/loading1.gif";
import MainButton from "../../../components/inputs/MainButton";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import LossesReport from "../../../components/reports/LossesReport";

import {
    Box, Button, Divider, keyframes, Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Collapse,
} from "@mui/material";
import { defaultRequest } from "../../../utils/request/request";
import { getDeposit, getMovementType } from "../../../utils/request/requestSelects";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { withTranslation } from "react-i18next";


class Losses extends React.Component {
    constructor(props) {
        super(props)

        const { t } = this.props

        const hasNewTableList = Array.isArray(this.props.newTableList) && this.props.newTableList.length > 0

        this.tableRef = React.createRef()
        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'Suprimentos_Perdas',
            isLoading: true,
            isLoadingTable: false,
            isLoadingInputsTable: true,
            isReportOpen: false,
            isCloseOpen: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['sg_unidademedida', t('UM')],
                ['qt_perdida', t('Q. Perdida')]
            ],
            tableTotalSize: 0,

            inputsList: [],
            inputsColumns: [
                { label: t("Descrição") },
                { label: t("Q. Aplicada") },
                { label: t("Q. Total") },
            ],
            inputsColumnsPdf: [
                ['id_composto', t('Item')],
                ['desc_item', t('Descrição do Item')],
                ['unidade', t('UM')],
                ['qt_aplicada', t('Q. Aplicada')]
            ],
            inputsTotalSize: 0,

            company: hasNewTableList ? this.props.newTableList[0].cd_empresa : null,
            deposit: hasNewTableList ? this.props.newTableList[0].cd_deposito : null,
            releaseDate: dayjs(),
            selectAll: false,
            documentNumber: '',

            depositList: [],
            lossTypeList: [],
            seriesList: [],

            openRows: {},
            rowSelected: true,
            allInputs: {},
            refreshInputs: false,

            isDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchData()
        this.setState({
            tableList: this.props.newTableList.map((item, index) => ({
                ...item,
                id_linha: index + 1,
            })),
            tableTotalSize: this.props.newTableList.length,
        })
    }

    addInputsResult = (row) => {
        this.setState({
            isLoadingInputsTable: true,
            rowSelected: row
        }, () => this.fetchInputs())
    }

    changeAllSelected = () => {
        this.setState({ isLoadingInputsTable: true })

        var allInputs = this.state.allInputs
        if (this.state.rowSelected) {
            allInputs[this.state.rowSelected.id_item].forEach((value, index) => {
                value.at_baixa_estoque = !this.state.selectAll
            })

            this.setState({
                allInputs: { ...allInputs },
                inputsList: allInputs[this.state.rowSelected.id_item],
                selectAll: !this.state.selectAll,
                isLoadingInputsTable: false
            })
        } else {
            console.log('Não há nenhuma linha para ser selecionada')
        }
    }

    deleteInputs = () => {
        const { allInputs, rowSelected } = this.state

        if (!rowSelected || !allInputs[rowSelected.id_item]) {
            console.log('Nenhuma linha ou inputs selecionados')
            return
        }

        // Mapeia os inputs da linha selecionada
        const updatedInputs = allInputs[rowSelected.id_item].map(item => {
            if (item.subItems && item.subItems.length > 0) {
                item.subItems = item.subItems.filter(subItem => !subItem.at_baixa_estoque)  // Filtra os subitens que não estão marcados
            }
            return item
        }).filter(item => !item.at_baixa_estoque || item.subItems.length > 0) // Filtra o item principal se ele ou seus subitens estiverem marcados

        this.setState({
            allInputs: {
                ...allInputs,
                [rowSelected.id_item]: updatedInputs,
            },
            inputsList: updatedInputs,
        })
    }

    deleteSingleInput = (parentIndex = null) => {
        const { allInputs, rowSelected } = this.state

        const indices = parentIndex.split('-').map(Number)
        if (indices.length > 2) {
            indices.splice(1, 1)
        } else {
            indices.pop()
        }

        const toggleItem = (items, indices) => {
            const currentIdx = indices.shift()
            if (indices.length > 0) {
                toggleItem(items[currentIdx].subItems, indices)
            } else {
                items.splice(currentIdx, 1)
            }
        }

        toggleItem(allInputs[rowSelected.id_item], indices)

        this.setState({
            allInputs: allInputs,
            inputsList: allInputs[rowSelected.id_item]
        })
    }

    fetchData = async () => {
        try {
            const depositData = getDeposit(this.state.company, this.props.user.entidade)
            const movementTypeData = getMovementType(this.props.user.entidade)
            const seriesData = this.fetchSeries()

            const [depositList, movementTypeList, seriesList] = await Promise.all([depositData, movementTypeData, seriesData])

            const filteredlossTypeList = movementTypeList.filter(item => item.fl_movimento_perda === 'S')

            this.setState({
                depositList: depositList,
                lossTypeList: filteredlossTypeList,
                seriesList: seriesList,

                rowSelected: false,

                isLoading: false,
                isLoadingTable: false,
            }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                depositList: [],
                lossTypeList: [],
                seriesList: [],

                isLoading: false,
                isLoadingTable: false,
            })
        }
    }

    fetchInputs = async () => {
        this.setState({ isAccordionOpen: true }, () =>
            setTimeout(() => {
                this.tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 200)
        )

        const { rowSelected, allInputs, refreshInputs } = this.state

        // Se os inputs já estiverem disponíveis, apenas mostra eles
        if (rowSelected.id_item in allInputs && !refreshInputs) {
            return this.setState({
                inputsList: allInputs[rowSelected.id_item],
                isLoadingInputsTable: false,
                refreshInputs: false,
            })
        }

        let config = {
            endpoint: `adm/fichatecnica/item/${rowSelected.id_item}`,
            method: 'get',
        }

        let form = {
            'x-Entidade': this.props.user.entidade
        }

        try {
            const response = await defaultRequest(config, form)

            if (Object.keys(response.data).length === 0 && response.data.constructor === Object) {  // Se response.data for um objeto vazio, faz apenas o setState
                this.setState({ inputsList: [], isLoadingInputsTable: false, refreshInputs: false })
            } else {
                this.processInputs(response.data)
            }

        } catch (error) {
            console.error('Erro ao buscar inputs:', error)
            this.setState({ inputsList: [], isLoadingInputsTable: false, refreshInputs: false })
        }
    }

    fetchSeries = async () => {
        let config = {
            endpoint: `componente/usuarioserie/classe`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'at_classe': 290,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                return r.data
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar o serie:', error)
            return []
        }
    }

    handleChangeCheckbox = (parentIndex) => {
        var inputsList = this.state.inputsList

        const indices = parentIndex.split('-').map(Number)
        indices.shift()

        const toggleItem = (items, indices) => {
            const currentIdx = indices.shift()
            if (indices.length > 0) {
                toggleItem(items[currentIdx].subItems, indices)
            } else {
                items[currentIdx].at_baixa_estoque = !items[currentIdx].at_baixa_estoque
            }
        }

        toggleItem(inputsList, indices)

        this.setState({ inputsList: inputsList })
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleEditRow = (updatedList, method, id) => {
        if (method === 'edit') {
            this.setState({
                tableList: updatedList,
                // rowSelected: id,
            })
        }
        if (method === 'delete') {
            this.setState({
                tableList: updatedList,
                tableTotalSize: updatedList.length
            })
        }
    }

    processInputs = (inputs) => {
        if (!Array.isArray(inputs)) {
            return console.error('Erro: resposta inesperada da API. Esperado um array, mas recebido:', inputs)
        }

        inputs.shift() // Remove o primeiro item

        inputs = inputs.filter(item => [280, 281, 708].includes(item.at_tipo_componente))

        const itemsMap = {}

        inputs.forEach(item => {
            itemsMap[item.id_ordem] = { ...item, subItems: [], checked: false, at_baixa_estoque: false }
        })

        const finalList = []
        const openRows = {}

        inputs.forEach(item => {
            if (item.id_ordem_pai === 1) {
                finalList.push(itemsMap[item.id_ordem])
            } else {
                const father = itemsMap[item.id_ordem_pai]
                if (father) {
                    father.subItems.push(itemsMap[item.id_ordem])
                }
            }
        })

        finalList.forEach((key, index) => {             // iniciar as linhas como fechadas
            if (finalList[key]?.subItems.length > 0) {
                openRows[index] = false
            }
        })

        this.setState(prevState => ({
            inputsList: finalList,                                              // Lista de ordens e subitens
            allInputs: {                                                        // Armazena todos os inputs
                ...prevState.allInputs,
                [this.state.rowSelected.id_item]: finalList
            },
            openRows: openRows,                                                 // Define quais linhas devem estar abertas (todas fechadas inicialmente)
            refreshInputs: false,                                               // Reseta o sinalizador de atualização

            isLoadingInputsTable: false,
        }))
    }

    sendLosses = () => {
        // Se o campo: at_tipo_baixa === 93 isso quer dizer que ITEM PRINCIPAL BAIXA PRODUCAO. Então mandaremos apenas o item principal para baixar estoque de perdas
        // Se o campo: at_tipo_baixa !== 93. Então mandaremos apenas os insumos que tenham o campo tem_composicao === null para baixar estoque de perdas
        // Se at_tipo_baixa !== 93 e não tem nenhum insumo com tem_composicao === null, manda o item principal mesmo.
        // So mostrar at_tipo_componente IN(280,281,708)

        const { tableList, company, deposit, series, releaseDate, lossType, lossTypeList, allInputs, observation } = this.state
        this.setState({ isDialogOpen: false, isLoading: true })

        if (!series || !releaseDate || !lossType) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasInvalidItem = tableList.some(item => !('qt_perdida' in item) || item.qt_perdida === 0)

        if (hasInvalidItem) {
            this.showAlert('Há items não preenchidos na tabela.', 'error')
            this.setState({ isLoading: false })
            return
        }

        const collectValidInputs = (inputs) => {
            let validInputs = []

            inputs.forEach(input => {
                if (input.tem_composicao === null) {
                    validInputs.push(input)
                }

                // Se houver subItems, chama a função recursivamente
                if (input.subItems && Array.isArray(input.subItems)) {
                    validInputs = validInputs.concat(collectValidInputs(input.subItems))
                }
            })
            return validInputs
        }

        var allItems = []

        var lossTypeMap = {}
        lossTypeList.forEach(item => {
            lossTypeMap[item.id_movimentoestoquetipo] = { ...item }
        })

        tableList.forEach((item) => {
            const itemData = {
                ...item,
                "qt_embalagem": item.qt_perdida,
                "vl_embalagem_unitario": item.vl_unitario,
                "vl_convertido_unitario": item.vl_unitario,
                "at_movimentacao": lossTypeMap[Number(lossType)].at_movimentacao,
                "fl_movimento_custo": lossTypeMap[Number(lossType)].fl_movimento_custo,
                "vl_embalagem_total": item.vl_unitario * item.qt_perdida
            }

            if (item.at_tipo_baixa === 93) {    // Se at_tipo_baixa for 93, adiciona apenas o item principal
                allItems.push(itemData)
            } else {
                // Adiciona insumos com tem_composicao igual a null
                const inputs = allInputs[item.id_item] || []
                const validInputs = collectValidInputs(inputs)  // Coleta insumos válidos, incluindo subinsumos

                if (validInputs.length > 0) {
                    validInputs.forEach((input) => {
                        const inputItem = {
                            ...input,
                            "id_item": input.id_composto,
                            "qt_embalagem": input.qt_aplicada * item.qt_perdida,
                            "vl_embalagem_unitario": input.custo_medio,
                            "vl_convertido_unitario": input.custo_medio,
                            "at_movimentacao": lossTypeMap[Number(lossType)].at_movimentacao,
                            "fl_movimento_custo": lossTypeMap[Number(lossType)].fl_movimento_custo,
                            "vl_embalagem_total": input.custo_medio * input.qt_aplicada,
                        }
                        allItems.push(inputItem)
                    })
                } else {
                    allItems.push(itemData) // Se não houver insumos válidos, adiciona o item principal
                }
            }
        })

        var config = {
            endpoint: 'adm/movimentoestoque?x-Entidade=' + this.props.user.entidade,
            method: 'post'
        }
        var form = {
            "cd_empresa": company,
            "cd_serie": series,
            "dt_movimento": releaseDate,
            "id_movimentoestoquetipo_origem": lossType,
            "observacao": observation,
            "cd_usuario": this.props.user.data.cd_usuario,
            "cd_usuario_responsavel": this.props.user.data.cd_usuario,
            "cd_deposito_origem": deposit,
            "at_situacao": 1,

            "itens": allItems   // Itens e Insumos
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.showAlert('Ação realizada com com sucesso!', 'success')
                this.setState({
                    isLoading: false,
                    isDisabled: true,
                    isConfirmationOpen: true,
                    documentNumber: r.data.nr_documento
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    toggleRow = (index) => {
        this.setState((prevState) => ({
            openRows: {
                ...prevState.openRows,
                [index]: !prevState.openRows[index],
            },
        }))
    }

    renderRows = (row, index, parentIndex = '') => {
        const rowIndex = parentIndex ? `${parentIndex}-${index}` : `${index}`
        const isExpanded = this.state.openRows[rowIndex] || false

        return (
            <React.Fragment key={rowIndex}>
                <TableRow sx={{ height: '20px !important' }}>
                    <TableCell sx={{ width: '60px !important' }}>
                        {row.subItems.length > 0 && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleRow(rowIndex)}
                            >
                                {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell sx={{ width: '700px !important' }}>{row.desc_item}</TableCell>
                    <TableCell sx={{ width: '150px !important' }}>{row.qt_aplicada.toFixed(6)}</TableCell>
                    <TableCell sx={{ width: '150px !important' }}>{(row.qt_aplicada * (this.state.rowSelected.qt_perdida || 0)).toFixed(6)}</TableCell>
                    <TableCell sx={{ width: '60px !important' }}>
                        <IconButton onClick={() => this.deleteSingleInput(rowIndex)} sx={{ margin: 'auto' }}>
                            <i style={{ color: this.props.colors.redAccent[700], fontSize: '18px' }} className="fa-solid fa-trash-can"></i>
                        </IconButton>
                    </TableCell>
                </TableRow>
                {row.subItems.length > 0 && (
                    <TableRow>
                        <TableCell style={{ padding: '0', borderBottom: "none" }} colSpan={6}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Table size="small" aria-label="subitems">
                                    <TableBody>
                                        {row.subItems.map((subItem, subIndex) =>
                                            this.renderRows(subItem, subIndex, rowIndex)
                                        )}
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </React.Fragment>
        )
    }


    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendLosses}                          // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Nr. Documento')}: ${this.state.documentNumber}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => this.setState({ isConfirmationOpen: false })}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isLossesVisible')}
                        />

                        {this.state.isReportOpen ?
                            <LossesReport
                                {...this.state}
                                {...this.props}
                                supplier={this.state.supplier}
                                supplierList={this.state.supplierList}
                                columns={this.state.tableColumns}
                                inputColumns={this.state.inputsColumnsPdf}
                                rows={this.state.tableList}
                                allInputs={this.state.allInputs}
                                onClose={() => this.setState({ isReportOpen: false })}
                            /> : <></>}

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                {/* Header */}
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                        {t('Perdas')}:
                                    </Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <EditableTable
                                        {...this.props}
                                        allowEdit
                                        allowEditOnRow
                                        height='40vh'
                                        id='table_losses'
                                        data={this.state.tableList}
                                        columns={this.state.tableColumns}
                                        rowId='id_linha'
                                        totalSize={this.state.tableTotalSize}
                                        onPageChange={() => { }}
                                        onRowDoubleClick={(params) => this.addInputsResult(params)}
                                        isLoading={this.state.isLoadingTable}
                                        onEditRow={this.handleEditRow}
                                        editableFields={['qt_perdida']}
                                        extraColumnsConfig={
                                            {
                                                'qt_perdida': {
                                                    'type': 'number',
                                                    'maxDigits': '5',
                                                    'maxDecimals': '4',
                                                }
                                            }
                                        }
                                        customRowSize={
                                            {
                                                'cd_item': 80,
                                                'ds_item': 250,
                                                'sg_unidademedida': 80,
                                            }
                                        }
                                    />
                                </Box>

                                <Box ref={this.tableRef} sx={{ margin: { xs: '10px 0', lg: '20px 40px 0 0' } }}>
                                    <Accordion
                                        {...this.props}
                                        title={`${t('Itens')}:`}
                                        isOpen={this.state.isAccordionOpen}
                                        onToggle={(isExpanded) => this.setState({ isAccordionOpen: isExpanded })}
                                        content={
                                            <>
                                                {this.state.rowSelected ?
                                                    <Box sx={{ display: 'flex', gap: '20px', padding: '10px 30px', border: `1px solid ${this.props.colors.grey[800]}`, margin: '10px 0', borderRadius: '10px' }}>
                                                        <Typography sx={{ fontWeight: 'bold' }}>Item:</Typography>
                                                        <Typography sx={{}}>{this.state.rowSelected.cd_item || ''}</Typography>
                                                        <Typography sx={{ textOverflow: 'ellipsis' }}>{this.state.rowSelected.ds_item || ''}</Typography>
                                                    </Box>
                                                    :
                                                    <></>
                                                }

                                                {/* Tabela Insumos */}
                                                <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none', marginTop: '20px', height: '40vh' }}>
                                                    <Table sx={{
                                                        '& .MuiTableCell-root': {
                                                            padding: '5px !important', // Remove espaços que possam aumentar a altura
                                                            lineHeight: '30px', // Centraliza verticalmente o conteúdo
                                                        },
                                                    }}>
                                                        <TableHead sx={{ '& .MuiTableCell-root': { padding: '7px !important' }, '& .MuiTableRow-root': { height: '35px !important' } }}>
                                                            <TableRow style={{ background: this.props.colors.primary[400], }}>
                                                                <TableCell style={{ borderRadius: '15px 0 0 15px' }} />
                                                                {this.state.inputsColumns.map((column, index) => {
                                                                    return (
                                                                        <TableCell
                                                                            key={index}
                                                                            style={{ fontSize: '13px', textTransform: 'uppercase', whiteSpace: 'nowrap', }}
                                                                        >
                                                                            {column.label}
                                                                        </TableCell>
                                                                    )
                                                                })}
                                                                <TableCell style={{ borderRadius: '0 15px 15px 0' }}>
                                                                    AÇÕES
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.isLoadingInputsTable && this.state.rowSelected ? (
                                                                <TableRow>
                                                                    <TableCell colSpan={this.state.inputsColumns.length + 1} style={{ textAlign: "center" }}>
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                                                            <img src={loading1} alt="Loading..." style={{ width: "100px" }} />
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : this.state.isLoadingInputsTable && !this.state.rowSelected ? (
                                                                <TableRow>
                                                                    <TableCell colSpan={this.state.inputsColumns.length + 1} style={{ textAlign: "center" }}>
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                                                            Selecione um item para mostrar os insumos
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : (
                                                                this.state.inputsList.map((row, index) => (
                                                                    this.renderRows(row, 0, index.toString())
                                                                ))
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        }
                                    />
                                </Box>

                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '33% 33% 18% 14%' }, margin: { xs: '10px 0', lg: '10px 70px 0 0' } }}>
                                    <MainSelectField
                                        {...this.props}
                                        id='company'
                                        value={this.state.company}
                                        label={t('Empresa')}
                                        placeholder={t('[selecione...]')}
                                        optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                        handleChange={this.handleChangeText}
                                        disabled
                                    />
                                    <MainSelectField
                                        {...this.props}
                                        id='deposit'
                                        value={this.state.deposit}
                                        label={t('Depósito')}
                                        placeholder={t('[selecione...]')}
                                        optionsList={this.state.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                        handleChange={this.handleChangeText}
                                        disabled
                                    />
                                    <MainDateTimeInput
                                        {...this.props}
                                        required
                                        id='releaseDate'
                                        value={this.state.releaseDate}
                                        label={t('D. Lançamento')}
                                        type='date'
                                        handleChange={this.handleChangeText}
                                        ref={this.firstFieldRef}
                                    />
                                    <MainButton
                                        {...this.props}
                                        sx={{ width: '100%', background: this.props.colors.blueAccent[100], marginTop: '20px' }}
                                        title={t('Recarregar Insumos')}
                                        onButtonClick={() => { this.setState({ refreshInputs: true, isLoadingInputsTable: true }, () => this.fetchInputs()) }}
                                    />
                                </Box>

                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '28% 28% 28% 14%' }, margin: { xs: '10px 0', lg: '10px 70px 0 0' } }}>
                                    <MainSelectField
                                        {...this.props}
                                        required
                                        id='lossType'
                                        value={this.state.lossType}
                                        label={t('Tipo de Movimento de Perda')}
                                        placeholder={t('[selecione...]')}
                                        optionsList={this.state.lossTypeList.map(item => ({ label: item.ds_movimentoestoquetipo, value: item.id_movimentoestoquetipo }))}
                                        handleChange={this.handleChangeText}
                                    />
                                    <MainSelectField
                                        {...this.props}
                                        required
                                        id='series'
                                        value={this.state.series}
                                        label={t('Série')}
                                        placeholder={t('[selecione...]')}
                                        optionsList={this.state.seriesList.map(item => ({ label: item.ds_serie, value: item.cd_serie }))}
                                        handleChange={this.handleChangeText}
                                    />
                                    <MainTextField
                                        {...this.props}
                                        id='observation'
                                        value={this.state.observation}
                                        label={t('Observação')}
                                        placeholder={t('[digite...]')}
                                        handleChange={this.handleChangeText}
                                    />
                                    {this.state.isDisabled ?
                                        (
                                            <Button className='main-icon-button' sx={{ marginTop: '20px !important', marginRight: 'auto', height: '40px' }} onClick={() => this.setState({ isReportOpen: true })}>
                                                <i style={{ margin: '5px', fontSize: '20px' }} className="fa-solid fa-print"></i>
                                            </Button>
                                        )
                                        :
                                        (
                                            <MainButton
                                                {...this.props}
                                                sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                                title={t('Lançar Perda')}
                                                onButtonClick={() => this.setState({ isDialogOpen: true })}
                                            />
                                        )
                                    }
                                    <Box />
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Box >
        )
    }
}

export default withTranslation()(Losses);

const right = keyframes` from {right: -100%; } to { right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',  // adicionar padding na direita para espaçar
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    }),
}