import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainCheckBoxInput from "../../../components/inputs/MainCheckBoxInput";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";
import TablePopup from "./popups/TablePopup";

import { ArrowForward, ArrowBack } from "@mui/icons-material";

import { Box, Button, Divider, Typography, keyframes } from "@mui/material";
import { defaultRequest } from "../../../utils/request/request";
import { formatMultipleSelect } from "../../../utils/format";
import { getDeposit, getPurchaseOrigin, getRequestType } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class PurchaseBySuggestion extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        const hasNewTableList = Array.isArray(this.props.newTableList) && this.props.newTableList.length > 0

        this.inputRef = React.createRef()
        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'Suprimentos_CompraPorSugestao',
            isLoading: true,
            isLoadingTable: false,

            isEditingTableItem: false,
            isTablePopupOpen: false,

            isOnFirstPage: true,
            calculatedOneTime: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['ds_embalagem', t('Embalagem')],
                ['qt_sugerida', t('Q. Sugerida (Emb. Compra)')],
                ['qt_compra', t('Q. Compra (Emb. Compra)')],
                ['dias_analise', t('Dias Análise')],
                ['dias_compra', t('Dias Compra')],
                ['variacao', `% ${t('Variação')}`],
                ['dt_entrega', t('D. Entrega')],

                // apenas para visualização
                // ['vl_ultima_compra', t('V. Ult Compra')],
                // ['qt_estoque', t('Q. Estoque')],
                // ['vl_total', t('V. Total')],
                // ['vl_unitario', t('V. Unitário')],
                // ['qt_saldo', t('Saldo Dispo.')],
            ],
            tableTotalSize: 0,

            company: hasNewTableList ? this.props.newTableList[0].cd_empresa : null,
            deposit: hasNewTableList ? this.props.newTableList[0].cd_deposito : null,
            analysisPeriod: false,
            currentBalance: true,
            qtyRequest: true,
            qtyOrder: true,
            minStock: true,

            depositList: [],
            requestTypeList: [],
            purchaseOriginList: [],
            isDisabled: false,

            item: '',
            itemsList: [],
            itemPacking: '',
            itemPackagingList: [],
        }
    }

    componentDidMount() {
        this.fetchData()
        this.setState({
            tableList: this.props.newTableList.map((item, index) => ({
                ...item,
                id_linha: index + 1, // Adiciona um id único para cada objeto
                dt_entrega: dayjs().add(1, 'day'),
                ds_embalagempadrao: item.ds_embalagem,
            }))
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.item !== prevState.item) {
            this.state.item
                ? this.getItemsPackaging()
                : this.setState({ itemPackagingList: [], itemPacking: '', um: '', qtdRequested: '' })
        }
        if (prevState.tableList !== this.state.tableList) {
            this.calculateTable()
        }
    }

    calculateTable = () => {
        const updateTable = this.state.tableList.map(item => {
            const operation = item.at_operacao

            const qtEmb = item.qt_item || 0
            const qtDigitado = item.qt_compra || 0
            const vlUnitario = item.vl_unitario || 0

            const qtEstoque = operation === 228 ? qtDigitado * qtEmb : qtDigitado / qtEmb || 0

            const vlTotal = vlUnitario * qtDigitado || 0

            return { ...item, qt_estoque: qtEstoque, vl_total: vlTotal }
        })

        let sum = updateTable.reduce((acc, item) => acc + item.vl_total, 0)

        if (JSON.stringify(this.state.tableList) !== JSON.stringify(updateTable)) {
            setTimeout(() => {
                this.setState({ tableList: updateTable, tableTotalSize: updateTable.length, totalValue: sum.toFixed(4) })
            }, 100)
        }
    }

    calculateLastPurchaseValue = async () => {
        this.setState({ isIncludeDisabled: true })

        const item = this.state.itemsList.find(item => item.id_item === this.state.item)

        let config = {
            endpoint: `componente/valorultimacompra/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'cd_empresa': this.state.company,
            'cd_deposito': this.state.deposit,
            'dt_selecionada': dayjs().format('YYYY-MM-YY'),
            'id_item': this.state.item,
            'id_embalagem': this.state.itemPacking,
            'id_embalagem_padrao': item.id_embalagempadrao,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data) {
                this.setState({ isIncludeDisabled: false })

                return r.data.vl_ultima_cpa
            } else {
                this.setState({ isIncludeDisabled: false })

                return 0
            }
        } catch (error) {
            console.error('Erro ao retornar o evento:', error)
            return 0
        }
    }

    calculateSuggestion = () => {
        this.setState({ isCalculating: true })

        if (!this.state.analysisDays || !this.state.purchaseDays) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isCalculating: false })
            return
        }

        const idList = (this.state.tableList && this.state.tableList.length > 0) ?
            formatMultipleSelect(this.state.tableList.map(item => ({ value: item.id_item }))) : ''
        
            console.log(idList)

        let config = {
            endpoint: `adc/sugestaocompra`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'cd_empresa': this.state.company,
            'cd_deposito': this.state.deposit,
            'qt_dias_compra': this.state.purchaseDays,
            'qt_dias_analise': this.state.analysisDays,
            'qt_dias_estoque': 1,
            'pr_margem': this.state.variation || 0,
            'dt_inicial': this.state.initialDate.format('YYYY-MM-DD'),
            'dt_final': this.state.finalDate.format('YYYY-MM-DD'),
            'tp_analise': 'M',
            'fl_saldo_atual': this.state.currentBalance ? 'S' : 'N',
            'fl_solicitacao': this.state.qtyRequest ? 'S' : 'N',
            'fl_pedido': this.state.qtyOrder ? 'S' : 'N',
            'fl_estoque_minimo': this.state.minStock ? 'S' : 'N',
            'id_itens': idList,
        }

        defaultRequest(config, form).then((r) => {
            if (r.status) {
                const updatedTableList = this.state.tableList.map(item => {
                    const responseData = r.data.find(dataItem => dataItem.id_item === item.id_item)

                    let qtSugerida = responseData && responseData.qt_sugerida
                        ? parseFloat(responseData.qt_sugerida.replace(',', '.'))
                        : 0

                    const result = qtSugerida + (qtSugerida * this.state.variation) / 100 || 0

                    if (responseData) {
                        return {
                            ...item,
                            dias_analise: this.state.analysisDays,
                            dias_compra: this.state.purchaseDays,
                            variacao: this.state.variation,

                            sg_unidademedida: responseData.sg_unidademedida,
                            fl_decimal_quantidade: responseData.fl_decimal_quantidade,
                            qt_dias_compra: responseData.qt_dias_compra,
                            qt_dias_analise: responseData.qt_dias_analise,
                            qt_minima_estoque: responseData.qt_minima_estoque,
                            qt_estoque_atual: responseData.qt_estoque_atual,
                            qt_sugerida: result,
                            qt_compra: result,
                            qt_comprada_periodo: responseData.qt_comprada_periodo,
                            pr_variacao: responseData.pr_variacao,
                            qt_saldo_atual: responseData.qt_saldo_atual,
                            qt_solicitada: responseData.qt_solicitada,
                            qt_pedida: responseData.qt_pedida,
                            qt_estoque_futuro: responseData.qt_estoque_futuro,
                            qt_vendida: responseData.qt_vendida,
                            qt_prev_consumo: responseData.qt_prev_consumo,
                            qt_consumo_medio: responseData.qt_consumo_medio,
                            qt_consumo_pico: responseData.qt_consumo_pico,
                            qt_perdas: responseData.qt_perdas,
                            qt_calculada: responseData.qt_calculada
                        }
                    }
                    return item
                })
                this.setState({ tableList: updatedTableList, isCalculating: false, calculatedOneTime: true, isOnFirstPage: false })
            } else {
                this.showAlert('Erro ao Calcular Sugestão.', 'error')
                const updatedTableList = this.state.tableList.map(item => {
                    return {
                        ...item,
                        dias_analise: 0,
                        dias_compra: 0,
                        variacao: 0,
                        sg_unidademedida: '',
                        fl_decimal_quantidade: '',
                        qt_dias_compra: '',
                        qt_dias_analise: '',
                        qt_minima_estoque: '',
                        qt_estoque_atual: '',
                        qt_sugerida: 0,
                        qt_comprada_periodo: '',
                        pr_variacao: '',
                        qt_saldo_atual: '',
                        qt_solicitada: '',
                        qt_pedida: '',
                        qt_estoque_futuro: '',
                        qt_vendida: '',
                        qt_prev_consumo: '',
                        qt_consumo_medio: '',
                        qt_consumo_pico: '',
                        qt_perdas: '',
                        qt_calculada: '',
                    }
                })
                this.setState({ tableList: updatedTableList, isCalculating: false })
            }
        })
    }

    checkButtonIsDisabled = () => {
        if (!this.state.item || !this.state.um || !this.state.itemPacking || !this.state.qtdRequested) {
            return true
        }
        return false
    }

    fetchData = async () => {
        try {
            const depositData = getDeposit(this.state.company, this.props.user.entidade)
            const requestTypeData = getRequestType(this.props.user.entidade)
            const purchaseOriginData = getPurchaseOrigin(this.props.user.entidade)

            const [depositList, requestTypeList, purchaseOriginList] = await Promise.all([depositData, requestTypeData, purchaseOriginData])

            const filteredRequestTypeList = requestTypeList.filter(item => item.at_situacao === 1)
            const filteredPurchaseOriginList = purchaseOriginList.filter(item => item.at_situacao === 1)

            this.setState({
                depositList: depositList,
                requestTypeList: filteredRequestTypeList,
                purchaseOriginList: filteredPurchaseOriginList,

                isLoading: false
            }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                depositList: [],
                requestTypeList: [],
                purchaseOriginList: [],

                isLoading: false
            })
        }
    }

    getItems = (item) => {
        const { t } = this.props
        let config = {
            endpoint: `componente/item/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'tx_busca': item,
            'x-Pagina': 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemsList: r.data
                })
            } else {
                this.setState({
                    itemsList: [],

                    alertMessage: t('Nenhum dado retornado'),
                    alertType: 'warning',
                    showAlert: true,
                })
            }
        })
    }

    getItemsPackaging = (page = 0) => {
        let config = {
            endpoint: `componente/itemembalagem/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'id_item': this.state.item,
            'x-Pagina': page + 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemPacking: '',
                    itemPackagingList: r.data,
                })
                console.log(r.data)
            } else {
                this.setState({
                    itemPackagingList: [],
                })
            }
        })
    }

    handleChangePacking = (event) => {
        const itemPackingData = this.state.itemPackagingList.find(item => item.id_embalagem === event.target.value) || []

        const um = itemPackingData.sg_unidademedida || ''

        if (event.target.value) {
            this.setState({ [event.target.id]: event.target.value, um: um })
        } else {
            this.setState({ [event.target.id]: event.target.value, um: '' })
        }
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleChangeTextAnalysis = (event) => {
        if (event.target.value) {
            this.setState({
                [event.target.id]: event.target.value,
                initialDate: dayjs().subtract(parseInt(event.target.value, 10) + 1, 'day'),
                finalDate: dayjs().subtract(1, 'day'),
            })
        } else {
            this.setState({ [event.target.id]: event.target.value })
        }
    }

    handleChangeTextDates = (event) => {
        const { id, value } = event.target

        this.setState({ [id]: value },
            () => {
                const { initialDate, finalDate } = this.state

                if (initialDate && finalDate) {
                    const result = dayjs(finalDate).diff(dayjs(initialDate), 'day')
                    if (result !== this.state.analysisDays) {
                        this.setState({ analysisDays: result })
                    }
                }
            }
        )
    }

    handleEditRow = (updatedList, method, id) => {
        if (method === 'edit') {
            this.setState({
                tableList: updatedList
            })
        }
        if (method === 'delete') {
            this.setState({
                tableList: updatedList,
                tableTotalSize: updatedList.length
            })
        }
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.includeItem()
        }
    }

    includeItem = async () => {
        const item = this.state.itemsList.find(item => item.id_item === this.state.item)
        const packing = this.state.itemPackagingList.find(itemPacking => itemPacking.id_embalagem === this.state.itemPacking)

        const lastPurchaseValue = await this.calculateLastPurchaseValue()

        if (item && packing) {
            const newItem = {
                id_linha: this.state.tableList.length + 1,  // cria um id único para poder adicionar duas ou + vezes o mesmo item
                id_item: item.id_item,
                cd_item: item.cd_item,
                ds_item: item.ds_item,
                id_embalagem: packing.id_embalagem,
                ds_embalagem: packing.ds_embalagem,
                qt_compra: this.state.qtdRequested,
                dias_analise: this.state.analysisDays,
                dias_compra: this.state.purchaseDays,
                variacao: this.state.variation,
                dt_entrega: dayjs().add(1, 'day'),
                vl_ultima_compra: lastPurchaseValue,
                at_operacao: packing.at_operacao,
            }

            const itemExists = this.state.tableList.some(tableItem =>   // se já existir o mesmo item com a mesma embalagem na tabela
                tableItem.id_item === newItem.id_item && tableItem.id_embalagem === newItem.id_embalagem
            )

            if (itemExists) {
                this.showAlert('Já existe um registro para este item e embalagem na tabela.', 'error')
            } else {
                this.setState(prevState => ({
                    tableList: [...prevState.tableList, newItem],
                    item: '',
                    itemPacking: '',
                    qtdRequested: '',

                    itemsList: [],
                    itemPackagingList: [],
                }), () => this.calculateSuggestion())
                this.showAlert('Item adicionado com sucesso.', 'success')
            }
        }
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    sendPurchaseBySuggestion = () => {
        this.setState({ isDialogOpen: false, isLoading: true })

        if (!this.state.purchaseDays || !this.state.purchaseOrigin || !this.state.requestType) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasInvalidItem = this.state.tableList.some(item => !('qt_compra' in item) || item.qt_compra === 0)

        if (hasInvalidItem) {
            this.showAlert('Há items não preenchidos na tabela.', 'error')
            this.setState({ isLoading: false })
            return
        }

        let config = {
            endpoint: `adc/solicitacaocompra?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'cd_empresa': this.state.company,
            'cd_usuario_solicitante': this.props.user.data.cd_usuario,
            'dt_solicitacao': dayjs().format('YYYY-MM-YY'),
            'id_solicitacaocompratipo': this.state.requestType,
            'cd_origemcompra': this.state.purchaseOrigin,
            'at_situacao': 1,
            'observacao': this.state.observation,

            'itens': (this.state.tableList && this.state.tableList.length > 0) ?
                this.state.tableList.map((item, index) => {
                    return {
                        'nr_ordem': index + 1,
                        'cd_item': item.cd_item,
                        'id_item': item.id_item,
                        'id_embalagem': item.id_embalagem,
                        'qt_solicitada': item.qt_compra,
                        'qt_atendida': 0,
                        'qt_convertido': item.qt_estoque || 0,
                        'vl_ultima_compra': item.vl_ultima_compra,
                        'dt_entrega': item.dt_entrega,
                        'at_operacao': item.at_operacao,
                    }
                }) : []
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.showAlert('Ação realizada com com sucesso!', 'success')

                this.setState({
                    movement: r.data.id_solicitacaocompra,
                    isLoading: false,
                    isDisabled: true,
                    isConfirmationOpen: true
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendPurchaseBySuggestion}                          // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Solicitação gerada com sucesso, n.:')} ${this.state.movement}`}
                            body={t('')}
                            onConfirm={() => { this.setState({ isConfirmationOpen: false }); this.props.toggleVisiblePage('isPurchaseBySuggestionVisible') }}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isPurchaseBySuggestionVisible')}
                        />

                        {this.state.isLoading ?
                            <LoadingGif />
                            : this.state.isOnFirstPage ?
                                <>
                                    {/* Cabeçalho da primeira página */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                        <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                            {t('Compra por Sugestão')}:
                                        </Typography>

                                        <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                        <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                            <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                        </Button>
                                    </Box>


                                    <Box sx={{ margin: { xs: '20px 0', lg: '10px 40px 0 0' } }}>
                                        <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '30% 23% 21% 13% 13%' }, margin: { md: '10px 0 0 0', lg: '10px 80px 0 0' } }}>
                                            <MainSelectField
                                                {...this.props}
                                                id='company'
                                                value={this.state.company}
                                                label={t('Empresa')}
                                                placeholder={t('[selecione...]')}
                                                optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                                disabled={true}
                                            />
                                            <MainSelectField
                                                {...this.props}
                                                id='deposit'
                                                value={this.state.deposit}
                                                label={t('Depósito')}
                                                placeholder={t('[selecione...]')}
                                                optionsList={this.state.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                                disabled={true}
                                            />
                                            <MainCheckBoxInput
                                                {...this.props}
                                                sx={{ marginTop: '10px' }}
                                                id='analysisPeriod'
                                                value={this.state.analysisPeriod}
                                                label={t('Período para Análise')}
                                                handleChange={this.handleChangeText}
                                            />
                                            <MainDateTimeInput
                                                {...this.props}
                                                id='initialDate'
                                                value={this.state.initialDate}
                                                label={t('D. Inicial')}
                                                placeholder={t('[00/00/0000]')}
                                                handleChange={this.handleChangeTextDates}
                                                type='date'
                                                disabled={!this.state.analysisPeriod}
                                                required={this.state.analysisPeriod ? true : false}
                                            />
                                            <MainDateTimeInput
                                                {...this.props}
                                                id='finalDate'
                                                value={this.state.finalDate}
                                                label={t('D. Final')}
                                                placeholder={t('[00/00/0000]')}
                                                handleChange={this.handleChangeTextDates}
                                                type='date'
                                                disabled={!this.state.analysisPeriod}
                                                required={this.state.analysisPeriod ? true : false}
                                            />
                                        </Box>

                                        <Box sx={{ display: 'grid', gap: '20px', alignItems: 'center', gridTemplateColumns: { lg: '100%', xl: '22% 7% 58% 13%' }, margin: { md: '15px 0 0 0', lg: '15px 60px 0 0' } }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: { md: 'column', lg: 'row' },
                                                    gap: '15px',
                                                    alignItems: 'center',

                                                    padding: '10px',
                                                    border: `1px solid ${this.props.colors.grey[800]}`,
                                                    borderRadius: '10px'
                                                }}
                                            >
                                                <Typography {...this.props} sx={{ fontSize: '16px', fontWeight: '500', whiteSpace: 'nowrap', }}>{t('Sugestão de Dias')}:</Typography>
                                                <MainTextField
                                                    {...this.props}
                                                    id='analysisDays'
                                                    value={this.state.analysisDays}
                                                    label={t('Análise')}
                                                    placeholder={t('[000]')}
                                                    handleChange={this.handleChangeTextAnalysis}
                                                    type="number"
                                                    maxDigits={5}
                                                    disabled={this.state.analysisPeriod}
                                                    required={!this.state.analysisPeriod ? true : false}
                                                    ref={this.firstFieldRef}
                                                />
                                                <MainTextField
                                                    required
                                                    {...this.props}
                                                    id='purchaseDays'
                                                    value={this.state.purchaseDays}
                                                    label={t('Compra')}
                                                    placeholder={t('[000]')}
                                                    handleChange={this.handleChangeText}
                                                    type="number"
                                                    maxDigits={5}
                                                />
                                            </Box>
                                            <MainTextField
                                                {...this.props}
                                                id='variation'
                                                value={this.state.variation}
                                                label={`% ${t('Variação')} +/-`}
                                                placeholder={t('[000]')}
                                                handleChange={this.handleChangeText}
                                                type="number"
                                                maxDecimals={4}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: { md: 'column', lg: 'row' },
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '15px',
                                                    minHeight: '60px',

                                                    padding: '10px',
                                                    border: `1px solid ${this.props.colors.grey[800]}`,
                                                    borderRadius: '10px'
                                                }}
                                            >
                                                <Typography {...this.props} sx={{ fontSize: '16px', fontWeight: '500', whiteSpace: 'nowrap', }}>{t('Opções de Cálculo')}:</Typography>
                                                <MainCheckBoxInput
                                                    {...this.props}
                                                    id='currentBalance'
                                                    value={this.state.currentBalance}
                                                    label={t('Saldo Atual')}
                                                    handleChange={this.handleChangeText}
                                                />
                                                <MainCheckBoxInput
                                                    {...this.props}
                                                    id='qtyRequest'
                                                    value={this.state.qtyRequest}
                                                    label={t('Q. Solicitação')}
                                                    handleChange={this.handleChangeText}
                                                />
                                                <MainCheckBoxInput
                                                    {...this.props}
                                                    id='qtyOrder'
                                                    value={this.state.qtyOrder}
                                                    label={t('Q. Pedidos')}
                                                    handleChange={this.handleChangeText}
                                                />
                                                <MainCheckBoxInput
                                                    {...this.props}
                                                    id='minStock'
                                                    value={this.state.minStock}
                                                    label={t('Estoque Min.')}
                                                    handleChange={this.handleChangeText}
                                                />
                                            </Box>
                                            <MainButton
                                                {...this.props}
                                                sx={{ background: this.props.colors.blueAccent[100] }}
                                                title={this.state.isCalculating ? t('Calculando...') : t('Calcular Sugestão')}
                                                onButtonClick={this.calculateSuggestion}
                                                disabled={this.state.isCalculating || this.state.isDisabled}
                                            />
                                        </Box>

                                        <Box sx={{ display: 'grid', justifyContent: 'end', gridTemplateColumns: { lg: '100%', xl: '12%' }, margin: { md: '15px 0 0 0', lg: '15px 0 0 0' } }}>
                                            <MainButton
                                                {...this.props}
                                                sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                                title={t('Itens')}
                                                onButtonClick={() => this.state.calculatedOneTime
                                                    ? this.setState({ isOnFirstPage: false })
                                                    : this.showAlert('Calcule a sugestão antes de avançar', 'error')
                                                }
                                                endIcon={<ArrowForward sx={{ color: 'white' }} />}
                                            />
                                        </Box>
                                    </Box>
                                </> :
                                <>
                                    {/* Cabeçalho segunda página */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                        <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                            {t('Compra por Sugestão') + ' - ' + t('Itens')}:
                                        </Typography>

                                        <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                        <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                            <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                        </Button>
                                    </Box>

                                    <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                        <Accordion
                                            {...this.props}
                                            startOpen
                                            title={`${t('Itens')}:`}
                                            content={
                                                <>
                                                    <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '100%', lg: '15%' } }}>
                                                        <MainButton
                                                            {...this.props}
                                                            sx={{ background: this.state.isIncludeItemTableVisible ? this.props.colors.grey[900] : this.props.colors.blueAccent[100] }}
                                                            title={t('Adicionar Item')}
                                                            onButtonClick={() => this.setState({ isIncludeItemTableVisible: true })}
                                                        />
                                                    </Box>

                                                    {this.state.isIncludeItemTableVisible &&
                                                        (
                                                            <Box sx={{ padding: '10px 30px', border: `1px solid ${this.props.colors.orangeAccent[800]}`, margin: '20px 0', borderRadius: '10px' }}>

                                                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>{t('Adicionar Item')}:</Typography>

                                                                    <Divider sx={{ flexGrow: 1, margin: '0 0 0 20px' }} />

                                                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isIncludeItemTableVisible: false })}>
                                                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                                                    </Button>
                                                                </Box>

                                                                {/* Campos de adição */}
                                                                <Box sx={{ display: 'grid', gap: '0', gridTemplateColumns: { xs: '100%', lg: '30% 10% 30% 15% 15%' } }}>
                                                                    <Box sx={styles.AddItemBox}>
                                                                        <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '20px 0 0 20px' }}>{t('Descrição do Item')}</Typography>
                                                                        <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='item' value={this.state.item} placeholder={t('[digite para buscar as opções...]')} optionsList={this.state.itemsList.map(item => ({ label: `${item.cd_item} - ${item.ds_item}`, value: item.id_item }))} handleChange={this.handleChangeText} delayFunction={this.getItems} />
                                                                    </Box>
                                                                    <Box sx={styles.AddItemBox}>
                                                                        <Typography sx={styles.addItemHeader(this.props)}>{t('UM')}</Typography>
                                                                        <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='um' value={this.state.um} placeholder={t('[unidade]')} handleChange={this.handleChangeText} disabled={true} />
                                                                    </Box>
                                                                    <Box sx={styles.AddItemBox}>
                                                                        <Typography sx={styles.addItemHeader(this.props)}>{t('Embalagem')}</Typography>
                                                                        <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='itemPacking' value={this.state.itemPacking} placeholder={t('[embalagem...]')} optionsList={this.state.itemPackagingList.map(item => ({ label: item.ds_embalagem, value: item.id_embalagem }))} handleChange={this.handleChangePacking} />
                                                                    </Box>
                                                                    <Box sx={styles.AddItemBox}>
                                                                        <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '0 20px 20px 0' }}>{t('Quantidade')}</Typography>
                                                                        <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='qtdRequested' value={this.state.qtdRequested} placeholder={t('[0,0000]')} handleChange={this.handleChangeText} type='number' maxDecimals={4} onKeyUp={this.handleEnterKeyUp} />
                                                                    </Box>
                                                                    <MainButton sx={styles.AddItemMainButton(this.props)} {...this.props} title={t('Inserir')} onButtonClick={this.includeItem} disabled={this.checkButtonIsDisabled() || this.state.isIncludeDisabled} />
                                                                </Box>

                                                                <Divider sx={{ flexGrow: 1, marginTop: '5px' }} />
                                                            </Box>
                                                        )
                                                    }

                                                    <EditableTable
                                                        {...this.props}
                                                        allowEdit
                                                        allowEditOnRow
                                                        id='id_item'
                                                        rowId='id_item'
                                                        data={this.state.tableList}
                                                        columns={this.state.tableColumns}
                                                        totalSize={this.state.tableTotalSize}
                                                        isLoading={this.state.isLoadingTable}
                                                        onRowDoubleClick={() => { }}
                                                        onPageChange={() => { }}
                                                        onEditRow={this.handleEditRow}
                                                        editableFields={['qt_compra', 'dt_entrega']}
                                                        extraColumnsConfig={
                                                            {
                                                                'qt_item': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'qt_sugerida': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'qt_compra': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'dias_analise': {
                                                                    'type': 'number',
                                                                },
                                                                'dias_compra': {
                                                                    'type': 'number',
                                                                },
                                                                'variacao': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'dt_entrega': {
                                                                    'type': 'date',
                                                                },

                                                                // apenas para visualização
                                                                'vl_ultima_compra': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'qt_estoque': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'vl_total': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'vl_unitario': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                                'qt_saldo': {
                                                                    'type': 'number',
                                                                    'maxDecimals': '4',
                                                                },
                                                            }
                                                        }
                                                        customRowSize={
                                                            {
                                                                'cd_item': 80,
                                                                'ds_item': 210,
                                                                'qt_sugerida': 190,
                                                                'qt_compra': 190,
                                                                'dt_entrega': 100,
                                                                'actions': 60
                                                            }
                                                        }
                                                    />
                                                </>
                                            }
                                        />

                                        <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '25% 25% 40% 10%' }, margin: { md: '10px 0 0 0', lg: '10px 60px 0 0' } }}>
                                            <MainSelectField
                                                {...this.props}
                                                id='requestType'
                                                value={this.state.requestType}
                                                label={t('Tipo de Solicitação')}
                                                placeholder={t('[selecione...]')}
                                                handleChange={this.handleChangeText}
                                                optionsList={this.state.requestTypeList.map(item => ({ label: item.ds_solicitacaocompratipo, value: item.id_solicitacaocompratipo }))}
                                                disabled={this.state.isDisabled}
                                                required
                                            />
                                            <MainSelectField
                                                {...this.props}
                                                id='purchaseOrigin'
                                                value={this.state.purchaseOrigin}
                                                label={t('Origem de Compra')}
                                                placeholder={t('[selecione...]')}
                                                handleChange={this.handleChangeText}
                                                optionsList={this.state.purchaseOriginList.map(item => ({ label: item.ds_origemcompra, value: item.cd_origemcompra }))}
                                                disabled={this.state.isDisabled}
                                                required
                                            />
                                            <MainTextField
                                                {...this.props}
                                                id='observation'
                                                value={this.state.observation}
                                                label={t('Observação')}
                                                placeholder={t('[digite...]')}
                                                handleChange={this.handleChangeText}
                                                disabled={this.state.isDisabled}
                                            />
                                            <MainTextField
                                                {...this.props}
                                                id=''
                                                value={this.state.totalValue}
                                                label={t('V. Total')}
                                                placeholder={t('[0,00]')}
                                                type="number"
                                                maxDecimals={4}
                                                disabled={true}
                                            />

                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: '70%', justifyContent: 'space-between', margin: { md: '10px 0 0 0', lg: '10px 40px 0 0' } }}>
                                        <MainButton
                                            {...this.props}
                                            sx={{ background: this.props.colors.grey[500], marginTop: '20px' }}
                                            onButtonClick={() => this.setState({ isOnFirstPage: true })}
                                            title={t('Voltar')}
                                            startIcon={<ArrowBack sx={{ color: 'white' }} />}
                                        />
                                        <MainButton
                                            {...this.props}
                                            onButtonClick={() => this.setState({ isDialogOpen: true })}
                                            title={t('Gerar Solicitação')}
                                            sx={{ background: this.props.colors.orangeAccent[400], margin: '20px 0' }}
                                            disabled={this.state.isDisabled}
                                        />
                                    </Box>
                                </>
                        }

                        {this.state.isTablePopupOpen && (
                            <TablePopup
                                {...this.props}
                                {...this.state}
                                isOpen={this.state.isTablePopupOpen}
                                closePopup={() => this.setState({ isTablePopupOpen: false })}
                            />
                        )}

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(PurchaseBySuggestion);

const right = keyframes` from {right: -100%; } to {right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    }),
    addItemHeader: (props) => ({
        marginTop: '10px',
        color: props.colors.blueAccent[100],
        backgroundColor: props.colors.grey[900],
        padding: '5px 0 5px 10px',
        fontWeight: '600',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    }),
    AddItemMainTextField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiInputBase-input': { boxShadow: 'none !important' },
        '& .MuiOutlinedInput-root': {
            'fieldset': { borderColor: props.colors.orangeAccent[800] },
            '&.Mui-focused fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800] }
        },
        '& .MuiInputBase-input.Mui-disabled': { backgroundColor: 'transparent' }
    }),
    AddItemMainSelectField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800], boxShadow: 'none !important' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { border: 'none' } }
    }),
    AddItemMainDateTimeInput: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { boxShadow: 'none !important' },
        '& .MuiOutlinedInput-root fieldset': { borderWidth: '0 0 1px 0', borderRadius: '0 !important', borderColor: props.colors.orangeAccent[800] },
        '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { border: 'none' } }
    }),
    AddItemMainButton: (props) => ({
        width: { xs: '100%', lg: '70%' },
        height: '30px',
        border: `1px solid ${props.colors.blueAccent[100]}`,
        textColor: props.colors.blueAccent[100],
        margin: { xs: '30px 0 10px 0', lg: '50px 0 0 15px' }
    })
}